import React, { useEffect, useState } from 'react'

import Modal from '../components/Modal'
import { DialogContent, DialogActions, Button } from '@material-ui/core'
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'

//Services
import StoreService from '../services/store.service'

//Redux
import { update_alert } from '../store/actions'
import { connect } from 'react-redux'

import { useForm } from 'react-hook-form'
import { getMessageError, getDateWithSetHourMinutes } from '../helpers'

const AddAndUpdateWorkdayHours = props => {
  //Props
  const {
    open,
    handleClose,
    storeWorkday,
    store_id,
    editStoreWorkdayWorkingHour,
  } = props

  //Redux
  const { update_alert } = props

  //State
  const [hourRange, setHourRange] = useState({
    start_hour: '',
    end_hour: '',
  })

  const [selectedInitTime, setSelectedInitTime] = useState(new Date())
  const [selectedEndTime, setSelectedEndTime] = useState(new Date())

  //validación for useForm
  const { register, handleSubmit, setValue, errors } = useForm()

  //Methods
  //Change init time input
  const handleInitTimeChange = date => {
    setSelectedInitTime(date)
  }
  //Change end time input
  const handleEndTimeChange = date => {
    setSelectedEndTime(date)
  }

  const save = async () => {
    //Validate hours range
    const format = 'HH:mm'
    const startHour = moment(selectedInitTime, format).set({
      second: 0,
      millisecond: 0,
    })
    const endHour = moment(selectedEndTime, format).set({
      second: 0,
      millisecond: 0,
    })
    let validate_hours_error = ''
    console.log(startHour, endHour)
    if (startHour >= endHour) {
      validate_hours_error =
        'La hora inicial no puede ser igual ni mayor a la hora final'
    } else {
      if (storeWorkday.store_workday_working_hours.length > 0) {
        storeWorkday.store_workday_working_hours.forEach(wh => {
          let beforeTime = moment(wh.start_hour, format)
          let afterTime = moment(wh.end_hour, format)

          if (startHour.isBetween(beforeTime, afterTime, undefined, '[)')) {
            validate_hours_error =
              'El bloque horario seleccionado parece entrar en conflicto con uno ya registrado 1'
          }

          if (endHour.isBetween(beforeTime, afterTime, undefined, '(]')) {
            validate_hours_error =
              'El bloque horario seleccionado parece entrar en conflicto con uno ya registrado 2'
          }
        })
      }
    }

    if (validate_hours_error) {
      update_alert({
        open: true,
        severity: 'error',
        message: validate_hours_error,
      })
      return
    }

    try {
      const { data: res } = await StoreService.saveStoreWorkdayHours({
        start_hour: startHour.format('HH:mm'),
        end_hour: endHour.format('HH:mm'),
        store_id: store_id,
        store_workday_id: storeWorkday.id,
      })
      const date = new Date()
      setSelectedEndTime(date)
      setSelectedInitTime(date)

      handleClose(res.data)
      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  const update = async () => {
    const storeWorkdayCopy = JSON.parse(JSON.stringify(storeWorkday))
    storeWorkdayCopy.store_workday_working_hours = storeWorkdayCopy.store_workday_working_hours.filter(
      i => i.id !== editStoreWorkdayWorkingHour.id
    )
    //Validate hours range
    const format = 'HH:mm'
    const startHour = moment(selectedInitTime, format).set({
      second: 0,
      millisecond: 0,
    })
    const endHour = moment(selectedEndTime, format).set({
      second: 0,
      millisecond: 0,
    })
    let validate_hours_error = ''
    if (startHour >= endHour) {
      validate_hours_error =
        'La hora inicial no puede ser igual ni mayor a la hora final'
    } else {
      if (storeWorkdayCopy.store_workday_working_hours.length > 0) {
        storeWorkdayCopy.store_workday_working_hours.forEach(wh => {
          let beforeTime = moment(wh.start_hour, format)
          let afterTime = moment(wh.end_hour, format)

          if (startHour.isBetween(beforeTime, afterTime, undefined, '[)')) {
            validate_hours_error =
              'El bloque horario seleccionado parece entrar en conflicto con uno ya registrado 1'
          }

          if (endHour.isBetween(beforeTime, afterTime, undefined, '(]')) {
            validate_hours_error =
              'El bloque horario seleccionado parece entrar en conflicto con uno ya registrado 2'
          }
        })
      }
    }

    if (validate_hours_error) {
      update_alert({
        open: true,
        severity: 'error',
        message: validate_hours_error,
      })
      return
    }

    try {
      const { data: res } = await StoreService.updateStoreWorkdayHours({
        start_hour: startHour.format('HH:mm'),
        end_hour: endHour.format('HH:mm'),
        store_id: store_id,
        id: editStoreWorkdayWorkingHour.id,
      })
      const date = new Date()
      setSelectedEndTime(date)
      setSelectedInitTime(date)

      handleClose(res.data, true)
      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //useEffect
  useEffect(() => {
    if (editStoreWorkdayWorkingHour) {
      console.log(editStoreWorkdayWorkingHour)
      setSelectedInitTime(
        getDateWithSetHourMinutes(editStoreWorkdayWorkingHour.start_hour)
      )
      setSelectedEndTime(
        getDateWithSetHourMinutes(editStoreWorkdayWorkingHour.end_hour)
      )
    }
  }, [editStoreWorkdayWorkingHour])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Modal
        open={open}
        zIndex={1}
        title={
          'Agregar nuevo bloque de atención al día ' +
          (storeWorkday ? storeWorkday.workday.name : '')
        }
        closeModal={handleClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <form
          onSubmit={handleSubmit(editStoreWorkdayWorkingHour ? update : save)}
        >
          <DialogContent className="pt-2">
            <div>
              <p>Desde que hora a que hora sera el bloque de atención</p>
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Hora inicial"
                value={selectedInitTime}
                onChange={handleInitTimeChange}
                KeyboardButtonProps={{
                  'aria-label': 'Elegir',
                }}
              />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Hora final"
                value={selectedEndTime}
                onChange={handleEndTimeChange}
                KeyboardButtonProps={{
                  'aria-label': 'Elegir',
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary">
              <i className="fa fa-save mr-3"></i>
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Modal>
    </MuiPickersUtilsProvider>
  )
}

export default connect(null, { update_alert })(AddAndUpdateWorkdayHours)
