import React, { useState } from "react";
import { update_user, update_alert, update_loading } from "../store/actions";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import UtilityService from "../services/utility.service";
import { getMessageError } from "../helpers";
import NewShippingAddress from "../components/NewShippingAddress";
import Modal from "../components/Modal";

const ChangeCurrentShippingAddress = (props) => {
  //Redux
  const { update_alert, update_user, update_loading, user } = props;

  //State
  const [open, setOpen] = useState(false);

  //Methods
  //Selectshipping address
  const selectShippingAddress = async (id) => {
    try {
      update_loading({
        show: true,
        message: "Cambiando dirección de envio",
      });
      const { data: res } = await UtilityService.changeShippingAddress(id);

      user.shipping_addresses.forEach((i) => {
        if (res.data.id === i.id) {
          i.current = true;
        } else {
          i.current = false;
        }
      });

      const new_user = {
        ...user,
        current_shipping_address: res.data,
        shipping_addresses: user.shipping_addresses,
      };

      update_user(new_user);

      update_alert({
        open: true,
        severity: "success",
        message: res.message,
      });
    } catch (error) {
      const errorMessage = getMessageError(error);
      update_alert({
        open: true,
        severity: "error",
        message: errorMessage,
      });
    }

    update_loading({
      show: false,
    });

    props.callback();
  };

  //New Current Shipping Address
  const newCurrentShippingAddress = (new_address) => {
    user.shipping_addresses.forEach((i) => {
      i.current = false;
    });

    const new_user = {
      ...user,
      current_shipping_address: new_address,
      shipping_addresses: [...user.shipping_addresses, new_address],
    };
    update_user(new_user);
    closeModal();
    props.callback();
  };

  //Open modal
  const openModal = () => {
    setOpen(true);
  };

  //Close modal
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="d-block text-right m-0 p-0">
        <Button onClick={() => openModal()} variant="outlined" color="primary">
          Nueva
        </Button>
      </div>
      {user.shipping_addresses.map((sa) => {
        return (
          <div className="card shadow position-relative mt-2" key={sa.id}>
            {sa.current && (
              <span
                style={{ top: 15, right: 15 }}
                className="text-success position-absolute rigth-0"
              >
                <i className="fa fa-check-circle"></i>
                Actual
              </span>
            )}
            {!sa.current && (
              <span
                onClick={() => selectShippingAddress(sa.id)}
                style={{ top: 15, right: 15 }}
                className="text-primary position-absolute rigth-0 font-weight-bold btn"
              >
                Seleccionar
              </span>
            )}
            <h5 className="font-weight-bold">{sa.name}</h5>
            <p className="pl-3 m-0">{sa.address}</p>
          </div>
        );
      })}

      <Modal
        open={open}
        title="Nueva dirección de envio"
        closeModal={closeModal}
      >
        <NewShippingAddress callback={newCurrentShippingAddress} />
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, {
  update_user,
  update_loading,
  update_alert,
})(ChangeCurrentShippingAddress);
