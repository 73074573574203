import React, { useState, useEffect } from 'react'
import Modal from './Modal'
import DialogContent from '@material-ui/core/DialogContent'
import { TextField, Button, Checkbox, Tooltip } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core'

const AddAttributeProduct = props => {
  //Props
  const { closeModal, open } = props
  console.log('AddAttributeProduct: ', props.currentAttributes)

  //State
  const [attribute, setAttribute] = useState({
    name: '',
    help_text: '',
  })
  const [selected, setSelected] = useState('')
  const [checked, setChecked] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [options, setOptions] = useState([
    {
      id: 1,
      name: 'Casillas de Chequeo (Checkbox)',
      type: 'checkbox',
    },
    {
      id: 2,
      name: 'Selección con Radios',
      type: 'radio',
    },
    {
      id: 3,
      name: 'Selección con Select',
      type: 'select',
    },
    // {
    // 	id: 4,
    // 	name: 'Subida de archivo',
    // 	type: 'file',
    // },
  ])

  //validación por useForm
  const { register, handleSubmit, setValue, errors } = useForm()

  //Change input attribute
  const handleInputChangeAttribute = e => {
    setAttribute({
      ...attribute,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  const handleChangeCheck = event => {
    setChecked(event.target.checked)
  }

  //Add Attribute
  const addAttribute = () => {
    closeModal({
      ...attribute,
      type: selected.type,
      required: checked,
      options: [],
    })
    setSelected('')
    setAttribute({
      name: '',
      help_text: '',
    })
    setValue('type_attribute', '', true)
    setChecked(false)
  }

  const handleSelectChange = e => {
    if (selected.name === e.target.value.name) return
    setSelected(e.target.value)
    setValue('type_attribute', e.target.value, true)
  }

  //Listen to register validation
  useEffect(() => {
    register(
      { name: 'type_attribute', type: 'select' },
      {
        required: {
          value: true,
          message: 'Este campo es requerido',
        },
      }
    )
  }, [register])

  return (
    <Modal
      open={open}
      title="Agregar grupo de adicionales"
      closeModal={closeModal}
      zIndex={2}
    >
      <form onSubmit={handleSubmit(addAttribute)}>
        <DialogContent>
          <div className="mb-4">
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="type_attribute" error={!!errors.type_attribute}>
                Tipo de opciónes del grupo
              </InputLabel>
              <Select
                renderValue={value => value.name}
                value={selected}
                onChange={handleSelectChange}
                inputRef={register}
                name="type_attribute"
                label="Tipo de opciónes del grupo"
                error={!!errors.type_attribute}
              >
                <MenuItem value="">Seleccione</MenuItem>
                {options.map(option => {
                  return (
                    <MenuItem key={option.id} value={option}>
                      {option.name}
                    </MenuItem>
                  )
                })}
              </Select>
              {!!errors.type_attribute && (
                <FormHelperText className="text-danger">
                  Debes seleccionar un tipo de opciones de grupo
                </FormHelperText>
              )}
            </FormControl>
          </div>

          <div className="mb-4">
            <TextField
              inputRef={register({ required: true })}
              fullWidth
              variant="outlined"
              label="Nombre Grupo"
              name="name"
              type="text"
              value={attribute.name}
              onChange={handleInputChangeAttribute}
              error={!!errors.name}
              helperText={
                errors.name && 'El campo nombre de grupo es requerido'
              }
            />
          </div>

          <div className="mb-4">
            <TextField
              placeholder="Si quieres mostrar una ayuda al cliente (opcional)"
              fullWidth
              variant="outlined"
              label="Texto de ayuda"
              name="help_text"
              type="text"
              value={attribute.help_text}
              onChange={handleInputChangeAttribute}
            />
          </div>

          {(selected.type === 'radio' || selected.type === 'select') && (
            <div className="mt-3">
              <label htmlFor="required_check">
                <Checkbox
                  id="required_check"
                  checked={checked}
                  onChange={handleChangeCheck}
                ></Checkbox>
                Una opción sera requerida
              </label>
              <Tooltip
                placement="top"
                title="Chequealo si quieres que al menos una opción del grupo de opciones sea requerida"
              >
                <i className="fa fa-question-circle ml-2 text-info"></i>
              </Tooltip>
            </div>
          )}

          <div className="d-flex justify-content-center w-100 mt-3">
            <Button color="primary" type="submit" variant="contained">
              Agregar
            </Button>
          </div>
        </DialogContent>
      </form>
    </Modal>
  )
}

export default AddAttributeProduct
