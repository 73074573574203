import http from '../http'

class RegisterService {
  static register(user) {
    return http({
      method: 'post',
      url: 'register',
      data: user,
    })
  }

  static verifyAccount(verifyCode) {
    return http({
      method: 'post',
      url: 'verify-account',
      data: {
        verify_code: verifyCode,
      },
    })
  }

  static resendVerifyCode() {
    return http({
      method: 'post',
      url: 'resend-verify-code',
    })
  }

  static login(user) {
    return http({
      method: 'post',
      url: 'user/login',
      data: user,
    })
  }

  static logout() {
    return http({
      method: 'post',
      url: 'auth/logout',
    })
  }
}

export default RegisterService
