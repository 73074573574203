import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import './Distributors.scss'
import StoreService from '../../services/store.service'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

//Styles
const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '1rem',
  },
  table: {
    maxWidth: '100%',
  },
})

const Distributors = props => {
  //State
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [rows, setRows] = useState([])

  //State Redux
  const { storeInfo } = props

  //Methods
  const getDistributors = async () => {
    try {
      const { data: res } = await StoreService.getStoreDistributors(
        storeInfo.id
      )
      console.log(res)
      if (res.success) {
        setRows(res.data.data)
      }
    } catch (error) {}
  }

  //Change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  //Cahnge rows per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  //useEffect
  useEffect(() => {
    if (storeInfo) getDistributors()
  }, [storeInfo])

  return (
    <Paper className={classes.root}>
      <div className="headerTable">
        <span className="title">Lista de repartidores</span>
        <TextField variant="outlined" label="Buscar"></TextField>
      </div>

      <TableContainer className="containerTable">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombres</TableCell>
              <TableCell>Apellidos</TableCell>
              <TableCell>Identificación</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Dirección</TableCell>
              <TableCell>Activo</TableCell>
              <TableCell>En servicio</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.distributor.name}</TableCell>
                  <TableCell>{row.distributor.surname}</TableCell>
                  <TableCell>{row.distributor.identification}</TableCell>
                  <TableCell>{row.distributor.phone}</TableCell>
                  <TableCell>{row.distributor.address}</TableCell>
                  <TableCell>{row.distributor.active ? 'Si' : 'No'}</TableCell>
                  <TableCell>
                    {row.distributor.on_service ? 'Si' : 'No'}
                  </TableCell>
                  <TableCell>
                    <Link to={`orders/${row.id}`}>
                      <Button variant="contained" color="primary">
                        <i className="fa fa-eye"></i>
                        Detalle
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

const mapStateToProps = state => ({
  storeInfo: state.storeInfo,
})

export default connect(mapStateToProps, null)(Distributors)
