import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { ListSubheader } from '@material-ui/core'
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
})

const SwipeableTemporaryDrawer = props => {
  const classes = useStyles()

  //Props
  const { open, anchor, closeDrawer } = props

  return (
    <SwipeableDrawer
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Nested List Items
        </ListSubheader>
      }
      anchor={anchor}
      open={open}
      onClose={() => closeDrawer()}
      onOpen={() => {
        console.log('holaaaaa')
      }}
    >
      {props.children}
    </SwipeableDrawer>
  )
}

export default SwipeableTemporaryDrawer
