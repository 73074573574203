import http from '../http'

class PublicService {
  static getSubscriptions() {
    return http({
      method: 'get',
      url: 'subscriptions',
    })
  }

  static getProducts() {
    return http({
      method: 'get',
      url: 'public/products',
    })
  }

  static getProduct(id) {
    return http({
      method: 'get',
      url: `public/products/${id}`,
    })
  }

  static getStores(search = '') {
    if (search) {
      search = `?search=${search}`
    }

    return http({
      method: 'get',
      url: `public/stores${search}`,
    })
  }

  static getStore(id) {
    return http({
      method: 'get',
      url: `public/stores/${id}`,
    })
  }

  static addProductCart(data) {
    return http({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'post',
      url: `public/add-cart`,
      data: data,
    })
  }

  static getTransactionStatus(id) {
    return http({
      method: 'get',
      url: `public/transaction/${id}`,
    })
  }

  //Get payment methods store
  static getPaymentMethodsStore(store_id) {
    return http({
      method: 'get',
      url: `public/store-payment-methods/${store_id}`,
    })
  }

  //Get store workdays
  static getWorkdays() {
    return http({
      method: 'get',
      url: `public/workdays`,
    })
  }

  //Get order by id from user orders
  static getOrderById(data) {
    return http({
      method: 'get',
      url: `public/order/${data.store_id}/${data.order_id}`,
    })
  }

  //Get orders by user
  static userOrders() {
    return http({
      method: 'get',
      url: `public/orders`,
    })
  }
}

export default PublicService
