import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  containerLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    background: "rgba(255,255,255, 0.8)",
    position: "absolute",
    zIndex: 10000,
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
  },
  textLoading: {
    color: theme.palette.primary.main,
    paddingLeft: "1rem",
    fontSize: "20px",
    fontWeight: "bold",
    zIndex: 20000,
  },
}));

const Loading = (props) => {
  const { loading } = props;
  const classes = useStyles();
  return (
    <>
      {loading.show && (
        <div className={classes.containerLoading}>
          <CircularProgress size={26}></CircularProgress>
          <span className={classes.textLoading}>{loading.message}</span>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
});

export default connect(mapStateToProps)(Loading);
