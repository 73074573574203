import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  update_user,
  update_alert,
  update_loading,
} from "./../../store/actions";
import { useParams } from "react-router-dom";
import AuthService from "./../../services/auth.service";
import { getMessageError } from "../../helpers";

const EmailVerify = (props) => {
  const { update_user, update_alert, update_loading } = props;
  const { id, code } = useParams();

  useEffect(() => {
    emailVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailVerify = async () => {
    try {
      update_loading({
        show: true,
        message: "Verificando por favor espere",
      });

      const { data: res } = await AuthService.emailVerify(id, code);

      if (res.success) {
        update_user(res.data.user);

        update_alert({
          open: true,
          severity: "success",
          message: res.message,
        });
        props.history.push("/home");
        return;
      } else {
        update_alert({
          open: true,
          severity: "error",
          message: "Algo va mal",
        });
      }
    } catch (error) {
      const errorMessage = getMessageError(error);
      update_alert({
        open: true,
        severity: "error",
        message: errorMessage,
      });
    }

    update_loading({
      show: false,
    });
  };

  return (
    <>
      <h1>Comprobando</h1>
    </>
  );
};

export default connect(null, { update_user, update_alert, update_loading })(
  EmailVerify
);
