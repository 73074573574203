import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { update_loading, update_alert } from '../store/actions'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  Button,
} from '@material-ui/core'

import ReactStars from 'react-rating-stars-component'

//services
import UtilityService from '../services/utility.service'

//Helpers
import { getMessageError } from '../helpers'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const QualifyOrderModal = props => {
  //Redux state
  const { showStatusOrderModal, handleClose, open, order } = props

  //Redux actions
  const { update_loading, update_alert } = props

  //State
  const [ratingDistributor, setRatingDistributor] = useState(0)
  const [ratingStore, setRatingStore] = useState(0)
  const [ratingWomve, setRatingWomve] = useState(0)

  const ratingDistributorChanged = newRating => {
    console.log(newRating)
    setRatingDistributor(newRating)
  }
  const ratingStoreChanged = newRating => {
    console.log(newRating)
    setRatingStore(newRating)
  }
  const ratingWomveChanged = newRating => {
    console.log(newRating)
    setRatingWomve(newRating)
  }

  //Save qualify order
  const saveQualifyOrder = async data => {
    try {
      if (ratingDistributor === 0 || ratingStore === 0 || ratingWomve === 0) {
        update_alert({
          open: true,
          severity: 'error',
          message: 'La calificación minima debe ser de media estrella',
        })
        return
      }

      update_loading({
        show: true,
        message: 'Calificando',
      })

      const data = {
        order_id: showStatusOrderModal.order_id,
        store_id: showStatusOrderModal.store_id,
        rating_distributor: ratingDistributor,
        rating_store: ratingStore,
        rating_womve: ratingWomve,
      }

      const { data: res } = await UtilityService.saveQualifyOrder(data)

      if (res.success) {
        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
    handleClose(true)
  }

  //UseEffect
  useEffect(() => {
    if (order) {
      setRatingDistributor(order.user_order.rating_distributor)
      setRatingStore(order.user_order.rating_store)
      setRatingWomve(order.user_order.rating_womve)
    }
  }, [order])

  return (
    <Dialog
      style={{ zIndex: 1102 }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose(false)}
      fullWidth={true}
      maxWidth={'xs'}
    >
      <DialogTitle className="border-bottom">
        {order && !order.user_order.rating_store ? 'Calificar' : 'Calificación'}{' '}
        orden # {showStatusOrderModal.order_id}
      </DialogTitle>
      <DialogContent>
        {order && !order.user_order.rating_store && (
          <p>
            Califica como te fue con tu orden esto ayudara a que cada dia se te
            preste un mejor servicio
          </p>
        )}

        {order && !order.user_order.rating_store && (
          <>
            <div>
              <h5 className="p-0 m-0">Repartidor: </h5>{' '}
              <ReactStars
                count={5}
                onChange={ratingDistributorChanged}
                size={40}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffbe00"
              />
            </div>
            <div>
              <h5 className="p-0 m-0">Tienda: </h5>{' '}
              <ReactStars
                count={5}
                onChange={ratingStoreChanged}
                size={40}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffbe00"
              />
            </div>
            <div>
              <h5 className="p-0 m-0">Plataforma: </h5>{' '}
              <ReactStars
                count={5}
                onChange={ratingWomveChanged}
                size={40}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffbe00"
              />
            </div>
            <div className="text-center">
              <Button
                variant="contained"
                color="primary"
                onClick={saveQualifyOrder}
              >
                <i className="fa fa-paper-plane"></i>
                Enviar
              </Button>
            </div>
          </>
        )}

        {order && order.user_order.rating_store > 0 && (
          <>
            <div>
              <h5 className="p-0 m-0">Repartidor: </h5>{' '}
              <ReactStars
                count={5}
                onChange={ratingDistributorChanged}
                size={40}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffbe00"
                value={order.user_order.rating_distributor}
                edit={false}
              />
            </div>
            <div>
              <h5 className="p-0 m-0">Tienda: </h5>{' '}
              <ReactStars
                count={5}
                onChange={ratingStoreChanged}
                size={40}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffbe00"
                value={order.user_order.rating_store}
                edit={false}
              />
            </div>
            <div>
              <h5 className="p-0 m-0">Plataforma: </h5>{' '}
              <ReactStars
                count={5}
                onChange={ratingWomveChanged}
                size={40}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffbe00"
                value={order.user_order.rating_womve}
                edit={false}
              />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  showStatusOrderModal: state.showStatusOrderModal,
})

export default connect(mapStateToProps, { update_alert, update_loading })(
  QualifyOrderModal
)
