import React, { useState } from 'react'
import Modal from './Modal'
import DialogContent from '@material-ui/core/DialogContent'
import { TextField, Button, Checkbox, Tooltip } from '@material-ui/core'
import { useForm } from 'react-hook-form'

const AddOptionAttribute = props => {
  //Props
  const { closeModal, open, attribute } = props
  console.log('AddOptionAttribute ', attribute)

  //State
  const [option, setOption] = useState({
    name: '',
    price: 0,
    help_text: '',
  })
  const [checked, setChecked] = useState(false)

  //validación por useForm
  const { register, handleSubmit, errors } = useForm()

  //Change input
  const handleInputChangeAttribute = e => {
    setOption({
      ...option,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  //Change input check
  const handleChangeCheck = event => {
    setChecked(event.target.checked)
  }

  //Add Option
  const addOption = () => {
    closeModal({
      ...option,
      required: checked,
    })
    setOption({
      name: '',
      price: 0,
      help_text: '',
    })
    setChecked(false)
  }

  return (
    <Modal
      open={open}
      title="Agregar opción al grupo"
      closeModal={closeModal}
      zIndex={2}
    >
      <form onSubmit={handleSubmit(addOption)}>
        <DialogContent>
          <div className="mb-4">
            <TextField
              inputRef={register({ required: true })}
              fullWidth
              variant="outlined"
              label="Nombre"
              name="name"
              type="text"
              value={option.name}
              onChange={handleInputChangeAttribute}
              error={!!errors.name}
              helperText={errors.name && 'El campo nombre es requerido'}
            />
          </div>

          <div className="mb-4">
            <TextField
              inputRef={register({ required: true })}
              fullWidth
              variant="outlined"
              label="Precio"
              name="price"
              type="text"
              value={option.price}
              onChange={handleInputChangeAttribute}
              error={!!errors.price}
              helperText={errors.price && 'El campo precio es requerido'}
            />
          </div>

          <div className="mb-4">
            <TextField
              placeholder="Si quieres mostrar una ayuda al cliente (opcional)"
              fullWidth
              variant="outlined"
              label="Texto de ayuda"
              name="help_text"
              type="text"
              value={option.help_text}
              onChange={handleInputChangeAttribute}
            />
          </div>

          {attribute.type !== 'radio' && attribute.type !== 'select' && (
            <div className="mt-3">
              <label htmlFor="required_check">
                <Checkbox
                  id="required_check"
                  checked={checked}
                  onChange={handleChangeCheck}
                ></Checkbox>
                Es requerido
              </label>
              <Tooltip
                placement="top"
                title="Chequealo si quieres que esta opción sea requerida"
              >
                <i className="fa fa-question-circle ml-2 text-info"></i>
              </Tooltip>
            </div>
          )}

          <div className="d-flex justify-content-center w-100 mt-5">
            <Button color="primary" type="submit" variant="contained">
              Agregar
            </Button>
          </div>
        </DialogContent>
      </form>
    </Modal>
  )
}

export default AddOptionAttribute
