import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { useParams } from 'react-router-dom'
import StoreService from '../../services/store.service'

//Components
import SaveAndEditStoreInfo from '../../components/SaveAndEditStoreInfo'
import AddAndUpdatePaymentMethods from '../../components/AddAndUpdatePaymentMethods'
import AddAndUpdateWorkdaysStore from '../../components/AddAndUpdateWorkdaysStore'
import DrawPolygonAreaMap from '../../components/DrawPolygonAreaMap'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}))

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles()
  //State
  const [value, setValue] = React.useState(0)
  const [store, setStore] = useState(null)
  //Params url
  let { id } = useParams()

  //Methods
  const getStoreById = async () => {
    try {
      const { data: res } = await StoreService.getStoreById(id)
      setStore(res.data)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  //Use Effects
  useEffect(() => {
    getStoreById()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      {store && (
        <div className="p-3">
          <h3>Área de configuraciones : {store.name}</h3>
        </div>
      )}

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Generales y apariencia" {...a11yProps(0)} />
          <Tab label="Horarios de atención" {...a11yProps(1)} />
          <Tab label="Metodos de pago" {...a11yProps(2)} />
          <Tab label="Metodos de envio" {...a11yProps(3)} />
          <Tab label="Subscripción" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {store && <SaveAndEditStoreInfo store_id={store.id} width="50%" />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {store && <AddAndUpdateWorkdaysStore store_id={store.id} />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {store && <AddAndUpdatePaymentMethods store_id={store.id} />}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {store && <DrawPolygonAreaMap store_id={store.id} />}
      </TabPanel>
      <TabPanel value={value} index={4}>
        Información sobre la subscripción adquirida sobre los pagos y poder
        pagar mas meses o plan
      </TabPanel>
    </div>
  )
}
