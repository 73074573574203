const reducer = (state, action) => {
  console.log(action)
  switch (action.type) {
    case 'UPDATE_USER':
      return { ...state, user: action.payload }

    case 'UPDATE_ALERT':
      return {
        ...state,
        alert: {
          ...state.alert,
          ...action.payload,
        },
      }

    case 'UPDATE_LOADING':
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      }

    case 'UPDATE_STORE_INFO':
      return {
        ...state,
        storeInfo: action.payload,
      }

    case 'UPDATE_CART':
      return {
        ...state,
        cart: action.payload,
      }

    case 'UPDATE_SHOW_COMPLETE_ORDER_MODAL':
      return {
        ...state,
        showCompleteOrderModal: action.payload,
      }
    case 'UPDATE_SHOW_STATUS_ORDER_MODAL':
      return {
        ...state,
        showStatusOrderModal: action.payload,
      }
    case 'UPDATE_CURRENT_CURRENCY_CLIENT':
      return {
        ...state,
        currentCurrencyClient: action.payload,
      }

    default:
      return state
  }
}

export default reducer
