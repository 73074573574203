import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import OrderService from '../../services/order.service'
import UtilityService from '../../services/utility.service'
import { currencyFormat } from '../../helpers'
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@material-ui/core'
import { getMessageError, confirmAlert, compare } from '../../helpers'
import Alert from './../../components/Alert'
import Loading from './../../components/Loading'
import { connect } from 'react-redux'
import { update_alert, update_loading } from '../../store/actions'
import Modal from '../../components/Modal'
import { useForm } from 'react-hook-form'
import moment from 'moment'

import ApprovePaymentModal from '../../components/ApprovePaymentModal'

const DetailOrder = props => {
  //Params
  const { id } = useParams()

  //redux actions
  const { update_alert, update_loading } = props

  //redux state
  const { storeInfo } = props

  //State
  const [order, setOrder] = useState({
    order_details: [],
    payments: [],
    statuses: [],
    shipping_address: null,
  })
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState({
    status: '',
    description: '',
  })
  const [statuses, setStatuses] = useState([])
  const [lastStatusOrderId, setLastStatusOrderId] = useState(null)

  const [clientInfo, setClientInfo] = useState(null)

  const [openApprovePaymentModal, setOpenApprovePaymentModal] = useState(false)

  //Validation hook
  const { register, handleSubmit, errors, setValue } = useForm()

  //Methods
  const closeApprovePaymentModal = async data => {
    await getOrderById(id)

    setOpenApprovePaymentModal(false)
  }
  const handleInputChange = e => {
    setStatus({
      ...status,
      [e.currentTarget.name]: e.currentTarget.value,
    })
    console.log(status)
  }

  const handleSelectChange = e => {
    if (status.status.id === e.target.value.id) return

    setStatus({
      ...status,
      [e.target.name]: e.target.value,
      description: e.target.value.description,
    })
    setValue('status', e.target.value)
  }

  //Get statuses to order
  const getStatusesToOrder = async () => {
    try {
      const { data: res } = await UtilityService.getStatusesToOrder({
        order_id: id,
      })
      setStatuses(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //Get order by id
  const getOrderById = async id => {
    try {
      update_loading({
        show: true,
        message: 'Consultando orden',
      })

      const { data: res } = await OrderService.getOrderById(id)

      if (res.success) {
        setOrder(res.data)
        setClientInfo(JSON.parse(res.data.client_info))
        setLastStatusOrderId(
          res.data.statuses.length > 0
            ? res.data.statuses[res.data.statuses.length - 1].status_id
            : null
        )
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  //Save status confirm
  const saveStatusConfirm = async status_id => {
    const res = await confirmAlert('Marcar orden como lista ara recoger')
    console.log(res.isConfirmed)
    if (res.isConfirmed) {
      await saveStatus(status_id)
    }
  }

  //Save new status in order
  const saveStatus = async status_id => {
    try {
      update_loading({
        show: true,
        message: 'Guardando nuevo estado en orden',
      })

      const { data: res } = await UtilityService.saveStatusOrder({
        store_id: storeInfo.id,
        payment_id: null,
        distributor_id: null,
        order_id: order.id,
        status: status_id,
        type: 'store',
      })

      if (res.success) {
        //order.statuses.push(res.data.)
        await getOrderById(res.data.order.id)

        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })

        closeModal()
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  //Open modal
  const openModal = () => {
    setOpen(true)
  }

  //Close modal
  const closeModal = () => {
    setOpen(false)
    setStatus({
      status: '',
      description: '',
    })
    setValue('status', '')
  }

  const checkStatusesExists = statuses_to_check => {
    const exists = order.statuses.find(i =>
      statuses_to_check.includes(i.status_id)
    )
    return exists ? true : false
  }

  //useEffect
  useEffect(() => {
    getOrderById(id)
    //getStatusesToOrder()

    //Subscribe chanel status order
    // 	const channel = `order.${id}`
    // 	window.echo.private(channel).listen('.ChangeStatusOrder', data => {
    // 		setOrder(order => ({
    // 			...order,
    // 			statuses: [...order.statuses, data],
    // 		}))
    // 	})

    // 	//cleanup subcribers
    // 	return () => window.echo.leave(channel)
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    register({ name: 'status', type: 'select' }, { required: true })
  }, [register])

  return (
    <>
      <div>
        <Loading></Loading>
      </div>
      <div className="col-md-12 mt-3">
        <div className="d-flex card w-100">
          <div className="card-title">
            <h2 className="h2 d-flex justify-content-between">
              <span>
                Información orden # {order.id} {lastStatusOrderId}
              </span>
              {lastStatusOrderId === 1 && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    setOpenApprovePaymentModal(!openApprovePaymentModal)
                  }
                >
                  <i className="fa fa-check"></i>
                  Aprobar pago
                </Button>
              )}
              {(lastStatusOrderId === 3 ||
                lastStatusOrderId === 4 ||
                lastStatusOrderId === 6) &&
                !checkStatusesExists([5]) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => saveStatusConfirm(5)}
                  >
                    <i className="fa fa-check"></i>
                    Marcar como listo para retirar
                  </Button>
                )}

              {checkStatusesExists([5]) &&
                checkStatusesExists([6]) &&
                !checkStatusesExists([7]) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => saveStatusConfirm(7)}
                  >
                    <i className="fa fa-check"></i>
                    Marcar como entregado
                  </Button>
                )}
            </h2>
            <h6 className="text-muted pl-1">
              <i className="fa fa-calendar-day"></i>
              Fecha: {moment(order.created_at).format('DD-MM-YYYY - HH:mm A')}
            </h6>
            <h6 className="text-muted pl-1">
              Tipo orden: {order.type == 1 ? 'Delivery' : 'Recoger'}
            </h6>
            {order.statuses.length > 0 && (
              <h6 className="text-muted pl-1">
                Estado actual:{' '}
                {order.statuses[order.statuses.length - 1].status.name}
              </h6>
            )}
          </div>

          <div className="row">
            {/* payments */}
            <div className="col-md">
              <h5 className="">Pago</h5>
              {(() => {
                if (order && order.last_payment) {
                  return (
                    <ul>
                      <li>
                        <span className="font-weight-bold">Medio:</span>{' '}
                        {order.last_payment.payment_method.name}
                      </li>
                      <li>
                        <span className="font-weight-bold">Referencia:</span>{' '}
                        {order.last_payment.reference}
                      </li>
                      <li>
                        <span className="font-weight-bold">Monto:</span>{' '}
                        {currencyFormat(order.last_payment.amount)}
                      </li>
                      {/* <li>
                        <span className="font-weight-bold">Estado:</span>{' '}
                        {order.last_payment.status}
                      </li> */}
                    </ul>
                  )
                } else {
                  return <p>Pago no recibido.</p>
                }
              })()}
            </div>

            {/* Shipping address */}
            <div className="col-md">
              <h5>{order.type == 1 ? 'Datos de envio' : 'Quien recoge'}</h5>
              {clientInfo && (
                <ul>
                  {clientInfo.address && (
                    <li>
                      <span className="font-weight-bold">Dirección:</span>{' '}
                      {clientInfo.address}
                    </li>
                  )}

                  {clientInfo.address_reference && (
                    <li>
                      <span className="font-weight-bold">
                        Punto de referencia:
                      </span>{' '}
                      {clientInfo.address_reference}
                    </li>
                  )}

                  <li>
                    <span className="font-weight-bold">
                      {order.type == 1 ? 'Quien recoge' : 'Nombre'}:
                    </span>{' '}
                    {clientInfo.name}
                  </li>
                  <li>
                    <span className="font-weight-bold">Teléfono:</span>{' '}
                    {clientInfo.phone}
                  </li>
                </ul>
              )}
            </div>

            {/* Totales order */}
            <div className="col-md">
              <h5 className="">Totales</h5>
              <ul>
                <li>
                  <span className="font-weight-bold">Subtotal:</span>{' '}
                  {currencyFormat(order.subtotal)}
                </li>
                <li>
                  <span className="font-weight-bold">Total:</span>{' '}
                  {currencyFormat(order.total)}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 p-0">
            <div className="card w-100 p-0">
              <h4 className="p-0 m-0 pt-3 pl-3 text-center text-md-left">
                Detalles orden
              </h4>

              <div className="card-header pb-2 bg-white font-weight-bold row text-left d-none d-sm-flex">
                <div className="col my-auto"></div>
                <div className="col my-auto"></div>
                <div className="col my-auto">Cantidad</div>
                <div className="col my-auto">Precio</div>
                <div className="col my-auto">Subtotal</div>
                <div className="col my-auto">Descuento</div>
                <div className="col my-auto">Tax</div>
                <div className="col my-auto">Total</div>
              </div>

              <div className="card-body p-0 m-0">
                {order.order_details.length > 0 &&
                  order.order_details.map(od => (
                    <div className="m-3 border shadow p-2" key={od.id}>
                      <div key={od.id} className="media p-0">
                        <div className="sq align-self-center">
                          <img
                            className="img-fluid my-auto align-self-center mr-2 mr-md-4 pl-0 p-0 m-0"
                            src={od.product.first_image}
                            width="135"
                            height="135"
                            alt="imagen producto"
                          />
                        </div>
                        <div className="media-body my-auto text-left">
                          <div className="row my-auto flex-column flex-md-row">
                            <div className="col my-auto">
                              <h6 className="mb-0">
                                <span className="font-weight-bold">
                                  {od.name}
                                </span>
                              </h6>
                            </div>

                            <div className="col my-auto">
                              <p className="mb-0">
                                <span className="d-sm-none font-weight-bold mr-2">
                                  Cantidad:
                                </span>
                                {od.quantity}
                              </p>
                            </div>

                            <div className="col my-auto">
                              <p className="mb-0">
                                <span className="d-sm-none font-weight-bold mr-2">
                                  Precio:
                                </span>
                                {currencyFormat(od.price)}
                              </p>
                            </div>
                            <div className="col my-auto">
                              <p className="mb-0">
                                <span className="d-sm-none font-weight-bold mr-2">
                                  Subtotal:
                                </span>
                                {currencyFormat(od.subtotal)}
                              </p>
                            </div>

                            <div className="col my-auto">
                              <p className="mb-0">
                                <span className="d-sm-none font-weight-bold mr-2">
                                  Descuento:
                                </span>
                                {currencyFormat(od.discount)}
                              </p>
                            </div>

                            <div className="col my-auto">
                              <p className="mb-0">
                                <span className="d-sm-none font-weight-bold mr-2">
                                  Impuesto:
                                </span>
                                {currencyFormat(od.tax)}
                              </p>
                            </div>

                            <div className="col my-auto">
                              <p className="mb-0">
                                <span className="d-sm-none font-weight-bold mr-2">
                                  Total:
                                </span>
                                {currencyFormat(od.total)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* additional options */}
                      <div className="row border-top">
                        <div className="col-md-6">
                          <h5 className="pt-3">Opciones adicionales</h5>
                          {od.attributes.length > 0 &&
                            od.attributes.map(attr => {
                              return (
                                <ul>
                                  <li>
                                    <span className="font-weight-bold">
                                      {attr.attribute}:
                                    </span>
                                    <ul>
                                      {attr.additional_options.map(ad => {
                                        return (
                                          <li>
                                            {ad.option} (
                                            {currencyFormat(ad.price)})
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  </li>
                                </ul>
                              )
                            })}
                          {od.attributes.length === 0 && (
                            <p className="pl-3">Sin adicionales.</p>
                          )}
                        </div>

                        <div className="col-md-6 pt-3">
                          <h5 className="">Totales</h5>
                          <ul>
                            <li>
                              <span className="font-weight-bold">
                                Subtotal:
                              </span>{' '}
                              {currencyFormat(od.subtotal)}
                            </li>
                            <li>
                              <span className="font-weight-bold">Total:</span>{' '}
                              {currencyFormat(od.total)}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 className="text-muted text-center mt-4">Seguimiento</h4>

      <div className="timeline">
        {order.statuses.length > 0 &&
          order.statuses.sort(compare('status_id')).map(status => (
            <div
              key={status.id}
              className="row no-gutters justify-content-end justify-content-md-around align-items-start timeline-nodes"
            >
              <div className="col-11 col-md-5 order-3 order-md-1 timeline-content position-relative">
                <time
                  style={{
                    position: 'absolute',
                    left: '0',
                    top: '-22px',
                    color: '#7b7b7b',
                    fontSize: '16px',
                  }}
                >
                  <i className="fa fa-calendar-day"></i>
                  {moment(status.created_at).format('DD-MM-YYYY - H:mm A')}
                </time>
                <h3 className="text-light text-left text-capitalize">
                  {status.status.name}
                </h3>

                <p className="text-left text-muted h5">{status.description}</p>
                {status.execute_by === 'distributor' && (
                  <div className="w-100 p-2 m-0 text-right">
                    Repartidor: {status.executable.name}
                  </div>
                )}
              </div>

              <div
                className={
                  'col-2 col-sm-1 order-2 timeline-image text-md-center d-flex align-items-center justify-content-center ' +
                  status.status.icon +
                  ' ' +
                  status.status.css_classes
                }
              ></div>

              <div className="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date"></div>
            </div>
          ))}
      </div>

      {/* {user && user.rol === 'admin' && (
        <div className="col-md-12 text-center">
          <Button variant="contained" color="primary" onClick={openModal}>
            <i className="fa fa-plus"></i>
            Nuevo Estado
          </Button>
        </div>
      )} */}

      <Alert />
      <Modal
        open={open}
        title="Registar nuevo estado"
        closeModal={closeModal}
        zIndex={3}
      >
        <form onSubmit={handleSubmit(saveStatus)}>
          <div>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="status" error={!!errors.status}>
                Estado
              </InputLabel>
              <Select
                value={status.status}
                onChange={handleSelectChange}
                inputRef={register}
                name="status"
                label="Estado"
                error={!!errors.status}
              >
                <MenuItem value="">Seleccione</MenuItem>
                {statuses.map(status => {
                  return (
                    <MenuItem key={status.id} value={status}>
                      {status.name}
                    </MenuItem>
                  )
                })}
              </Select>
              {!!errors.status && (
                <FormHelperText className="text-danger">
                  Debes seleccionar un estado
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="mt-3">
            <TextField
              multiline
              inputRef={register({ required: true })}
              fullWidth
              variant="outlined"
              label="Descripción"
              name="description"
              value={status.description}
              onChange={handleInputChange}
              error={!!errors.description}
              helperText={errors.description && 'El campo es requerido'}
            />
          </div>
          <div className="text-center mt-3">
            <Button type="submit" variant="contained" color="primary">
              <i className="fa fa-save"></i>
              Guardar
            </Button>
          </div>
        </form>
      </Modal>

      <ApprovePaymentModal
        order={order}
        payment={order.last_payment}
        open={openApprovePaymentModal}
        handleClose={closeApprovePaymentModal}
      />
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  storeInfo: state.storeInfo,
})

export default connect(mapStateToProps, { update_alert, update_loading })(
  DetailOrder
)
