import React, { useState, useEffect } from 'react'
import {
  Checkbox,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
  Paper,
  FormControlLabel,
  Box,
} from '@material-ui/core'

import Modal from './Modal'
import AddPaymentMethodModal from './AddPaymentMethodModal'

//Redux
import { connect } from 'react-redux'
import { update_alert } from '../store/actions'

//Validate input con hook form
import { useForm } from 'react-hook-form'
import StoreService from '../services/store.service'
import UtilityService from '../services/utility.service'
//Helpers
import { getMessageError, confirmAlert } from '../helpers'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  addPaymentMethodBtn: {
    background: '#E6E6E6',
    cursor: 'pointer',
    '&:hover': {
      background: '#F9F9F9',
      color: '#52CA8B !important',
      fontWeight: 'bold',
    },
  },
})

const AddAndUpdatePayementMethods = props => {
  const classes = useStyles()

  //Redux
  const { update_alert, store_id } = props
  //STATE
  const [open, setOpen] = useState(false)

  const [paymentMethod, setPaymentMethod] = useState({
    note: '',
  })

  const [womvePaymentMethod, setWomvePaymentMethod] = useState('')

  const [paymentMethods, setPaymentMethods] = useState([])

  //payment methods in store
  const [storePaymentMethods, setStorePaymentMethods] = useState([])

  const [paymentMethodEditId, setPaymentMethodEditId] = useState('')

  //Active parent payment method
  const [activeParentPaymentMethod, setActiveParentPaymentMethod] = useState('')
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false)

  //Edit payemnt method child
  const [
    activePaymentMethodChildEdit,
    setActivePaymentMethodChildEdit,
  ] = useState('')

  //validación for useForm
  const { register, handleSubmit, setValue, errors } = useForm()

  //Methods
  //Change input
  const handleInputChange = e => {
    setPaymentMethod({
      ...paymentMethod,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  //Change select payment method
  const selectPaymentMethod = e => {
    setValue('payment_method', e.target.value)
    setWomvePaymentMethod(e.target.value)
  }

  const handleClose = () => {
    setOpen(false)
    setPaymentMethodEditId('')
    setPaymentMethod({
      note: '',
    })
    setValue('payment_method', '')
    setWomvePaymentMethod('')
  }

  //Open add payment method to payment method store
  const openActivePaymentMethodModal = (pm, edit = false) => {
    setActiveParentPaymentMethod(pm)
    setActivePaymentMethodChildEdit(edit)
    setOpenPaymentMethodModal(true)
  }

  //Close add payment method to payment method store
  const closeActivePaymentMethodModal = newPaymentMethod => {
    if (newPaymentMethod) {
      const parentPaymentMethodIndex = storePaymentMethods.findIndex(
        i => i.id === newPaymentMethod.payment_method_store_id
      )
      if (newPaymentMethod.edit_id) {
        const list_index = storePaymentMethods[
          parentPaymentMethodIndex
        ].payment_method_store_lists.findIndex(
          i => i.id === newPaymentMethod.edit_id
        )

        storePaymentMethods[
          parentPaymentMethodIndex
        ].payment_method_store_lists[list_index] = newPaymentMethod
        setStorePaymentMethods(JSON.parse(JSON.stringify(storePaymentMethods)))
      } else {
        storePaymentMethods[
          parentPaymentMethodIndex
        ].payment_method_store_lists.push(newPaymentMethod)
        setStorePaymentMethods(JSON.parse(JSON.stringify(storePaymentMethods)))
      }
    }

    setActiveParentPaymentMethod('')
    setOpenPaymentMethodModal(false)
    setActivePaymentMethodChildEdit(false)
  }

  //Open edit payment method note modal
  const openEditPaymentMethodNoteModal = pm_id => {
    setOpen(true)
    const payment_method = storePaymentMethods.find(i => i.id === pm_id)
    setValue('payment_method', payment_method)
    setWomvePaymentMethod(payment_method)
    setPaymentMethodEditId(pm_id)
    setPaymentMethod({
      note: payment_method.note ? payment_method.note : '',
    })
  }
  //update payment method note
  const updateNotePaymentMethod = async () => {
    try {
      const { data: res } = await StoreService.updateNotePaymentMethod({
        store_id: store_id,
        id: paymentMethodEditId,
        note: paymentMethod.note,
      })

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })

      const index = paymentMethods.findIndex(i => i.id === paymentMethodEditId)
      storePaymentMethods[index] = res.data
      setStorePaymentMethods(storePaymentMethods)

      handleClose()
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //save
  const save = async () => {
    try {
      const { data: res } = await StoreService.storePaymentMethod({
        store_id: store_id,
        payment_method_id: womvePaymentMethod.id,
        note: paymentMethod.note,
      })

      setStorePaymentMethods([...storePaymentMethods, res.data])
      handleClose()

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //update
  const update = async () => {
    const data = JSON.parse(JSON.stringify(paymentMethod))
    try {
      const form = new FormData()

      //form.append('currency_client', currencyClient.id)

      //   update_alert({
      //     open: true,
      //     severity: 'success',
      //     message: res.message,
      //   })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //Get payment methods wove
  const getPaymentMethods = async store_id => {
    try {
      const { data: res } = await UtilityService.getPaymentMethods(store_id)
      if (res.success) {
        setPaymentMethods(res.data)
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //Get store payment methods
  const getStorePaymentMethods = async store_id => {
    try {
      const { data: res } = await StoreService.getPaymentMethods(store_id)
      setStorePaymentMethods(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //Add new payment method womve
  const addPaymentMethod = () => {
    setOpen(true)
  }

  //Delete store payment method
  const deleteStorePaymentMethod = async (pm_id, index) => {
    const sw = await confirmAlert(
      'Quitar metodo de pago',
      '¿Estas seguro de quitar este metodo de pago de tu tienda?'
    )

    if (sw.value) {
      try {
        const { data: res } = await StoreService.deleteStorePaymentMethod({
          store_id: store_id,
          id: pm_id,
        })
        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })

        storePaymentMethods.splice(index, 1)
        setStorePaymentMethods(JSON.parse(JSON.stringify(storePaymentMethods)))
      } catch (error) {
        const errorMessage = getMessageError(error)
        update_alert({
          open: true,
          severity: 'error',
          message: errorMessage,
        })
      }
    }
  }

  const deletePaymentMethodChild = async (pmsl_id, pm_index, pmsl_index) => {
    const sw = await confirmAlert(
      'Quitar metodo de pago',
      '¿Estas seguro de quitar este metodo de pago de tu tienda?'
    )

    if (sw.value) {
      try {
        const { data: res } = await StoreService.deleteStorePaymentMethodChild({
          store_id: store_id,
          id: pmsl_id,
        })
        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })

        storePaymentMethods[pm_index].payment_method_store_lists.splice(
          pmsl_index,
          1
        )
        setStorePaymentMethods(JSON.parse(JSON.stringify(storePaymentMethods)))
      } catch (error) {
        const errorMessage = getMessageError(error)
        update_alert({
          open: true,
          severity: 'error',
          message: errorMessage,
        })
      }
    }
  }

  //Change accept payment method in pickup or delivery
  const changeAcceptOrderType = async (pm_id, orderType) => {
    const payment_method = storePaymentMethods.find(i => i.id === pm_id)

    if (orderType === 'pickup') {
      payment_method.use_in_pickup = !payment_method.use_in_pickup
    } else if (orderType === 'delivery') {
      payment_method.use_in_delivery = !payment_method.use_in_delivery
    } else {
      update_alert({
        open: true,
        severity: 'error',
        message: 'No se puede completar la acción',
      })
      return
    }

    const {
      data: res,
    } = await StoreService.changeAcceptOrderTypeInPaymentMethod({
      id: payment_method.id,
      store_id: store_id,
      use_in_pickup: payment_method.use_in_pickup,
      use_in_delivery: payment_method.use_in_delivery,
      change: orderType,
    })

    const index = storePaymentMethods.findIndex(i => i.id === pm_id)

    storePaymentMethods[index] = payment_method
    setStorePaymentMethods(JSON.parse(JSON.stringify(storePaymentMethods)))

    update_alert({
      open: true,
      severity: 'success',
      message: res.message,
    })
  }

  //useEffect
  useEffect(() => {
    if (store_id) {
      getStorePaymentMethods(store_id) //Payment methods store
      getPaymentMethods(store_id) //Payment methods available in womve
    }
  }, [store_id]) // eslint-disable-line react-hooks/exhaustive-deps

  //Listen to register validation for appy the rules each input
  useEffect(() => {
    register(
      { name: 'payment_method', type: 'select' },
      {
        required: {
          value: true,
          message: 'El campo metodo de pago es requerido',
        },
      }
    )
  }, [register])

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5 className="pr-2">
          Administra los metodos de pago que aceptas en tu tienda
        </h5>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={addPaymentMethod}
          >
            <i className="fa fa-plus mr-2"></i>
            Nuevo
          </Button>
        </div>
      </div>
      {/* Payment methods store */}
      <div>
        {storePaymentMethods &&
          storePaymentMethods.map((pm, index) => {
            return (
              <Paper className="p-3 mt-5 border" key={pm.id}>
                <h5 className="mb-0">
                  <strong>
                    <i
                      className="fa fa-trash text-danger p-2"
                      onClick={() => deleteStorePaymentMethod(pm.id, index)}
                    ></i>{' '}
                    {pm.payment_method.name}{' '}
                  </strong>
                  <span className="ml-4">
                    Aceptar en:{' '}
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => changeAcceptOrderType(pm.id, 'pickup')}
                          checked={pm.use_in_pickup}
                          name="pickup"
                        />
                      }
                      label="Recoger"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() =>
                            changeAcceptOrderType(pm.id, 'delivery')
                          }
                          checked={pm.use_in_delivery}
                          name="delivery"
                        />
                      }
                      label="Delivery"
                    />
                  </span>
                </h5>

                <p className="m-0 pt-0 pl-4">
                  Nota al cliente: {pm.note ? pm.note : 'S/N'}{' '}
                  <i
                    className="fa fa-edit text-success p-2"
                    onClick={() => openEditPaymentMethodNoteModal(pm.id)}
                  ></i>
                </p>

                <div className="row">
                  {pm.payment_method_store_lists.length > 0 &&
                    pm.payment_method_store_lists.map((pmsl, pmsl_index) => {
                      return (
                        <div className="col-md-4">
                          <Box className="border m-4 p-3 position-relative">
                            <ul
                              className="p-0 m-0"
                              style={{ listStyle: 'none' }}
                            >
                              {pmsl.payment_method_fields.map(field => {
                                return (
                                  <li>
                                    {field.name}: {field.value}
                                  </li>
                                )
                              })}
                            </ul>
                            <div
                              style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                              }}
                            >
                              <i
                                className="fa fa-edit text-success p-2 mr-3"
                                onClick={() =>
                                  openActivePaymentMethodModal(pm, pmsl)
                                }
                              ></i>
                              <i
                                className="fa fa-trash text-danger p-2"
                                onClick={() =>
                                  deletePaymentMethodChild(
                                    pmsl.id,
                                    index,
                                    pmsl_index
                                  )
                                }
                              ></i>
                            </div>
                          </Box>
                        </div>
                      )
                    })}

                  <div className="col-md-4">
                    <Box
                      className={
                        'border m-4 p-3 ' + classes.addPaymentMethodBtn
                      }
                    >
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          height: `${
                            pm.payment_method.init_fields.length * 20
                          }px`,
                        }}
                        onClick={() => openActivePaymentMethodModal(pm, '')}
                      >
                        <h6>
                          <i className="fa fa-plus mr-3"></i> AGREGAR{' '}
                          {pm.payment_method.name.toUpperCase()}
                        </h6>
                      </div>
                    </Box>
                  </div>
                </div>
              </Paper>
            )
          })}
      </div>

      {/* Modal add payment method womve */}
      <Modal
        open={open}
        zIndex={1}
        title="Agregar nuevo metodo de pago"
        closeModal={handleClose}
        maxWidth="sm"
      >
        <form
          onSubmit={handleSubmit(
            paymentMethodEditId ? updateNotePaymentMethod : save
          )}
        >
          <DialogContent className="pt-0">
            <div>
              {paymentMethodEditId ? (
                <p className="pt-0">
                  Edita la nota que el cliente ve para el metodo de pago que
                  aceptas en tu negocio.
                </p>
              ) : (
                <p className="pt-0">
                  Elige un nuevo metodo de pago y agregalo a tus metodos de
                  pagos que aceptas en tu negocio.
                </p>
              )}
            </div>
            {!paymentMethodEditId && (
              <div>
                <FormControl
                  variant="outlined"
                  className="w-100"
                  error={!!errors.payment_method}
                >
                  <InputLabel id="select_payment_method_label">
                    Metodo de pago
                  </InputLabel>
                  <Select
                    label="Metodo de pago"
                    labelId="select_payment_method_label"
                    id="select_payment_method"
                    value={womvePaymentMethod}
                    onChange={selectPaymentMethod}
                    inputRef={register}
                    name="payment_method"
                  >
                    <MenuItem value="none" disabled>
                      Seleccionar
                    </MenuItem>
                    {paymentMethods.map(pm => {
                      return (
                        <MenuItem key={pm.id} value={pm}>
                          {pm.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {!!errors.payment_method && (
                    <p className="text-danger text-small">
                      {errors.payment_method.message}
                    </p>
                  )}
                </FormControl>
              </div>
            )}

            <div className="mt-3">
              <TextField
                fullWidth
                variant="outlined"
                label="Nota"
                name="note"
                type="text"
                value={paymentMethod.note}
                onChange={handleInputChange}
                helperText={
                  paymentMethodEditId
                    ? ''
                    : 'Si lo deseas puedes dejar una nota para que tus clientes la vean cuando te vayan a pagar usando este metodo de pago'
                }
              />
            </div>

            <div className="mt-2 mb-2 d-flex justify-content-end">
              <Button type="submit" variant="contained" color="primary">
                <i className="fa fa-save mr-2"></i>
                {paymentMethodEditId ? 'Actualizar' : 'Guardar'}
              </Button>
            </div>
          </DialogContent>
        </form>
      </Modal>

      <AddPaymentMethodModal
        open={openPaymentMethodModal}
        paymentMethod={activeParentPaymentMethod}
        activePaymentMethodChildEdit={activePaymentMethodChildEdit}
        handleClose={closeActivePaymentMethodModal}
      />
    </>
  )
}

export default connect(null, { update_alert })(AddAndUpdatePayementMethods)
