import React, { useState, useEffect, useRef } from 'react'
import Loader from './Loader'
import { TextField, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const SearchInput = props => {
  const loaded = useRef(false)

  //STATE
  const [search, setSearch] = useState('')

  const handleInputChange = ev => {
    let value = ev.currentTarget.value
    setSearch(value)
    // executeSearch(value)
  }

  const executeSearch = value => {
    props.executeSearch(value)
  }

  //Use Effect
  useEffect(() => {
    if (loaded.current) {
      const timeOutId = setTimeout(() => executeSearch(search), 400)
      return () => clearTimeout(timeOutId)
    } else {
      loaded.current = true
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      style={{
        //width: props.width ? props.width : 'auto',
        //position: 'relative',
        //display: 'flex',
        // marginRight: '20px',
        padding: '10px',
      }}
    >
      <div className="mb-1">
        <TextField
          style={{
            width: props.width ? props.width : 'auto',
            minWidth: '300px',
          }}
          size={props.size ? props.size : 'small'}
          onChange={handleInputChange}
          variant="outlined"
          label={props.label ? props.label : ''}
          placeholder={props.placeholder ? props.placeholder : ''}
          value={search}
        ></TextField>
        {/* <IconButton color="primary" style={{ position: 'absolute' }}>
          <SearchIcon />
        </IconButton> */}
      </div>

      <div style={{ position: 'absolute', bottom: '-25px' }}>
        {props.loading && (
          <Loader
            type="ThreeDots"
            color="#757575"
            height={30}
            width={30}
            text="Buscando"
          />
        )}
      </div>
    </div>
  )
}

export default SearchInput
