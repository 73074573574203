import React, { useState, useEffect } from 'react'
import { Select, MenuItem, FormControl } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const SelectControls = props => {
  //State
  const [selected, setSelected] = useState('')
  const [parent_attribute_name, setParentAttributeName] = useState('')

  //Validate useForm
  const { register, setValue, errors } = useFormContext()

  useEffect(() => {
    if (props.clearOptions) {
      setValue(parent_attribute_name)
      setSelected('')
    }
  }, [props.clearOptions]) // eslint-disable-line react-hooks/exhaustive-deps

  //Listen to register validation for appy the rules each input
  useEffect(() => {
    const option = [...props.options].shift()
    const attribute_name = `attribute_${option.attribute_id}`
    setParentAttributeName(attribute_name)

    register(
      { name: attribute_name, type: 'select' },
      {
        required: {
          value: props.required,
          message: 'Este campo es requerido',
        },
      }
    )
  }, [register]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectOption = event => {
    props.callback(event.target.value)
    setValue(parent_attribute_name, event.target.value)
    setSelected(event.target.value)
  }
  return (
    <li>
      <FormControl variant="outlined">
        {!!errors[parent_attribute_name] && (
          <p className="text-danger text-small">
            {errors[parent_attribute_name].message}
          </p>
        )}
        <Select
          value={selected}
          onChange={selectOption}
          inputRef={register}
          name={parent_attribute_name}
        >
          <MenuItem value="">Seleccionar</MenuItem>
          {props.options.map(o => {
            return (
              <MenuItem key={o.id} value={o}>
                {o.name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </li>
  )
}

export default SelectControls
