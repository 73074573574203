import React, { useState, useEffect } from 'react'
import { Checkbox } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { getValidationRules, calcPrice } from '../helpers'
import { connect } from 'react-redux'

const CheckboxControls = props => {
  //State
  const [checkedOptions, setCheckedOtions] = useState([])
  //Validate useForm
  const { register, errors } = useFormContext()

  //Redux state
  const { currentCurrencyClient } = props

  //Props
  const { store } = props

  useEffect(() => {
    if (props.clearOptions) {
      setCheckedOtions([])
    }
  }, [props.clearOptions]) // eslint-disable-line react-hooks/exhaustive-deps

  //Listen to register validation for appy the rules each input
  useEffect(() => {
    props.options.forEach(option => {
      register({ name: `option_${option.id}` }, getValidationRules(option))
    })
  }, [register]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectOption = option => event => {
    let new_options = []
    if (event.target.checked) {
      new_options = [...checkedOptions, option.id]
    } else {
      new_options = checkedOptions.filter(o => o !== option.id)
    }
    setCheckedOtions(new_options)
    props.callback(option)
  }

  return (
    <>
      {props.options.map(o => {
        return (
          <li key={o.id}>
            <label className="label-option">
              <Checkbox
                inputRef={register}
                checked={checkedOptions.includes(o.id)}
                name={'option_' + o.id}
                value={o.id}
                onChange={selectOption(o)}
              />
              <span className="name-option">
                {o.name} (+{' '}
                {calcPrice(
                  o.price,
                  currentCurrencyClient,
                  store.currency,
                  store.exchange_rate
                )}
                )
              </span>
            </label>
            {!!errors['option_' + o.id] && (
              <p className="text-danger text-small">
                {errors['option_' + o.id].message}
              </p>
            )}
          </li>
        )
      })}
    </>
  )
}
const mapStateToProps = state => ({
  currentCurrencyClient: state.currentCurrencyClient,
})
export default connect(mapStateToProps, null)(CheckboxControls)
