import React, { useState, useEffect } from 'react'

import Modal from '../components/Modal'
import {
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'

//Services
import StoreService from '../services/store.service'

//Redux
import { update_alert } from '../store/actions'
import { connect } from 'react-redux'

import { useForm } from 'react-hook-form'
import { getMessageError } from '../helpers'

const AddStoreWorkdayModal = props => {
  //Props
  const { open, handleClose, workdays, storeWorkdays, store_id } = props

  //Redux
  const { update_alert } = props

  //State
  const [workday, setWorkday] = useState('')

  //validación for useForm
  const { register, handleSubmit, setValue, errors } = useForm()

  //Methods
  const selectWorkday = ev => {
    const value = ev.target.value
    setValue('workday', value)
    setWorkday(value)
  }

  const verifyIdExists = id => {
    const exists = storeWorkdays.find(i => i.workday_id === id)
    return exists ? 1 : 0
  }

  const save = async () => {
    try {
      const { data: res } = await StoreService.saveStoreWorkday({
        ...workday,
        store_id: store_id,
      })
      setValue('workday', '')
      setWorkday('')
      handleClose(res.data)
      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //UseEffect
  useEffect(() => {
    register(
      { name: 'workday', type: 'select' },
      {
        required: {
          value: true,
          message: 'El campo día de atención es requerido',
        },
      }
    )
  }, [register])

  return (
    <>
      <Modal
        open={open}
        zIndex={1}
        title="Agregar nuevo dia de atención"
        closeModal={handleClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <form onSubmit={handleSubmit(save)}>
          <DialogContent className="pt-2">
            <div>
              <FormControl
                variant="outlined"
                className="w-100"
                error={!!errors.workday}
              >
                <InputLabel id="select_workday_label">
                  Día de atención
                </InputLabel>
                <Select
                  label="Metodo de pago"
                  labelId="select_workday_label"
                  id="select_workday"
                  value={workday}
                  onChange={selectWorkday}
                  inputRef={register}
                  name="workday"
                >
                  <MenuItem value="none" disabled>
                    Seleccionar
                  </MenuItem>
                  {workdays.map(wd => {
                    if (!verifyIdExists(wd.id)) {
                      return (
                        <MenuItem key={wd.id} value={wd}>
                          {wd.name}
                        </MenuItem>
                      )
                    }
                  })}
                </Select>
                {!!errors.workday && (
                  <p className="text-danger text-small">
                    {errors.workday.message}
                  </p>
                )}
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary">
              <i className="fa fa-save mr-3"></i>
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Modal>
    </>
  )
}

export default connect(null, { update_alert })(AddStoreWorkdayModal)
