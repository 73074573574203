import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  closeButton: {
    position: "absolute",
    right: "16px",
    top: "10px",
    color: "#b3b3b3",
  },
});

const Modal = (props) => {
  //Props
  const { open, title, children, closeModal, maxWidth, onClose } = props;

  //Methods
  const handleClose = () => {
    closeModal();
  };

  //Styles
  const classes = useStyles();

  return (
    <>
      <Dialog
        style={{ zIndex: 1101 + parseInt(props.zIndex ? props.zIndex : 0) }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth={maxWidth ? maxWidth : "xs"}
      >
        <DialogTitle className="border-bottom mb-3">
          {title}
          {onClose ? (
            <IconButton
              aria-label="cerrar"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {props.actions && (
          <DialogActions
            style={{ background: "#fafafa", height: "70px" }}
            className="d-flex w-100 align-items-center pr-5 border-top"
          >
            {props.actions}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default Modal;
