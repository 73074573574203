// React
import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Checkbox,
  MenuItem,
  Radio,
  Fade,
  FormControl,
  Select,
  Button,
  IconButton,
  InputLabel,
} from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { connect } from 'react-redux'
import { update_alert } from '../../store/actions'
import Modal from '../../components/Modal'
import AddAttributeProduct from '../../components/AddAttributeProduct'
import AddOptionAttribute from '../../components/AddOptionAttribute'

//Validate input con hook form
import { useForm } from 'react-hook-form'

// Services
import ProductService from '../../services/product.service'
import UtilityService from '../../services/utility.service'

//Helpers
import { getMessageError } from '../../helpers'

//Dialog
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { currencyFormat } from '../../helpers'

// css
import './Products.scss'
import { NativeSelect, Tooltip } from '@material-ui/core'
import Swal from 'sweetalert2'

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '1rem',
  },
  table: {
    maxWidth: '100%',
  },
})

const init_state_product = {
  name: '',
  description: '',
  price: '',
  product_attributes: [],
  active: true,
}

const Products = props => {
  const classes = useStyles()
  const { update_alert } = props
  //STATE
  const [rows, setRows] = useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [open, setOpen] = React.useState(false)
  const [product, setProduct] = useState(init_state_product)
  const [images, setImages] = useState([])

  //Redux state
  const { storeInfo } = props

  //Edit product
  const [productEditId, setProductEditId] = useState(null)
  const [productEditChangeImage, setProductEditChangeImage] = useState(false)

  //Array delete images product
  const [arrayDeleteImagesProduct, setArrayDeleteImagesProduct] = useState([])

  //Array delete product attributes
  const [
    arrayDeleteProductAttributes,
    setArrayDeleteProductAttributes,
  ] = useState([])
  //Array delete options product attribute
  const [
    arrayDeleteProductAttributeOptions,
    setArrayDeleteProductAttributeOptions,
  ] = useState([])

  const [checkedAdditionals, setCheckedAdditionals] = useState(false)
  const [openModalAddAttribute, setOpenModalAddAttribute] = useState(false)
  const [open_modal_add_option, setOpenModalAddOption] = useState(false)
  const [index_active_attribute, setIndexActiveAttribute] = useState(false)
  const [active_attribute, setActiveAttribute] = useState(false)
  const [error_image, setErrorImage] = useState(null)
  const [product_categories, setProductCategories] = useState([])
  const [product_category, setProductCategory] = useState('')

  //validación por useForm
  const { register, handleSubmit, errors, setValue } = useForm()

  //Methods
  //Checked additional in product
  const handleChangeAdditionals = ev => {
    setCheckedAdditionals(ev.target.checked)
  }

  //Change input product
  const handleInputChange = e => {
    let value = ''
    if (e.currentTarget.name === 'active') {
      value = e.target.checked
    } else {
      value = e.currentTarget.value
    }

    setProduct({
      ...product,
      [e.currentTarget.name]: value,
    })
  }

  //Open modal new product
  const handleClickOpen = () => {
    setOpen(true)
  }

  //Open modal edit product
  const editProduct = (product_id, index) => {
    setProductEditId(product_id)
    const edit_product = JSON.parse(JSON.stringify(rows[index]))
    edit_product.description = edit_product.description ?? ''
    setProduct(edit_product)

    const pc = product_categories.find(
      i => i.id === edit_product.product_category_id
    )
    setValue('product_category', pc)
    setProductCategory(pc)

    if (edit_product.images) {
      const images = edit_product.images.map(({ url, ...rest }) => ({
        ...rest,
        url: url,
        file: 0,
      }))
      setImages(images)
    }

    if (edit_product.have_attributes) {
      setCheckedAdditionals(true)
    }

    setProductEditChangeImage(false)
    setOpen(true)
  }

  //Close modal new product
  const handleClose = () => {
    setProduct(init_state_product)
    setImages([])
    setProductEditId(null)
    setProductEditChangeImage(false)
    setArrayDeleteImagesProduct([])
    setArrayDeleteProductAttributes([])
    setArrayDeleteProductAttributeOptions([])
    setCheckedAdditionals(false)
    setOpen(false)
  }

  //Change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  //Change items per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const addImage = e => {
    const file = e.target.files[0]
    setImages([
      ...images,
      {
        url: URL.createObjectURL(file),
        file: file,
      },
    ])
    if (productEditId) {
      setProductEditChangeImage(true)
    }
  }

  //save
  const save = async () => {
    let data = JSON.parse(JSON.stringify(product))
    data.have_additional_options = checkedAdditionals

    if (images.length === 0) {
      setErrorImage('Debes elegir una o mas imagenes para tu producto')
      return
    }

    try {
      const form = new FormData()
      images.forEach((file, index) => {
        form.append(`files[${index}]`, file.file)
      })

      form.append('product_category_id', product_category.id)
      form.append('name', data.name)
      form.append('description', data.description ? data.description : 0)
      form.append('price', data.price)
      form.append('active', data.active ? 1 : 0)
      form.append(
        'have_additional_options',
        data.have_additional_options ? 1 : 0
      )
      form.append('product_attributes', JSON.stringify(data.product_attributes))

      const { data: res } = await ProductService.save(form)
      handleClose()

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
      setRows([res.data, ...rows])
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  const update = async () => {
    const data = JSON.parse(JSON.stringify(product))
    data.have_additional_options = checkedAdditionals

    if (images.length === 0) {
      setErrorImage('Debes elegir una o mas imagenes para tu producto')
      return
    }

    try {
      const form = new FormData()
      console.log(arrayDeleteImagesProduct)

      images.forEach((file, index) => {
        if (file.file) form.append(`files[${index}]`, file.file)
      })
      form.append('_method', 'PUT')
      form.append('id', productEditId)
      form.append('product_category_id', product_category.id)
      form.append('name', data.name)
      form.append('description', data.description ? data.description : 0)
      form.append('price', data.price)
      form.append('active', data.active ? 1 : 0)

      form.append('product_edit_change_image', productEditChangeImage ? 1 : 0)
      form.append(
        'array_delete_images',
        JSON.stringify(arrayDeleteImagesProduct)
      )

      form.append(
        'array_delete_attributes',
        JSON.stringify(arrayDeleteProductAttributes)
      )
      form.append(
        'array_delete_options',
        JSON.stringify(arrayDeleteProductAttributeOptions)
      )

      form.append(
        'have_additional_options',
        data.have_additional_options ? 1 : 0
      )
      form.append('product_attributes', JSON.stringify(data.product_attributes))

      const { data: res } = await ProductService.update(form)
      handleClose()

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
      //Find row menu
      const new_rows = JSON.parse(JSON.stringify(rows))
      const index = new_rows.findIndex(i => i.id === res.data.id)
      new_rows[index] = res.data
      setRows(new_rows)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //get all products
  const get = async () => {
    const { data: res } = await ProductService.get(1, '')
    setRows(res.data.data)
  }

  //Open modal add attribute
  const openModal = () => {
    setOpenModalAddAttribute(true)
  }

  //Close modal add attribute
  const closeModal = attribute => {
    setOpenModalAddAttribute(false)
    if (attribute === undefined) return

    setProduct({
      ...product,
      product_attributes: [...product.product_attributes, attribute],
    })
  }

  //Open modal add option
  const modalAddOption = (index, attr) => {
    setIndexActiveAttribute(index)
    setOpenModalAddOption(true)
    setActiveAttribute(attr)
  }

  //Close modal add option
  const closeModalAddOption = option => {
    setOpenModalAddOption(false)
    if (option === undefined) return

    product.product_attributes[index_active_attribute].options.push(option)
    setProduct({
      ...product,
    })
  }

  //Delete attribute
  const deleteAttribute = indexAttr => {
    Swal.fire({
      title: 'Quitar Grupo',
      text: '¿Estas seguro de quitar este grupo de opciones?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        //If update
        if (productEditId) {
          const product_attribute_id = product.product_attributes[indexAttr].id
          if (product_attribute_id) {
            setArrayDeleteProductAttributes([
              ...arrayDeleteProductAttributes,
              product_attribute_id,
            ])
          }
        }

        product.product_attributes.splice(indexAttr, 1)
        setProduct({
          ...product,
        })
      }
    })
  }

  //Delete image product
  const deleteImage = index => {
    Swal.fire({
      title: 'Quitar imagen',
      text: '¿Estas seguro de quitar esta imagen de tu producto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        //If update
        if (productEditId) {
          const image_id = images[index].id
          if (image_id) {
            setArrayDeleteImagesProduct([...arrayDeleteImagesProduct, image_id])
            setProductEditChangeImage(true)
          }
        }

        images.splice(index, 1)
        setImages([...images])
      }
    })
  }

  //Delete option
  const deleteOption = (indexOption, indexAttr) => {
    Swal.fire({
      title: 'Quitar Opción',
      text: '¿Estas seguro de quitar esta opción?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        //If update
        if (productEditId) {
          const product_attribute = product.product_attributes[indexAttr]
          const product_attribute_option_id =
            product_attribute.options[indexOption].id
          if (product_attribute_option_id) {
            setArrayDeleteProductAttributeOptions([
              ...arrayDeleteProductAttributeOptions,
              {
                option_id: product_attribute_option_id,
                attribute_id: product_attribute.id,
              },
            ])
          }
        }

        product.product_attributes[indexAttr].options.splice(indexOption, 1)
        setProduct({
          ...product,
        })
      }
    })
  }

  //Delete product
  const deleteProduct = (product_id, index) => {
    Swal.fire({
      title: 'Eliminar producto',
      text: '¿Estas seguro de eliminar este producto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        const { data: res } = await ProductService.delete({ id: product_id })

        if (res.success) {
          rows.splice(index, 1)
          setRows([...rows])
        }
      }
    })
  }

  //Select product category
  const selectProductCategory = event => {
    setValue('product_category', event.target.value)
    setProductCategory(event.target.value)
  }

  //Get product cvategories
  const getProductCategories = async () => {
    try {
      const { data: res } = await UtilityService.getProductCategories()
      setProductCategories(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //UseEffects
  useEffect(() => {
    get()
    getProductCategories()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isFirstRun = useRef(true)

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    if (images.length === 0) {
      setErrorImage('Debes elegir una o mas imagenes para tu producto')
    } else {
      setErrorImage(null)
    }
  }, [images])

  //Listen to register validation for appy the rules each input
  useEffect(() => {
    register(
      { name: 'product_category', type: 'select' },
      {
        required: {
          value: true,
          message: 'Este campo categoría es requerido',
        },
      }
    )
  }, [register])

  return (
    <>
      <div className="text-right p-2">
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          <i className="fa fa-plus"></i>
          Nuevo
        </Button>
      </div>
      <Paper className={classes.root}>
        <div className="headerTable">
          <span className="title">Lista de productos</span>
          <TextField variant="outlined" label="Buscar"></TextField>
        </div>

        <TableContainer className="containerTable">
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Precio</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                      <img
                        className="mr-2"
                        src={row.first_image}
                        width="40"
                        height="40"
                        alt="imagen producto"
                      />
                      {row.name}
                    </TableCell>
                    <TableCell>{currencyFormat(row.price)}</TableCell>
                    <TableCell>
                      {row.description ? row.description : 's/d'}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => editProduct(row.id, index)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() => deleteProduct(row.id, index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <Modal
        open={open}
        zIndex={1}
        title="Nuevo producto"
        closeModal={handleClose}
        maxWidth="sm"
      >
        <form onSubmit={handleSubmit(productEditId ? update : save)}>
          <DialogContent>
            <div>
              <FormControl
                variant="outlined"
                className="w-100"
                error={!!errors.product_category}
              >
                <InputLabel id="select_product_category_label">
                  Categoría
                </InputLabel>
                <Select
                  label="Categoría"
                  labelId="select_product_category_label"
                  id="select_product_category"
                  value={product_category}
                  onChange={selectProductCategory}
                  inputRef={register}
                  name="product_category"
                >
                  <MenuItem value="none" disabled>
                    Seleccionar
                  </MenuItem>
                  {product_categories.map(pc => {
                    return (
                      <MenuItem key={pc.id} value={pc}>
                        {pc.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                {!!errors.product_category && (
                  <p className="text-danger text-small">
                    {errors.product_category.message}
                  </p>
                )}
              </FormControl>
            </div>

            <div className="mt-3">
              <TextField
                inputRef={register({ required: true })}
                fullWidth
                variant="outlined"
                label="Nombre"
                name="name"
                type="text"
                value={product.name}
                onChange={handleInputChange}
                error={!!errors.name}
                helperText={errors.name && 'El campo nombre es requerido'}
              />
            </div>

            <div className="mt-3">
              <TextField
                inputRef={register({ required: true })}
                fullWidth
                variant="outlined"
                label="Precio"
                name="price"
                type="number"
                value={product.price}
                onChange={handleInputChange}
                error={!!errors.price}
                helperText={errors.price && 'El campo precio es requerido'}
              />
            </div>

            <div className="mt-3">
              <TextField
                fullWidth
                variant="outlined"
                label="Descripción"
                name="description"
                type="text"
                value={product.description}
                onChange={handleInputChange}
                multiline
              />
            </div>

            <div className="mt-3">
              <p className="font-weight-bold">Imagenes</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {images.map((i, index) => {
                  return (
                    <div
                      style={{
                        width: '100px',
                        height: '100px',
                        position: 'relative',
                        border: '1px solid #ccc',
                        marginRight: '8px',
                      }}
                      key={i.url}
                    >
                      <i
                        className="fa fa-trash text-danger"
                        style={{
                          position: 'absolute',
                          right: 0,
                          top: '5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => deleteImage(index)}
                      ></i>
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                        src={i.url}
                        key={i.url}
                        alt="imagen producto"
                      />
                    </div>
                  )
                })}
                <span>
                  <input
                    className="inputfile"
                    type="file"
                    onChange={addImage}
                    id="file"
                  />
                  <label htmlFor="file">
                    <i className="fa fa-plus-circle"></i>
                  </label>
                </span>
              </div>
              {/* Error si no hay una imagen */}
              <div>
                {error_image && (
                  <div>
                    <p className="text-danger">{error_image}</p>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-3">
              <label htmlFor="active" className="font-weight-bold">
                {product.active ? 'Activo' : 'Inactivo'}
              </label>
              <Checkbox
                id="active"
                color="primary"
                checked={product.active}
                onChange={handleInputChange}
                inputProps={{ 'aria-label': 'Activa' }}
                name="active"
              />
            </div>

            {/* additionals */}

            <div className="row">
              <div className="col-md-12 border-top">
                <label htmlFor="additional_options" className="h6">
                  <Checkbox
                    id="additional_options"
                    checked={checkedAdditionals}
                    onChange={handleChangeAdditionals}
                    color="primary"
                  />
                  El producto tiene opciones adicionales
                </label>
              </div>

              {checkedAdditionals && (
                <>
                  <div className="row w-100 mb-4 border-bottom pb-2">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={openModal}
                      size="small"
                    >
                      <i className="fa fa-plus"></i>
                      Agregar grupo de opciones
                    </Button>
                  </div>

                  {/* If product attributes */}
                  {product.product_attributes.length > 0 && (
                    <ul>
                      {product.product_attributes.map((attr, indexAttr) => (
                        <li key={indexAttr} className="mb-4">
                          <span className="font-weight-bold h5">
                            {attr.name}
                            {attr.required && (
                              <Tooltip
                                placement="top"
                                title="Una opción de este grupo es requerida"
                              >
                                <i className="fa fa-asterisk text-danger ml-1 h6"></i>
                              </Tooltip>
                            )}

                            <i
                              className="fa fa-trash text-danger btn"
                              onClick={() => deleteAttribute(indexAttr)}
                            ></i>

                            <Button
                              color="primary"
                              className="ml-2"
                              variant="outlined"
                              size="small"
                              onClick={() => modalAddOption(indexAttr, attr)}
                            >
                              Agregar opción al grupo
                            </Button>
                          </span>

                          {attr.options.length > 0 && (
                            <>
                              {(() => {
                                if (
                                  attr.type === 'radio' ||
                                  attr.type === 'checkbox'
                                ) {
                                  return (
                                    <ul>
                                      {attr.options.map(
                                        (option, indexOption) => (
                                          <li key={indexOption}>
                                            {attr.type === 'radio' ? (
                                              <Radio name="option" />
                                            ) : (
                                              <Checkbox />
                                            )}
                                            {option.name} - (
                                            {currencyFormat(
                                              option.price,
                                              storeInfo.currency_store_symbol
                                            )}
                                            )
                                            {option.required && (
                                              <Tooltip
                                                placement="top"
                                                title="Esta opción sera requerida"
                                              >
                                                <i className="fa fa-asterisk text-danger ml-2"></i>
                                              </Tooltip>
                                            )}
                                            {option.help_text && (
                                              <Tooltip
                                                placement="top"
                                                TransitionComponent={Fade}
                                                TransitionProps={{
                                                  timeout: 200,
                                                }}
                                                title={option.help_text}
                                                arrow
                                              >
                                                <i className="fa fa-info-circle ml-1 text-info"></i>
                                              </Tooltip>
                                            )}
                                            <i
                                              className="fa fa-trash text-danger btn"
                                              onClick={() =>
                                                deleteOption(
                                                  indexOption,
                                                  indexAttr
                                                )
                                              }
                                            ></i>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )
                                } else if (attr.type === 'select') {
                                  return (
                                    <NativeSelect
                                      variant="outlined"
                                      className="w-100 d-block"
                                    >
                                      {attr.options.map(option => (
                                        <option>
                                          {option.name} - (
                                          {currencyFormat(option.price)})
                                        </option>
                                      ))}
                                    </NativeSelect>
                                  )
                                } else if (attr.type === 'file') {
                                  return (
                                    <>
                                      {attr.options.map(option => (
                                        <div className="col-md-12 mb-4">
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            component="label"
                                          >
                                            <i className="fa fa-upload mr-2"></i>
                                            {option.name} - (
                                            {currencyFormat(option.price)})
                                            <input
                                              type="file"
                                              style={{ display: 'none' }}
                                            />
                                          </Button>
                                        </div>
                                      ))}
                                    </>
                                  )
                                }
                              })()}
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
          </DialogContent>
          <DialogActions className="pb-4">
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button variant="contained" type="submit" color="primary">
              <i className="fa fa-save"></i>
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Modal>

      <AddAttributeProduct
        open={openModalAddAttribute}
        closeModal={closeModal}
        currentAttributes={product.product_attributes}
      ></AddAttributeProduct>

      <AddOptionAttribute
        open={open_modal_add_option}
        closeModal={closeModalAddOption}
        attribute={active_attribute}
      ></AddOptionAttribute>
    </>
  )
}

const mapStateToProps = state => ({
  storeInfo: state.storeInfo,
})

export default connect(mapStateToProps, { update_alert })(Products)
