import React from 'react'
import { connect } from 'react-redux'

import AddProductToCartButton from './AddProductToCartButton'

import { update_cart } from '../store/actions'

import { calcPrice, AddOrUpdateProductInCart } from '../helpers'

import { Button, DialogActions } from '@material-ui/core'

const PublicMyOrder = props => {
  //Redux
  const { cart, update_cart, currentCurrencyClient } = props

  //props
  const { handleActiveStep } = props

  //Methods
  //Add product cart
  const addAndUpdateProductCart = async ({ quantity, product }) => {
    const newCart = await AddOrUpdateProductInCart(
      cart,
      product,
      quantity,
      null
    )
    update_cart(newCart)
  }

  return (
    <>
      <ul
        style={{ listStyle: 'none', position: 'relative' }}
        className="p-0 m-0"
      >
        {cart.items.map(product => {
          return (
            <li
              className="d-flex justify-content-between mb-3 border rounded position-relative"
              key={product.id}
            >
              <div className="d-flex">
                <img
                  className="border"
                  src={product.url_image}
                  alt="imagen"
                  style={{ height: '80px', width: '80px' }}
                />
                <div className="ml-2 w-100">
                  <h6 className="mb-0 w-100 p-1">{product.name}</h6>
                  <p className="m-0 font-weight-bold text-primary w-100">
                    {calcPrice(
                      product.price,
                      currentCurrencyClient,
                      cart.store_currency,
                      cart.store_exchange_rate
                    )}
                  </p>

                  {product.additional_options.length > 0 && (
                    <div>
                      <p className="m-0">Opciones:</p>
                      <ul className="p-0">
                        {product.additional_options.map(ao => (
                          <li className="text-muted">
                            {ao.name} x{' '}
                            {calcPrice(
                              ao.price,
                              currentCurrencyClient,
                              cart.store_currency,
                              cart.store_exchange_rate
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  position: 'absolute',
                  bottom: '0.5rem',
                  right: '0.5rem',
                }}
                className="d-flex justify-content-end"
              >
                <AddProductToCartButton
                  min={0}
                  callback={addAndUpdateProductCart}
                  product={product}
                  showAddButton={true}
                />
              </div>
            </li>
          )
        })}
      </ul>
      <div
        className="d-flex align-items-center m-0 p-0"
        style={{ position: 'absolute', height: '40px', bottom: '7px' }}
      >
        <b>Total: </b>{' '}
        {calcPrice(
          cart.total + cart.total_additional_options,
          currentCurrencyClient,
          cart.store_currency,
          cart.store_exchange_rate
        )}
      </div>
      <DialogActions
        style={{
          position: 'absolute ',
          bottom: '0',
          right: '10px',
        }}
        className="p-2 d-flex align-items-center bg-primary w-100 bg-white border-top"
      >
        <div>
          <Button
            onClick={() => handleActiveStep(1)}
            color="primary"
            variant="contained"
          >
            Continuar
            <i className="fa fa-arrow-right ml-2"></i>
          </Button>
        </div>
      </DialogActions>
    </>
  )
}

const mapStateToProps = state => ({
  cart: state.cart,
  currentCurrencyClient: state.currentCurrencyClient,
})

export default connect(mapStateToProps, { update_cart })(PublicMyOrder)
