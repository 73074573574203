import React, { useState, useEffect } from 'react'
import { Button, Checkbox } from '@material-ui/core'
import { Link } from 'react-router-dom'
import InputQuantity from '../../../components/InputQuantity'
import CartService from '../../../services/cart.service'
import { Decimal } from 'decimal.js'
import {
  update_loading,
  update_alert,
  update_user,
} from '../../../store/actions'
import { connect } from 'react-redux'
import { currencyFormat } from '../../../helpers'
import { getMessageError } from '../../../helpers'
import Modal from '../../../components/Modal'
//import UtilityService from '../../../services/utility.service'
import Swal from 'sweetalert2'
import NewShippingAddress from '../../../components/NewShippingAddress'
import ChangeCurrentShippingAddress from '../../../components/ChangeCurrentShippingAddress'

const Cart = props => {
  //Redux
  const { update_alert, update_loading, update_user, user } = props

  //State
  const [cart, setCart] = useState({
    cart_products: [],
  })
  const [subtotal_cart, setSubtotalCart] = useState(0)
  const [subtotal_additional_options, setSubtotalAdditionalOptions] = useState(
    0
  )
  const [total, setTotal] = useState(0)
  const [open, setOpen] = useState(false)
  //Remove package
  const [checked, setChecked] = React.useState(false)

  //Components
  const CartProductRow = ({ cart_product }) => {
    return (
      <div className="row p-4 border-bottom">
        <div className="col-md-1">
          <img
            src={cart_product.product.first_image}
            alt="imagen producto"
            width={100}
            height={100}
          />
        </div>
        <div className="col-5">
          <h5>
            {cart_product.product.name}{' '}
            <button
              className="btn text-danger"
              onClick={() => removeCartProduct(cart_product.id)}
            >
              <i className="fa fa-trash"></i>
              Eliminar
            </button>
          </h5>

          {cart_product.attributes.length > 0 &&
            cart_product.attributes.map(attr => {
              return (
                <ul>
                  <li>
                    <span className="font-weight-bold text-muted">
                      {attr.attribute.name}:
                    </span>
                    <ul>
                      {attr.additional_options.map(ad => {
                        return (
                          <li>
                            {ad.option.name} ({currencyFormat(ad.option.price)})
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                </ul>
              )
            })}
        </div>
        <div className="col-3">
          <InputQuantity
            quantity={cart_product.quantity}
            changeQuantity={changeQuantity(cart_product.id)}
          />
        </div>
        <div className="col-3">
          {currencyFormat(
            subtotalRow(cart_product.product.price, cart_product.quantity)
          )}
          {cart_product.cart_product_additional_options.map(ad => {
            return (
              ad.option.price > 0 && (
                <p className="p-0 m-0">
                  <span className="text-success font-weight-bold mr-1">+</span>
                  {currencyFormat(ad.option.price)}
                </p>
              )
            )
          })}
        </div>
      </div>
    )
  }

  //Methods
  //Get Cart
  const getCart = async () => {
    try {
      const { data: res } = await CartService.get()
      setCart(res.data)
      //Retiro en tienda
      const checked = res.data.shipping_type !== 'delivery' ? true : false
      setChecked(checked)
    } catch (error) {}
  }

  //Calculate subtotal row
  const subtotalRow = (price, quantity) => {
    let subtotal = new Decimal(price) * new Decimal(quantity)
    return subtotal
  }

  //Calculate subtotal cart and subtotal additional options
  const subtotalCart = () => {
    let subtotal = 0
    let subtotal_additional_options = 0

    cart.cart_products.forEach(cp => {
      subtotal = new Decimal(subtotal).plus(
        new Decimal(cp.product.price) * new Decimal(cp.quantity)
      )

      //Calculate subtotal additional options
      cp.attributes.forEach(attr => {
        attr.additional_options.forEach(ad => {
          subtotal_additional_options = new Decimal(
            subtotal_additional_options
          ).plus(new Decimal(ad.option.price) * new Decimal(cp.quantity))
        })
      })
    })
    setSubtotalAdditionalOptions(subtotal_additional_options.toString())
    setSubtotalCart(subtotal.toString())
    setTotal(Decimal.add(subtotal, subtotal_additional_options))
  }

  //Change queantity product
  const changeQuantity = cart_product_id => async quantity => {
    const index = cart.cart_products.findIndex(cp => cp.id === cart_product_id)

    try {
      update_loading({
        show: true,
        message: 'Actualizando carrito',
      })

      const { data: res } = await CartService.updateQuantity({
        cart_id: cart.id,
        cart_product_id: cart_product_id,
        quantity: quantity.toString(),
      })

      if (res.success) {
        cart.cart_products[index].quantity = res.data.quantity
        setCart(JSON.parse(JSON.stringify(cart)))
        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  //Remove cart product
  const removeCartProduct = cart_product_id => {
    try {
      Swal.fire({
        title: 'Quitar Producto',
        text: '¿Estas seguro de quitar este producto de tu carrito de compra?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, continuar',
        cancelButtonText: 'Cancelar',
      }).then(async result => {
        if (result.value) {
          update_loading({
            show: true,
            message: 'Removiendo producto del carrito',
          })

          const { data: res } = await CartService.removeCartProduct({
            id: cart_product_id,
          })

          //Remove of the cart
          const index = cart.cart_products.findIndex(
            cp => cp.id === cart_product_id
          )
          cart.cart_products.splice(index, 1)
          setCart(JSON.parse(JSON.stringify(cart)))

          update_alert({
            open: true,
            severity: 'success',
            message: res.message,
          })

          update_loading({
            show: false,
          })
        }
      })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  //Change shipping type
  const changeShippingType = async shipping_type => {
    try {
      update_loading({
        show: true,
        message: 'Cambiando tipo de envio',
      })

      const { data: res } = await CartService.changeShippingType({
        shipping_type: shipping_type,
      })

      if (res.success) {
      }

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })

      update_loading({
        show: false,
      })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  //Proccess checkout
  const checkout = async () => {
    try {
      //Validation before pay
      //validate too shipping_type not collect
      if (!user.current_shipping_address && !checked) {
        update_alert({
          open: true,
          severity: 'warning',
          message:
            'Debes agregar una dirección de envio para hacerte llegar tu orden',
        })

        return 0
      }

      const { data: res } = await CartService.getTotal()
      const checkout = new window.WidgetCheckout({
        currency: 'COP',
        amountInCents: res.data.total,
        reference: res.data.reference,
        publicKey: 'pub_test_pXz7ZAeGkNzoiYuBWmyoYFOI49O4fDtr',
        redirectUrl: 'http://localhost:3000/after-payment', // Opcional
      })

      checkout.open(async function (result) {
        const transaction = result.transaction
        // const { data: res } = await PublicService.getTransactionStatus(
        // 	transaction.id
        // )
        // console.log(res)

        props.history.push(`after-payment?id=${transaction.id}`)
      })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  //Open modal
  const openModal = () => {
    setOpen(true)
  }

  //Close modal
  const closeModal = () => {
    setOpen(false)
  }

  //New Current Shipping Address
  const newCurrentShippingAddress = new_address => {
    const new_user = {
      ...user,
      current_shipping_address: new_address,
      shipping_addresses: [...user.shipping_addresses, new_address],
    }
    update_user(new_user)
    closeModal()
  }

  //Update Current Shipping Address
  const updateCurrentShippingAddress = () => {
    closeModal()
  }

  //remove package check
  const handleChange = event => {
    if (event.target.checked) {
      changeShippingType('collect')
    } else {
      changeShippingType('delivery')
    }
    setChecked(event.target.checked)
  }

  //Use effect
  useEffect(() => {
    getCart()
  }, [])

  useEffect(() => {
    subtotalCart()
  }, [cart]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="container-fluid">
        <h3 className="text-center mt-3">Carrito de compra</h3>

        {/* row item cart */}
        {cart.cart_products.length > 0 &&
          cart.cart_products.map(cp => {
            return <CartProductRow key={cp.id} cart_product={cp} />
          })}

        {/* show only cart products exists */}
        {cart.cart_products.length > 0 && (
          <div className="row pt-5">
            <div className="col-md-12">
              <h5>Opciones de envio</h5>
            </div>
            <div className="col">
              {!checked && (
                <div className="mb-3 border-bottom">
                  <div className="row text-left">
                    <h6 className="pl-3">
                      Te enviaremos tu orden a domicilio a la siguinete
                      dirección
                      {user && !user.current_shipping_address && (
                        <Button
                          onClick={openModal}
                          variant="outlined"
                          className="text-success ml-3"
                        >
                          Agregar dirección de envio
                        </Button>
                      )}
                    </h6>
                  </div>
                  {user && user.current_shipping_address && (
                    <div className="pl-3">
                      <p className="p-0 m-0 pt-2 font-weight-bold">
                        {user.current_shipping_address.name}
                        <Button
                          size="small"
                          onClick={openModal}
                          variant="outlined"
                          className="text-success ml-3"
                        >
                          Cambiar
                        </Button>
                      </p>
                      <p className="pl-3">
                        {user.current_shipping_address.address}
                      </p>
                    </div>
                  )}

                  {user && !user.current_shipping_address && (
                    <div className="pl-3">
                      <p className="p-0 m-0 pt-2 pl-3">
                        <i className="fa fa-info-circle"></i>
                        Debes agregar una dirección de envio para hacerte llegar
                        tu orden a domicilio.
                      </p>
                    </div>
                  )}
                </div>
              )}

              {/* Retiro personalmente */}
              <div className="pl-3">
                <label htmlFor="check">
                  Retirar en tienda
                  <Checkbox
                    id="check"
                    checked={checked}
                    onChange={handleChange}
                  />
                </label>
                {checked && (
                  <React.Fragment>
                    <p>
                      Has seleccionado retirar tu orden personalmente en el
                      establecimineto, una vez este lista se te notificara y
                      podras pasar retirandola en la siguiente dirección:
                    </p>
                    <p className="pl-3">{cart.store.address}</p>
                  </React.Fragment>
                )}
              </div>
            </div>

            <div className="col text-right">
              <h5>Subtotal: {currencyFormat(subtotal_cart)}</h5>
              {subtotal_additional_options > 0 && (
                <h5>
                  Adicionales: {currencyFormat(subtotal_additional_options)}
                </h5>
              )}
              <h5>Total: {currencyFormat(total)}</h5>
              <Button
                className="mt-2"
                variant="contained"
                color="primary"
                onClick={checkout}
                size="large"
              >
                <i className="fa fa-money-bill-alt"></i>
                Pagar
              </Button>

              <p className="mt-2">
                <Link to="/">Seguir comprando</Link>
              </p>
            </div>
          </div>
        )}

        {cart.cart_products.length === 0 && (
          <div className="col-md-12 p-5 text-center">
            <h2>Tu carrito esta vacio</h2>
            <p>
              <Link to="/">Seguir comprando</Link>
            </p>
          </div>
        )}
      </div>

      <Modal
        open={open}
        title="Cambiar dirección de envio"
        closeModal={closeModal}
      >
        {(() => {
          if (user.current_shipping_address) {
            return (
              <ChangeCurrentShippingAddress
                callback={updateCurrentShippingAddress}
              />
            )
          } else {
            return <NewShippingAddress callback={newCurrentShippingAddress} />
          }
        })()}
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps, {
  update_loading,
  update_alert,
  update_user,
})(Cart)
