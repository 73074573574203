import React from 'react'

const OrderCompleted = () => {
  return (
    <>
      <h1>
        Orden completada correctamente puedes dar seguimiento a tu orden aquí o
        en la sección mis ordenes
      </h1>
    </>
  )
}

export default OrderCompleted
