import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import './Orders.scss'
import PublicService from '../../services/public.service'
import { currencyFormat } from '../../helpers'
import moment from 'moment'
import { connect } from 'react-redux'
import { update_show_status_order_modal } from '../../store/actions'

//Styles
const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '1rem',
  },
  table: {
    maxWidth: '100%',
  },
})

const Orders = props => {
  //State
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [rows, setRows] = useState([])

  //Redux actions
  const { update_show_status_order_modal } = props

  //Methods
  const getOrders = async () => {
    try {
      const { data: res } = await PublicService.userOrders()
      console.log(res)
      if (res.success) {
        setRows(res.data.data)
      }
    } catch (error) {}
  }

  //Change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  //Cahnge rows per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  //open status order
  const openStatusOrder = data => {
    update_show_status_order_modal({
      ...data,
      open: true,
    })
  }

  //useEffect
  useEffect(() => {
    getOrders()
  }, [])

  return (
    <Paper className={classes.root}>
      <div className="headerTable">
        <span className="title">Mis Ordenes</span>
        <TextField variant="outlined" label="Buscar"></TextField>
      </div>

      <TableContainer className="containerTable">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>Descuento</TableCell>
              <TableCell>Impuesto</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>
                    {JSON.parse(row.order).client_info.name}
                  </TableCell>
                  <TableCell>{currencyFormat(row.subtotal)}</TableCell>
                  <TableCell>{currencyFormat(row.discount)}</TableCell>
                  <TableCell>{currencyFormat(row.tax)}</TableCell>
                  <TableCell>{currencyFormat(row.total)}</TableCell>
                  <TableCell>
                    {moment(row.created_at).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell>{JSON.parse(row.status).name}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        openStatusOrder({
                          order_id: row.order_id,
                          store_id: row.store_id,
                        })
                      }
                      variant="contained"
                      color="primary"
                    >
                      <i className="fa fa-eye"></i>
                      Detalle
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default connect(null, { update_show_status_order_modal })(Orders)
