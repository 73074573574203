import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { connect } from 'react-redux'
import { update_alert } from '../store/actions'

//Alerta
function AlertMessage(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Alert = props => {
  const { alert, update_alert } = props

  //cerrar alert
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      update_alert({
        open: false,
      })
      return
    }

    update_alert({
      open: false,
    })
  }

  return (
    <>
      <Snackbar
        open={alert.open}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleCloseAlert}
      >
        <AlertMessage onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </AlertMessage>
      </Snackbar>
    </>
  )
}

const mapStateToProps = state => ({
  alert: state.alert,
})

export default connect(mapStateToProps, { update_alert })(Alert)
