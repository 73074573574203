import React from "react";

const Home = (props) => {
  return (
    <div className="d-flex justify-content-center p-5">
      <h1>Bienvenido a WOMVE</h1>
    </div>
  );
};

export default Home;
