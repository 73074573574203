import React, { useState, useEffect } from 'react'
import { Radio, Fade, Tooltip } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { connect } from 'react-redux'
import { calcPrice } from '../helpers'

const RadioControls = props => {
  const [checked, setChecked] = useState(false)

  //Redux state
  const { currentCurrencyClient } = props

  //Props
  const { store } = props

  //Validate useForm
  const { register, errors } = useFormContext()

  useEffect(() => {
    if (props.clearOptions) {
      setChecked(false)
    }
  }, [props.clearOptions]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectOption = option => {
    props.callback(option)
    setChecked(option.id)
  }

  //Listen to register validation for appy the rules each input
  useEffect(() => {
    const option = [...props.options].shift()
    register(
      { name: `attribute_${option.attribute_id}` },
      {
        required: {
          value: props.required,
          message: 'Este campo es requerido',
        },
      }
    )
  }, [register]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {props.options.map((o, i) => {
        return (
          <li key={o.id}>
            {!!errors[`attribute_${o.attribute_id}`] && i === 0 && (
              <p className="text-danger text-small">
                {errors[`attribute_${o.attribute_id}`].message}
              </p>
            )}
            <label className="label-option">
              <Radio
                inputRef={register}
                checked={o.id === checked}
                name={`attribute_${o.attribute_id}`}
                value={o.id}
                onChange={() => selectOption(o)}
              />
              <span className="name-option">
                {o.name} (+{' '}
                {calcPrice(
                  o.price,
                  currentCurrencyClient,
                  store.currency,
                  store.exchange_rate
                )}
                )
              </span>

              {o.help_text && (
                <Tooltip
                  placement="top"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 200 }}
                  title={o.help_text}
                  arrow
                >
                  <i className="fa fa-info-circle ml-1 text-info"></i>
                </Tooltip>
              )}
            </label>
          </li>
        )
      })}
    </>
  )
}

const mapStateToProps = state => ({
  currentCurrencyClient: state.currentCurrencyClient,
})

export default connect(mapStateToProps, null)(RadioControls)
