import React, { useState, useEffect } from 'react'
import {
  Button,
  TextField,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  IconButton,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import Modal from '../../components/Modal'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'

import SaveAndEditStoreInfo from '../../components/SaveAndEditStoreInfo'

//Redux
import { connect } from 'react-redux'
import { update_alert, update_store_info } from '../../store/actions'
//Services
import StoreService from '../../services/store.service'

//Styles
const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '1rem',
  },
  table: {
    maxWidth: '100%',
  },
})

const Stores = props => {
  //Redux
  const { update_alert, update_store_info, storeInfo } = props
  //State
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [rows, setRows] = useState([])
  const [open, setOpen] = useState(false)

  //Open modal new store
  const handleClickOpen = () => {
    setOpen(true)
  }

  //Close modal new store
  const handleClose = () => {
    setOpen(false)
  }

  //Get stores
  const getStores = async () => {
    try {
      const { data: res } = await StoreService.get()
      if (res.success) {
        setRows(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //Select store
  const selectStore = async store_id => {
    try {
      const { data: res } = await StoreService.selectStore(store_id)

      if (res.success) {
        localStorage.setItem('womvesi', res.data.womve_schema)
        localStorage.setItem('womvesii', res.data.womvesii)
        localStorage.setItem('storeInfo', JSON.stringify(res.data))
        update_store_info(res.data)
        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  //Save store
  const saveStore = store => {
    setRows([store, ...rows])
    handleClose()
  }

  //Change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  //Cahnge rows per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  //Delete store
  const deleteStore = (store_id, index) => {
    Swal.fire({
      title: 'Eliminar tienda',
      text: '¿Estas seguro de eliminar esta tienda?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        const { data: res } = await StoreService.delete({ id: store_id })

        if (res.success) {
          rows.splice(index, 1)
          setRows([...rows])

          update_alert({
            open: true,
            severity: 'success',
            message: res.message,
          })
        }
      }
    })
  }

  //useEffect
  useEffect(() => {
    getStores()
  }, [])

  return (
    <>
      <div className="text-right p-2">
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          <i className="fa fa-plus"></i>
          Nueva
        </Button>
      </div>

      <Paper className={classes.root}>
        <div className="headerTable">
          <span className="title">Lista de Tiendas</span>
          <TextField variant="outlined" label="Buscar"></TextField>
        </div>

        <TableContainer className="containerTable">
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Categoría</TableCell>
                <TableCell>Subcategoría</TableCell>
                <TableCell>Abierto</TableCell>
                <TableCell>Activo</TableCell>
                <TableCell>Creada</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    className={
                      storeInfo && row.id === storeInfo.id
                        ? 'bg-success text-white'
                        : ''
                    }
                  >
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.store_category.name}</TableCell>
                    <TableCell>
                      {row.store_sub_categories.map(sc => `${sc.name}.`)}
                    </TableCell>
                    <TableCell>{row.closed ? 'No' : 'Sí'}</TableCell>
                    <TableCell>{row.active ? 'Sí' : 'No'}</TableCell>
                    <TableCell>
                      {moment(row.created_at).format('DD-MM-YYYY - HH:mm a')}
                    </TableCell>
                    <TableCell>
                      <Link to={`stores/${row.id}`}>
                        <Button variant="outlined" color="primary">
                          <i className="fa fa-eye"></i>
                          Detalle
                        </Button>
                      </Link>

                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() => deleteStore(row.id, index)}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => selectStore(row.id)}
                      >
                        {storeInfo && row.id === storeInfo.id ? (
                          <DoneAllIcon />
                        ) : (
                          <CheckIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <Modal
        open={open}
        zIndex={1}
        title="Nueva tienda"
        closeModal={handleClose}
        onClose={true}
        maxWidth="sm"
      >
        <SaveAndEditStoreInfo callback1={handleClose} callback2={saveStore} />
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  storeInfo: state.storeInfo,
})

export default connect(mapStateToProps, { update_alert, update_store_info })(
  Stores
)
