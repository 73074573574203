// React
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Checkbox,
  Button,
  IconButton,
} from '@material-ui/core'
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { connect } from 'react-redux'
import { update_alert, update_loading } from '../../store/actions'
import Modal from '../../components/Modal'
import NoRegistersMessage from '../../components/NoRegistersMessage'

//Validate input con hook form
import { useForm } from 'react-hook-form'

// Services
import MenuService from '../../services/menu.service'

//Helpers
import {
  getMessageError,
  defaultRowsPerPage,
  arrayRowsPerPageOptions,
  confirmAlert,
  getStringHourMinutesFromDate,
  getDateWithSetHourMinutes,
} from '../../helpers'

//Import custom search input
import SearchInput from '../../components/SearchInput'

//Dialog
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

//Moment js
import moment from 'moment'

// css
import './Menus.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '1rem',
  },
  table: {
    maxWidth: '100%',
  },
}))

//initial data menu
const initial_state_menu = {
  id: null,
  name: '',
  description: '',
  image: '',
  active: true,
  has_sales_hours: false,
  start_hour: '',
  end_hour: '',
}

const Menus = props => {
  const classes = useStyles()
  //REDUX
  const { update_alert, update_loading } = props

  //STATE
  //Paginate
  const [rows, setRows] = useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage)
  const [totalRows, setTotalRows] = React.useState(0)

  //Open modal
  const [open, setOpen] = React.useState(false)
  const [menu, setMenu] = useState(initial_state_menu)
  const [images, setImages] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [error_image, setErrorImage] = useState(null)
  //Current edit menu id
  const [menu_edit_id, setMenuEditId] = useState(null)
  const [menu_edit_change_image, setMenuEditChangeImage] = useState(false)
  const [selectedInitTime, setSelectedInitTime] = useState(new Date())
  const [selectedEndTime, setSelectedEndTime] = useState(new Date())
  //search term
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)

  //validación por useForm
  const { register, handleSubmit, errors } = useForm()

  //Methods
  //Change input menu
  const handleInputChange = e => {
    let value = ''
    if (
      e.currentTarget.name === 'active' ||
      e.currentTarget.name === 'has_sales_hours'
    ) {
      value = e.target.checked
    } else {
      value = e.currentTarget.value
    }
    console.log(value)
    setMenu({
      ...menu,
      [e.currentTarget.name]: value,
    })
  }

  //Methods
  //Change init time input
  const handleInitTimeChange = date => {
    setSelectedInitTime(date)
  }
  //Change end time input
  const handleEndTimeChange = date => {
    console.log(date)
    setSelectedEndTime(date)
  }

  //Open modal new menu menu
  const handleClickOpen = () => {
    setMenuEditId(null)
    // setMenu(initial_state_menu);
    // setImages([]);
    // setMenuEditChangeImage(false);
    setOpen(true)
  }

  //Open modal edit menu menu
  const editMenu = (menu_id, index) => {
    setMenuEditId(menu_id)
    const edit_menu = rows[index]
    edit_menu.description = edit_menu.description ?? ''
    edit_menu.active = !!edit_menu.active
    console.log(edit_menu)
    setMenu(edit_menu)

    //Set init and end hours
    const dateInit = getDateWithSetHourMinutes(edit_menu.start_hour)
    const dateEnd = getDateWithSetHourMinutes(edit_menu.end_hour)

    setSelectedInitTime(dateInit)
    setSelectedEndTime(dateEnd)

    if (edit_menu.image) {
      setImages([
        {
          url: edit_menu.url_image,
          file: edit_menu.url_image,
        },
      ])
    }

    setMenuEditChangeImage(false)
    setOpen(true)
  }

  //Close modal new menu menu
  const handleClose = () => {
    setMenuEditId(null)
    setMenu(initial_state_menu)
    setImages([])
    setMenuEditChangeImage(false)
    const new_date = new Date()
    setSelectedInitTime(new_date)
    setSelectedEndTime(new_date)
    setOpen(false)
  }

  //Change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  //Change items per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  //Add image input
  const addImage = e => {
    const file = e.target.files[0]
    setImages([
      ...images,
      {
        url: URL.createObjectURL(file),
        file: file,
      },
    ])
    setMenuEditChangeImage(true)
  }

  //Search change input
  const executeSearch = value => {
    console.log('hhh')
    setPage(0)
    setSearch(value)
  }

  //Save
  const save = async () => {
    update_loading({
      show: true,
      message: 'Guardando datos',
    })

    try {
      const data = JSON.parse(JSON.stringify(menu))
      const dateInit = getStringHourMinutesFromDate(selectedInitTime)
      const dateEnd = getStringHourMinutesFromDate(selectedEndTime)

      const form = new FormData()
      if (images.length > 0) {
        images.forEach((file, index) => {
          form.append(`files[${index}]`, file.file)
        })
      }

      form.append('name', data.name)
      form.append('description', data.description)
      form.append('active', data.active ? 1 : 0)
      form.append('has_sales_hours', data.has_sales_hours ? 1 : 0)
      form.append('initTime', `${dateInit}`)
      form.append('endTime', `${dateEnd}`)

      const { data: res } = await MenuService.saveMenu(form)
      handleClose()

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })

      //update correctly table
      if (page !== 0) {
        setPage(0)
        return
      } else {
        if (rowsPerPage < rows.length + 1) {
          rows.pop()
        }
      }
      setRows([{ ...res.data, menus_count: 0 }, ...rows])
      setTotalRows(totalRows => totalRows + 1)
      //end update correctly table
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  //get all menu categories
  const get = async () => {
    if (!search) {
      update_loading({
        show: true,
        message: 'Cargando',
      })
    } else {
      setSearchLoading(true)
    }

    try {
      //add + 1 to page because TablePaginate start in index 0 and laravel get page 1 not zero
      const { data: res } = await MenuService.getMenus(
        page + 1,
        rowsPerPage,
        search
      )
      setTotalRows(res.data.total)
      setRows(res.data.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
    setSearchLoading(false)
  }

  //Delete image menu
  const deleteImage = async index => {
    const sw = await confirmAlert(
      'Quitar imagen',
      '¿Estas seguro de quitar la imagen?'
    )

    if (sw.value) {
      images.splice(index, 1)
      setImages([...images])
      setMenuEditChangeImage(true)
    }
  }

  //Delete menu
  const deleteMenu = async (menu_id, index) => {
    update_loading({
      show: true,
      message: 'Eliminando',
    })

    try {
      const sw = await confirmAlert(
        'Eliminar menú',
        '¿Estas seguro de eliminar este menu?'
      )

      if (sw.value) {
        const { data: res } = await MenuService.deleteMenu({
          id: menu_id,
        })

        if (res.success) {
          rows.splice(index, 1)
          if (rows.length === 0) {
            setPage(0)
            setRows([])
            setTotalRows(0)
          } else {
            setRows([...rows])
            setTotalRows(totalRows => totalRows - 1)
          }

          update_alert({
            open: true,
            severity: 'success',
            message: res.message,
          })
        }
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  //update
  const update = async () => {
    update_loading({
      show: true,
      message: 'Actualizando datos',
    })

    const data = JSON.parse(JSON.stringify(menu))

    const dateInit = getStringHourMinutesFromDate(selectedInitTime)
    const dateEnd = getStringHourMinutesFromDate(selectedEndTime)

    try {
      const form = new FormData()
      if (images.length > 0) {
        images.forEach((file, index) => {
          form.append(`files[${index}]`, file.file)
        })
      }

      form.append('_method', 'PUT')
      form.append('id', data.id)
      form.append('name', data.name)
      form.append('description', data.description ? data.description : 0)
      form.append('has_sales_hours', data.has_sales_hours ? 1 : 0)
      form.append('initTime', dateInit)
      form.append('endTime', dateEnd)
      form.append('active', data.active ? 1 : 0)
      form.append('menu_edit_change_image', menu_edit_change_image ? 1 : 0)

      const { data: res } = await MenuService.updateMenu(form)
      handleClose()

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })

      //Find row menu
      let new_rows = JSON.parse(JSON.stringify(rows))
      const index = new_rows.findIndex(i => i.id === res.data.id)
      new_rows[index] = res.data
      setRows(new_rows)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  //Use Effect
  useEffect(() => {
    get()
  }, [search, page, rowsPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="text-right p-2">
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            <i className="fa fa-plus"></i>
            Nuevo
          </Button>
        </div>
        <Paper className={classes.root}>
          <div className="headerTable">
            <span className="title">Lista de menús</span>
            <SearchInput
              label="Buscar"
              executeSearch={executeSearch}
              loading={searchLoading}
            />
          </div>

          {rows.length > 0 ? (
            <>
              <TableContainer className="containerTable">
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Descripción</TableCell>
                      <TableCell>Hora inicio</TableCell>
                      <TableCell>Hora cierre</TableCell>
                      <TableCell>Activa</TableCell>
                      <TableCell>Acciones</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell>{row.id}</TableCell>
                          <TableCell>
                            <img
                              className="mr-2"
                              src={row.url_image}
                              width="40"
                              height="40"
                              alt="imagen menu"
                            />
                            {row.name}
                          </TableCell>
                          <TableCell>
                            {row.description ? row.description : 's/d'}
                          </TableCell>
                          <TableCell>
                            {moment(row.start_hour, ['H:m']).format('h:mm a')}
                          </TableCell>
                          <TableCell>
                            {moment(row.end_hour, ['H:m']).format('h:mm a')}
                          </TableCell>
                          <TableCell>{row.active ? 'Si' : 'No'}</TableCell>

                          <TableCell>
                            <IconButton
                              aria-label="Editar"
                              onClick={() => editMenu(row.id, index)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              onClick={() => deleteMenu(row.id, index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={arrayRowsPerPageOptions}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <NoRegistersMessage search={search} />
          )}
        </Paper>

        <Modal
          open={open}
          zIndex={1}
          title={(menu_edit_id ? 'Editar' : 'Nuevo') + ' menú'}
          closeModal={handleClose}
          maxWidth="sm"
        >
          <form
            onSubmit={menu_edit_id ? handleSubmit(update) : handleSubmit(save)}
          >
            <DialogContent>
              <div className="mt-3">
                <TextField
                  inputRef={register({ required: true })}
                  fullWidth
                  variant="outlined"
                  label="Nombre"
                  name="name"
                  type="text"
                  value={menu.name}
                  onChange={handleInputChange}
                  error={!!errors.name}
                  helperText={errors.name && 'El campo nombre es requerido'}
                />
              </div>

              <div className="mt-3">
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Descripción"
                  name="description"
                  type="text"
                  value={menu.description}
                  onChange={handleInputChange}
                  multiline
                />
              </div>

              <div className="mt-3">
                <p className="font-weight-bold">Imagen</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {images.map((i, index) => {
                    return (
                      <div
                        style={{
                          width: '100px',
                          height: '100px',
                          position: 'relative',
                          border: '1px solid #ccc',
                          marginRight: '8px',
                        }}
                      >
                        <i
                          className="fa fa-trash text-danger"
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={() => deleteImage(index)}
                        >
                          e
                        </i>
                        <img
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                          src={i.url}
                          key={i.url}
                          alt="imagen menu"
                        />
                      </div>
                    )
                  })}

                  {images.length <= 0 && (
                    <span>
                      <input
                        className="inputfile"
                        type="file"
                        onChange={addImage}
                        id="file"
                      />
                      <label htmlFor="file">
                        <i className="fa fa-plus-circle"></i>
                      </label>
                    </span>
                  )}
                </div>
                {/* Error si no hay una imagen */}
                <div>
                  {error_image && (
                    <div>
                      <p className="text-danger">{error_image}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-3">
                <label htmlFor="active" className="font-weight-bold">
                  Tendra un horario en el que estara disponible
                </label>
                <Checkbox
                  id="active"
                  color="primary"
                  checked={menu.has_sales_hours}
                  onChange={handleInputChange}
                  inputProps={{ 'aria-label': 'Horario de venta' }}
                  name="has_sales_hours"
                />
              </div>
              {menu.has_sales_hours && (
                <div className="mt-3">
                  <hr />
                  <p className="font-weight-bold">
                    Desde que hora a que hora estara disponible esta sección
                  </p>
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Hora inicial"
                    value={selectedInitTime}
                    onChange={handleInitTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'Elegir',
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Hora cierre"
                    value={selectedEndTime}
                    onChange={handleEndTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'Elegir',
                    }}
                  />
                </div>
              )}

              <div className="mt-3">
                <label htmlFor="active" className="font-weight-bold">
                  {menu.active ? 'Activo' : 'Inactivo'}
                </label>
                <Checkbox
                  id="active"
                  color="primary"
                  checked={menu.active}
                  onChange={handleInputChange}
                  inputProps={{ 'aria-label': 'Activa' }}
                  name="active"
                />
              </div>
            </DialogContent>
            <DialogActions className="pb-4">
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button variant="contained" type="submit" color="primary">
                <i className="fa fa-save"></i>
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Modal>
      </MuiPickersUtilsProvider>
    </>
  )
}

export default connect(null, { update_alert, update_loading })(Menus)
