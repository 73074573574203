import http from "../http";
import { defaultRowsPerPage } from "../helpers";

class MenuService {
  //save menu
  static saveMenu(data) {
    return http({
      method: "post",
      url: "menus",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
  }

  //save menu
  static updateMenu(data) {
    return http({
      method: "post",
      url: "menus",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
  }

  //get categories
  static getMenus(page = 1, perPage = defaultRowsPerPage, search = "") {
    return http({
      method: "get",
      url: `menus?page=${page}&per_page=${perPage}&search=${search}`,
    });
  }

  //delete menu
  static deleteMenu(data) {
    return http({
      method: "delete",
      data,
      url: "menus",
    });
  }
}

export default MenuService;
