import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

//Stepper
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'

//Redux
import { connect } from 'react-redux'
import { update_show_complete_order_modal } from '../store/actions'

//Components
import PublicMyOrder from './PublicMyOrder'
import PublicCompleteAndSendOrder from './PublicCompleteAndSendOrder.jsx'
import CreateAccount from './CreateAccount'
import OrderCompleted from './OrderCompleted'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PublicCompleteOrder = props => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  //Redux
  const {
    showCompleteOrderModal,
    update_show_complete_order_modal,
    cart,
    user,
  } = props

  const classes = useStyles()

  //State
  const [activeStep, setActiveStep] = useState(0)

  const [steps, setSteps] = useState([
    'Mi orden',
    'Crear cuenta',
    'Completar orden',
    'Orden Completado',
  ])

  //Methods
  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <PublicMyOrder handleActiveStep={handleActiveStep} />
      case 1:
        return user && user.verified_at ? (
          <PublicCompleteAndSendOrder handleActiveStep={handleActiveStep} />
        ) : (
          <CreateAccount handleActiveStep={handleActiveStep} inOrder={true} />
        )
      case 2:
        return (
          <PublicCompleteAndSendOrder handleActiveStep={handleActiveStep} />
        )
      case 3:
        return <OrderCompleted />
      default:
        return 'Unknown stepIndex'
    }
  }

  const handleActiveStep = step => {
    setActiveStep(step)
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  //UseEffect
  useEffect(() => {
    setActiveStep(0)
  }, [showCompleteOrderModal])

  useEffect(() => {
    if (cart.items.length === 0 && showCompleteOrderModal) {
      update_show_complete_order_modal(false)
    }
  }, [cart])

  useEffect(() => {
    if (user && user.verified_at) setSteps(['Mi pedido', 'Completar pedido'])
    if (!user) setSteps(['Mi pedido', 'Crear cuenta', 'Completar pedido'])
  }, [user])

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={showCompleteOrderModal}
        onClose={() => update_show_complete_order_modal(false)}
        className="p-0 m-0"
        scroll={'paper'}
        TransitionComponent={Transition}
      >
        <DialogTitle className="d-flex align-items-center justify-content-between p-2 w-100 position-relative">
          Completar pedido
          <i
            onClick={() => update_show_complete_order_modal(false)}
            className="fa fa-times text-danger"
            style={{
              position: 'absolute',
              right: '20px',
              top: '15px',
              cursor: 'pointer',
            }}
          ></i>
        </DialogTitle>
        <DialogContent dividers={true} className="p-1 pb-5">
          <div className="d-flex justify-content-center align-items-center mb-2">
            <img
              style={{
                width: '60px',
                height: '60px',
                minWidth: '60px',
                minHeight: '60px',
                borderRadius: '50%',
                border: '1px solid #E6E6E6',
              }}
              src={cart.store_url_logo}
              alt="logo"
            />
            <h5 className="ml-3">{cart.store_name}</h5>
          </div>

          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label} onClick={() => setActiveStep(index)}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed
                  </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <div className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        {/* <DialogActions>
          {activeStep !== 0 && (
            <Button onClick={handleBack} className={classes.backButton}>
              Atras
            </Button>
          )}

          <Button variant="contained" color="primary" onClick={handleNext}>
            {activeStep === steps.length - 1 ? (
              <span>
                <i className="fa fa-paper-plane mr-2"></i>
                Enviar pedido
              </span>
            ) : (
              <span>
                Continuar <i className="fa fa-arrow-right ml-1"></i>
              </span>
            )}
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  )
}

const mapStateToProps = state => ({
  showCompleteOrderModal: state.showCompleteOrderModal,
  cart: state.cart,
  user: state.user,
})
export default connect(mapStateToProps, { update_show_complete_order_modal })(
  PublicCompleteOrder
)
