import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import PublicService from '../../../services/public.service'
import { Link } from 'react-router-dom'
import { update_alert, update_loading } from '../../../store/actions'
import { connect } from 'react-redux'
import { getMessageError } from '../../../helpers'
import Alert from './../../../components/Alert'
import Loading from './../../../components/Loading'

const AfterPayment = props => {
  //Query params url
  const parsed = queryString.parse(props.location.search)

  //State
  const [transaction, setTransaction] = useState({
    classes: {
      icon: '',
      text: '',
    },
  })

  //Redux actions
  const { update_alert, update_loading } = props

  //Methods
  const getTransactionStatus = async id => {
    try {
      update_loading({
        show: true,
        message: 'Consultando data por favor espere un momento',
      })

      const { data: res } = await PublicService.getTransactionStatus(id)
      setTransaction(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)

      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  useEffect(() => {
    getTransactionStatus(parsed.id)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        <Loading></Loading>
      </div>
      <div className="container">
        <div className="text-center">
          <span
            className={
              'display-3 ' +
              transaction.classes.icon +
              ' ' +
              transaction.classes.text
            }
          ></span>
          <h2>
            <span className={transaction.classes.text}>
              {transaction.status}
            </span>
          </h2>
          <h2>Referencia: {transaction.reference}</h2>

          <p>{transaction.message}</p>
          {transaction.status_message && (
            <p>
              <span className="text-info">Información adicional:</span>{' '}
              {transaction.status_message}
            </p>
          )}

          {(transaction.status === 'APPROVED' ||
            transaction.status === 'PENDING') && (
            <p>
              Puedes dar seguimiento en todo momento a tu pedido en tu seccion
              de ordenes{' '}
              <Link className="font-weight-bold" to="/orders">
                Click Aquí
              </Link>
            </p>
          )}

          {transaction.status &&
            transaction.status !== 'APPROVED' &&
            transaction.status !== 'PENDING' && (
              <p>
                Puedes volver a tu carrito de compra haciendo{' '}
                <Link className="font-weight-bold" to="/cart">
                  Click Aquí
                </Link>
              </p>
            )}
        </div>
      </div>

      <Alert />
    </>
  )
}

export default connect(null, { update_alert, update_loading })(AfterPayment)
