import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  update_show_status_order_modal,
  update_loading,
  update_alert,
} from '../store/actions'

import { makeStyles } from '@material-ui/core/styles'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  Button,
} from '@material-ui/core'

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@material-ui/lab'

//services
import PublicService from '../services/public.service'
import UtilityService from '../services/utility.service'

//Helpers
import {
  getMessageError,
  compare,
  showStatusOrder,
  showStatusDescription,
  showStatusName,
} from '../helpers'

import moment from 'moment'

import QualifyOrderModal from './QualifyOrderModal'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}))

const OrderCompleted = props => {
  const classes = useStyles()

  //redux actions
  const { update_show_status_order_modal } = props

  //redux state
  const { showStatusOrderModal, user } = props

  //State
  const [order, setOrder] = useState(null)
  const [clientInfo, setClientInfo] = useState(null)
  const [lastStatusOrderId, setLastStatusOrderId] = useState(null)
  const [openQualifyOrderModal, setOpenQualifyOrderModal] = useState(false)

  //Methods
  //Close modal
  const handleClose = () => {
    update_show_status_order_modal({
      open: false,
      order_id: null,
      store_id: null,
    })
  }

  //Get order by id
  const getOrderById = async data => {
    try {
      update_loading({
        show: true,
        message: 'Consultando orden',
      })

      const { data: res } = await PublicService.getOrderById(data)
      console.log('HOLAAA', res)
      if (res.success) {
        setOrder(res.data)
        setClientInfo(JSON.parse(res.data.client_info))
        setLastStatusOrderId(
          res.data.statuses.length > 0
            ? res.data.statuses[res.data.statuses.length - 1].status_id
            : null
        )
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  const checkStatusesExists = statuses_to_check => {
    const exists = order.statuses.find(i =>
      statuses_to_check.includes(i.status_id)
    )
    return exists ? true : false
  }

  const handleCloseQualifyOrderModal = async reloadInfo => {
    setOpenQualifyOrderModal(false)
    if (reloadInfo) await getOrderById(showStatusOrderModal)
  }

  //Save new status in order
  const saveStatus = async status_id => {
    try {
      update_loading({
        show: true,
        message: 'Guardando nuevo estado en orden',
      })

      const { data: res } = await UtilityService.saveStatusOrder({
        store_id: order.store_id,
        order_id: order.id,
        status: status_id,
        type: 'distributor',
      })
      await getOrderById(showStatusOrderModal)

      if (res.success) {
        // order.statuses.push(res.data)

        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  //useEffect
  useEffect(() => {
    if (showStatusOrderModal.order_id) {
      getOrderById(showStatusOrderModal)
      window.Echo.private(`orders.${showStatusOrderModal.order_id}`).listen(
        '.OrderNewStatus',
        e => {
          console.log('hola')
          console.log(e)

          getOrderById(showStatusOrderModal)
        }
      )
    }
  }, [showStatusOrderModal])

  return (
    <>
      <Dialog
        style={{ zIndex: 2000 }}
        open={showStatusOrderModal.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <DialogTitle className="border-bottom position-relative">
          Detalle orden # {showStatusOrderModal.order_id}
          <i
            className="fa fa-times text-muted"
            style={{
              position: 'absolute',
              right: '16px',
              top: '16px',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          ></i>
        </DialogTitle>
        {order && (
          <DialogContent>
            <div>
              <h5 className="p-1">
                <i className="fa fa-store mr-2 text-muted"></i>
                {order.store.name}
              </h5>
              <p className="my-2">
                Fecha:{' '}
                {moment(order.created_at).format('DD-MM-yyyy hh:mm:ss A')}
              </p>
              <p className="my-2">Delivery: {order.cost_delivery}</p>
              <p className="my-2">Total: {order.total}</p>
            </div>
            <hr />
            <div className="mt-3">
              <h6>Estados orden</h6>
              <Timeline>
                {order &&
                  order.statuses.length > 0 &&
                  order.statuses
                    .sort(compare('status_id'))
                    .filter(i => showStatusOrder(user.rol, i.status.show_to))
                    .map(st => (
                      <TimelineItem key={st.id}>
                        <TimelineSeparator>
                          <TimelineDot
                            color="primary"
                            style={{
                              height: '40px',
                              width: '40px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <i
                              className={st.status.icon + ' ml-1'}
                              style={{ fontSize: '18px' }}
                            ></i>
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Paper elevation={2} className="p-2">
                            <Typography variant="h6" component="h1">
                              {showStatusName(user.rol, st.status)}
                            </Typography>
                            <Typography>
                              {showStatusDescription(user.rol, st.status)}
                            </Typography>
                            {st.status_id === 11 && (
                              <div className="text-right">
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  onClick={() => setOpenQualifyOrderModal(true)}
                                >
                                  Ver calificación
                                </Button>
                              </div>
                            )}
                          </Paper>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
              </Timeline>
              <div className="text-center">
                {user.rol === 'distributor' && (
                  <>
                    {(lastStatusOrderId === 4 || lastStatusOrderId === 5) &&
                      !checkStatusesExists([6]) && (
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          onClick={() => saveStatus(6)}
                        >
                          Marcar que llegue a la tienda
                        </Button>
                      )}

                    {checkStatusesExists([5, 7]) &&
                      checkStatusesExists([6]) &&
                      !checkStatusesExists([8]) && (
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          onClick={() => saveStatus(8)}
                        >
                          Marcar que recogi el pedido
                        </Button>
                      )}

                    {checkStatusesExists([8]) && !checkStatusesExists([9]) && (
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() => saveStatus(9)}
                      >
                        Marcar que llegue a la dirección del cliente
                      </Button>
                    )}

                    {checkStatusesExists([9]) && !checkStatusesExists([10]) && (
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() => saveStatus(10)}
                      >
                        Marcar que entregue la orden
                      </Button>
                    )}
                  </>
                )}
                {user.rol === 'user' ||
                  (user.rol === 'admin' && (
                    <>
                      {checkStatusesExists([10]) && !checkStatusesExists([11]) && (
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          onClick={() => setOpenQualifyOrderModal(true)}
                        >
                          Calificar orden
                        </Button>
                      )}
                    </>
                  ))}
              </div>
            </div>
            <hr />
            <div>
              <h6>Productos</h6>
              <List className={classes.root}>
                {order &&
                  order.order_details.length > 0 &&
                  order.order_details.map((p, index) => (
                    <>
                      <ListItem alignItems="flex-start" key={p.id}>
                        <ListItemAvatar>
                          <Avatar
                            alt={p.product.name}
                            src={p.product.first_image}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={p.product.name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              ></Typography>
                              {p.product.description}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      {index !== order.order_details.length - 1 && (
                        <Divider variant="inset" component="li" />
                      )}
                    </>
                  ))}
              </List>
            </div>
          </DialogContent>
        )}
      </Dialog>

      <QualifyOrderModal
        open={openQualifyOrderModal}
        handleClose={handleCloseQualifyOrderModal}
        order={order}
      />
    </>
  )
}

const mapStateToProps = state => ({
  showStatusOrderModal: state.showStatusOrderModal,
  user: state.user,
})

export default connect(mapStateToProps, { update_show_status_order_modal })(
  OrderCompleted
)
