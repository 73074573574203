import React from 'react'
import { Button, Badge } from '@material-ui/core'
import { connect } from 'react-redux'

import { update_show_complete_order_modal } from '../store/actions'

import { calcPrice } from '../helpers'

const CartBottomBar = props => {
  //Redux
  const {
    cart,
    update_show_complete_order_modal,
    currentCurrencyClient,
  } = props

  return (
    <>
      {cart && cart.items.length > 0 && (
        <div
          style={{
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            textAlign: 'center',
            zIndex: 5,
          }}
          onClick={() => update_show_complete_order_modal(true)}
        >
          <Button
            size="large"
            color="primary"
            variant="contained"
            className="w-100"
            style={{ borderRadius: 0 }}
          >
            <Badge badgeContent={cart.items.length} color="secondary">
              <i className="fa fa-shopping-cart pr-1"></i>
            </Badge>

            <span className="ml-3">
              {calcPrice(
                cart.total,
                currentCurrencyClient,
                cart.store_currency,
                cart.store_exchange_rate
              )}{' '}
              <span className="ml-2">Ver mi pedido</span>{' '}
            </span>
          </Button>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  cart: state.cart,
  showCompleteOrderModal: state.showCompleteOrderModal,
  currentCurrencyClient: state.currentCurrencyClient,
})

export default connect(mapStateToProps, { update_show_complete_order_modal })(
  CartBottomBar
)
