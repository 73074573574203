import http from "../http";

class AuthService {
  static authenticate(user) {
    return http({
      method: "post",
      url: "auth/login",
      data: user,
    });
  }

  static whoami() {
    return http({
      method: "post",
      url: "auth/me",
    });
  }

  static emailVerify(id, code) {
    return http({
      method: "post",
      url: `email/verify/${id}/${code}`,
    });
  }

  static resendEmailVerify() {
    return http({
      method: "post",
      url: `email/resend`,
    });
  }

  static resetPasswordSendEmail(data) {
    return http({
      method: "post",
      url: `password/email`,
      data: data,
    });
  }

  static resetPasswordReset(data) {
    return http({
      method: "post",
      url: `password/reset`,
      data: data,
    });
  }
}

export default AuthService;
