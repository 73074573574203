import http from '../http'

class CartService {
  static save(data) {
    return http({
      method: 'post',
      url: 'public/add-cart',
      // headers: {
      //   'Content-Type': 'multipart/form-data',
      // },
      data: data,
    })
  }

  static get() {
    return http({
      method: 'get',
      url: 'public/cart',
    })
  }

  static updateQuantity(data) {
    return http({
      method: 'post',
      url: 'public/cart-update-quantity',
      data: data,
    })
  }

  static removeCartProduct(data) {
    return http({
      method: 'delete',
      url: 'public/remove-cart',
      data: data,
    })
  }

  //Get total cart
  static getTotal() {
    return http({
      method: 'post',
      url: 'public/cart-total',
    })
  }

  //Verify transaction if success
  static verifyTransaction(data) {
    return http({
      method: 'post',
      url: 'verify-transaction',
      data: data,
    })
  }

  //Change shipping type
  static changeShippingType(data) {
    return http({
      method: 'post',
      url: 'public/change-shipping-type',
      data: data,
    })
  }
}

export default CartService
