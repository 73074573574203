import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { connect } from 'react-redux'
import { update_user, update_alert } from '../store/actions'
import { useHistory } from 'react-router-dom'

//Components
import PublicUserOrdersModal from './PublicUserOrdersModal'

//Services
import registerService from '../services/register.service'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}))

const DropdownMenuClient = props => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const history = useHistory()

  //Redux actions
  const { update_alert, update_user } = props

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  //State
  const [openUserOrdersModal, setOpenUserOrdersModal] = useState(false)

  const handleClose = () => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return
    // }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  //Methods
  const logout = async () => {
    const { data: res } = await registerService.logout()

    handleClose()
    localStorage.clear()
    update_user(false)
    update_alert({
      open: true,
      severity: 'success',
      message: 'Sesión cerrada correctamente',
    })
    history.push('/')
  }

  //Close user orders modal
  const closeUserOrdersModal = () => {
    setOpenUserOrdersModal(false)
  }

  //Open user current orders
  const openUserCurrentOrders = () => {
    handleClose()
    setOpenUserOrdersModal(true)
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      <>
        <IconButton
          className="p-0 px-3 text-white"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <AccountCircleIcon />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={() => openUserCurrentOrders()}>
                      Ordenes en curso
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      Historial de ordenes
                    </MenuItem>
                    <MenuItem onClick={handleClose}>Mi Perfil</MenuItem>
                    <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>

      <PublicUserOrdersModal
        open={openUserOrdersModal}
        closeUserOrdersModal={closeUserOrdersModal}
      />
    </>
  )
}

export default connect(null, { update_alert, update_user })(DropdownMenuClient)
