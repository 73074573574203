import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import PublicService from '../../../services/public.service'
import {
  getMessageError,
  compare,
  validateStoreIsOpen,
  calcPrice,
} from '../../../helpers'
import {
  update_alert,
  update_loading,
  update_cart,
  update_current_currency_client,
} from '../../../store/actions'
import { connect } from 'react-redux'
import './PublicStore.scss'
import moment from 'moment'
import { truncateString, AddOrUpdateProductInCart } from '../../../helpers'
import { Link as LinkScroll } from 'react-scroll'

import AddProductToCartButton from '../../../components/AddProductToCartButton'
import PublicProductDetail from '../../../components/PublicProductDetail'
import CartBottomBar from '../../../components/CartBottomBar'
import Loading from '../../../components/Loading'
import Alert from '../../../components/Alert'
import queryString from 'query-string'

import PublicCompleteOrder from '../../../components/PublicCompleteOrder'
import PublicStatusOrderModal from '../../../components/PublicStatusOrderModal'

import flatVe from '../images/flats/ve.svg'
import flatUs from '../images/flats/us.svg'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Select,
  MenuItem,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import useDynamicRefs from 'use-dynamic-refs'
import { useTheme } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import 'moment/locale/es'
import { Link } from 'react-router-dom'

moment.locale('es')

const today = moment().format('dddd')

const PublicStore = props => {
  //Params url
  const { id } = useParams()

  const [getRef, setRef] = useDynamicRefs()
  const inputSearch = useRef(null)

  //Query params url
  const { search } = useLocation()

  //Media queries
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  //Redux
  const {
    update_loading,
    update_alert,
    update_cart,
    update_current_currency_client,
  } = props

  //Redux state
  const { currentCurrencyClient, cart } = props

  //State
  const [store, setStore] = useState({
    store_sub_categories: [],
    menu_sections: [],
    product_categories: [],
    store_workdays: [],
  })
  const [product_categories_display, setProductCategoriesDisplay] = useState([])
  const [
    filter_product_categories_display,
    setFilterProductCategoriesDisplay,
  ] = useState([])
  const [active_product_category, setActiveProductCategory] = useState(
    'section1'
  )

  const [openModalDetail, setOpenModalDetail] = useState(false)
  const [selectProduct, setSelectProduct] = useState({})
  const [openWorkingHoursModal, setOpenWorkingHoursModal] = useState(false)
  const dateToday = new Date()
  const [currentDateTime, setCurrentDateTime] = useState({
    day: dateToday.getDay(),
    month: dateToday.getMonth(),
    date: dateToday.getDate(),
    year: dateToday.getFullYear(),
    time: moment(`${dateToday.getHours()}:${dateToday.getMinutes()}`, 'HH:mm'),
  })

  const [currentCurrency, setCurrentCurrency] = useState(1)

  //Store is open
  const [storeIsOpen, setStoreIsOpen] = useState(true)
  const [searchStoreVisible, setSearchStoreVisible] = useState(false)

  //search
  const loaded = useRef(false)
  const [searchQuery, setSearchQuery] = useState('')

  const handleSearchInputChange = ev => {
    const value = ev.currentTarget.value
    setSearchQuery(value)
  }

  const showSearchInput = () => {
    console.log(inputSearch)

    setSearchStoreVisible(() => !searchStoreVisible)
    inputSearch.current.focus()
  }

  //Store is working today
  const isWorkingToday = () => {}

  const executeSearch = value => {
    value = value.toLowerCase().trim()

    if (value) {
      console.log(value)

      console.log(product_categories_display)
      const new_product_categories_display = []
      product_categories_display.forEach(pcd => {
        const pds = pcd.products.filter(
          i =>
            i.name.toLowerCase().indexOf(value) > -1 ||
            (i.description && i.description.toLowerCase().indexOf(value) > -1)
        )
        if (pds.length > 0) {
          new_product_categories_display.push({
            ...pcd,
            products: pds,
          })
        }
      })
      console.log(new_product_categories_display)
      setFilterProductCategoriesDisplay(new_product_categories_display)
    } else {
      setFilterProductCategoriesDisplay(product_categories_display)
    }
  }

  //Use Effect
  useEffect(() => {
    if (loaded.current) {
      const timeOutId = setTimeout(() => executeSearch(searchQuery), 400)
      return () => clearTimeout(timeOutId)
    } else {
      loaded.current = true
    }
  }, [searchQuery]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchStoreVisible) {
      inputSearch.current.focus()
    }
  }, [searchStoreVisible])

  //Methods
  const handleCurrentCurrencyChange = ev => {
    update_current_currency_client(ev.target.value)
  }

  const getStore = async () => {
    try {
      update_loading({
        show: true,
        message: 'Cargando',
      })

      const { data: res } = await PublicService.getStore(id)
      setStore(res.data)
      setStoreIsOpen(validateStoreIsOpen(res.data, currentDateTime, today))
      //Verificar si hay secciones
      //Para mostrar las categorias por secciones
      const menu_sections = res.data.menu_sections
      if (menu_sections.length > 0) {
        setProductCategoriesDisplay(menu_sections[0].product_categories)
      } else {
        setProductCategoriesDisplay(res.data.product_categories)
        setFilterProductCategoriesDisplay(res.data.product_categories)
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  const countingSecond = () => {
    const d = new Date()
    setCurrentDateTime({
      day: d.getDay(),
      month: d.getMonth(),
      date: d.getDate(),
      year: d.getFullYear(),
      time: moment(`${d.getHours()}:${d.getMinutes()}`, 'HH:mm'),
    })
  }

  const changeActiveProductCategory = section => {
    setActiveProductCategory(section)
    // Somewhere else, even another file
    const sec = getRef(section)
    console.log(section)
    if (sec.current) sec.current.scrollIntoView({ inline: 'center' })
  }

  const changeMenuSection = ms => {
    //If menu section have product categories
    if (ms.product_categories.length > 0) {
      setProductCategoriesDisplay(ms.product_categories)
      changeActiveProductCategory(`section${ms.product_categories[0].id}`)
    }
  }

  //Open working hours modal
  const openModalWorkingHours = () => {
    setOpenWorkingHoursModal(true)
  }

  //Close working hours modal
  const closeModalWorkingHours = () => {
    setOpenWorkingHoursModal(false)
  }

  //Open product detail modal
  const openProductDetail = (product, decrement) => {
    setSelectProduct(product)
    setOpenModalDetail(true)
  }

  //Close product detail modal
  const closeProductDetail = () => {
    setOpenModalDetail(false)
  }

  //Add product cart
  const addAndUpdateProductCart = async ({ quantity, product }) => {
    const newCart = await AddOrUpdateProductInCart(
      cart,
      product,
      quantity,
      store
    )
    update_cart(newCart)
  }

  //useEffects
  useEffect(() => {
    getStore()
    const currentDateTimeInterval = setInterval(countingSecond, 5000)
    return () => {
      clearInterval(currentDateTimeInterval)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const store_is_open = validateStoreIsOpen(store, currentDateTime, today)
    setStoreIsOpen(store_is_open)
  }, [currentDateTime])

  //useEffects
  useEffect(() => {
    if (filter_product_categories_display.length > 0) {
      const queryParams = queryString.parse(props.location.search)
      let array = []
      filter_product_categories_display.forEach(i => {
        array = [...array, ...i.products]
      })
      const product = array.find(i => i.id === parseInt(queryParams.producto))
      if (product) {
        setOpenModalDetail(true)
        setSelectProduct(product)
      }
    }
  }, [filter_product_categories_display]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* Header */}
      <div className="position-relative">
        <Link
          style={{
            position: 'absolute',
            background: 'white',
            zIndex: 2,
            top: '1rem',
            padding: '.3rem',
            fontWeight: 'bold',
          }}
          to="/"
        >
          <i className="fa fa-arrow-left mr-2"></i>
          Ir al markeplace
        </Link>

        <section
          className="public-store-header"
          style={{
            backgroundImage: `url(${store.url_banner})`,
          }}
        >
          {/* Select currency */}
          <div
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              padding: '0 5px',
              display: 'flex',
            }}
          >
            {(!store.catalog_shop || store.show_pricing) && (
              <Select
                disableUnderline
                name="current_currency"
                value={currentCurrencyClient}
                onChange={handleCurrentCurrencyChange}
                size="small"
                style={{
                  background: 'white',
                  borderRadius: '5px',
                  padding: '0 5px',
                  border: 0,
                  background: 'white',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <MenuItem value={2}>
                  <img
                    style={{ width: '20px', height: '20px' }}
                    src={flatVe}
                    alt="ve"
                    className="mr-2"
                  />
                  <span style={{ fontSize: '12px' }}>VEF</span>
                </MenuItem>
                <MenuItem value={1}>
                  <img
                    style={{ width: '20px', height: '20px' }}
                    src={flatUs}
                    alt="usa"
                    className="mr-2"
                  />
                  <span style={{ fontSize: '12px' }}>USD</span>
                </MenuItem>
              </Select>
            )}

            <Select
              disableUnderline
              name="current_currency"
              value={currentCurrencyClient}
              onChange={handleCurrentCurrencyChange}
              size="small"
              style={{
                background: 'white',
                borderRadius: '5px',
                padding: '0 5px',
                border: 0,
                background: 'white',
                display: 'flex',
                alignItems: 'center',
                marginLeft: '1rem',
              }}
            >
              <MenuItem value={2}>
                <img
                  style={{ width: '20px', height: '20px' }}
                  src={flatVe}
                  alt="ve"
                  className="mr-2"
                />
                <span style={{ fontSize: '12px' }}>VEF</span>
              </MenuItem>
              <MenuItem value={1}>
                <img
                  style={{ width: '20px', height: '20px' }}
                  src={flatUs}
                  alt="usa"
                  className="mr-2"
                />
                <span style={{ fontSize: '12px' }}>USD</span>
              </MenuItem>
            </Select>
          </div>

          <div className="store-info__social-networks">
            {store.url_instagram && (
              <a target="_blank" href={store.url_instagram}>
                <i className="fab fa-instagram mr-4 cursor-pointer hover-instagram"></i>
              </a>
            )}
            {store.url_facebook && (
              <a target="_blank" href={store.url_facebook}>
                <i className="fab fa-facebook mr-4 cursor-pointer hover-facebook"></i>
              </a>
            )}
            {store.url_twitter && (
              <a target="_blank" href={store.url_twitter}>
                <i className="fab fa-twitter mr-4 cursor-pointer hover-twitter"></i>
              </a>
            )}
            {store.url_linkedin && (
              <a target="_blank" href={store.url_linkedin}>
                <i className="fab fa-linkedin mr-4 cursor-pointer hover-linkedin"></i>
              </a>
            )}
            {store.url_youtube && (
              <a target="_blank" href={store.url_youtube}>
                <i className="fab fa-youtube cursor-pointer hover-youtube"></i>
              </a>
            )}
          </div>
        </section>
        {/* Store info */}
        <div className="store-info">
          <div className="d-flex">
            <div className="col-public-store-logo">
              <div
                className="public-store-item-logo"
                style={{
                  backgroundImage: `url(${store.url_logo})`,
                }}
              ></div>
              {/* Estado cerrado abierto */}
              <span
                className={
                  'store-info__status text-white rounded ' +
                  (storeIsOpen ? 'bg-success' : 'bg-danger')
                }
              >
                {storeIsOpen ? 'abierto' : 'cerrado'}
              </span>
            </div>

            <div>
              <h1>
                <span className="store-info-name">{store.name}</span>
              </h1>
              {/* Store categories */}
              <div className="d-flex">
                {store.store_sub_categories.map((sc, index) => (
                  <span key={sc.id} className="d-flex align-items-center">
                    {sc.name}
                    {index !== store.store_sub_categories.length - 1 && (
                      <i
                        className="fa fa-circle m-2 text-muted"
                        style={{ fontSize: '8px' }}
                      ></i>
                    )}
                  </span>
                ))}
              </div>
              {/* Address */}
              {/* <p className="m-0">
                <i className="fa fa-map-marker-alt text-muted"></i>
                {store.address}
              </p> */}

              {/* {(store.phone_one || store.phone_two) && (
                <p className="m-0">
                  <i className="fa fa-phone-alt text-muted"></i>{' '}
                  {store.phone_one && <span>{store.phone_one}</span>}{' '}
                  {store.phone_two && <span> - {store.phone_two}</span>}
                </p>
              )} */}

              <p className="m-0" onClick={openModalWorkingHours}>
                <i className="fa fa-clock text-muted"></i> ver horario
              </p>
            </div>
          </div>
        </div>
        {/* End store info */}
      </div>
      {/* End Header */}

      <section className="sections-categories">
        {store.menu_sections.length > 0 && (
          <div className="public-store-sections">
            <ul>
              {store.menu_sections.map(ms => (
                <li
                  onClick={() => changeMenuSection(ms)}
                  className={!ms.on_service ? 'text-muted' : ''}
                  key={ms.id}
                >
                  <p className="p-0 m-0 h5">{ms.name}</p>
                  <small>
                    {moment(ms.start_hour, 'hh:mm:ss').format('h:mm a')} -{' '}
                    {moment(ms.end_hour, 'hh:mm:ss').format('h:mm a')}
                  </small>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="public-store-categories">
          <div>
            <IconButton
              className="product-categories-search-icon"
              size="medium"
              style={{ borderRadius: 0 }}
              onClick={showSearchInput}
            >
              <SearchIcon fontSize="large" color="primary" />
            </IconButton>
          </div>
          <div
            className={
              'product-categories-search-container ' +
              (searchStoreVisible
                ? 'search-store-visible'
                : 'search-store-invisible')
            }
          >
            <input
              ref={inputSearch}
              onChange={handleSearchInputChange}
              value={searchQuery}
              type="text"
              placeholder="Buscar"
              className="search-store"
            />
          </div>

          <ul
            className="scroll-horizontal bg-white"
            id="product-categories-container"
          >
            {filter_product_categories_display.map(pc => (
              <li
                key={pc.id}
                ref={setRef(`section${pc.id}`)}
                className={
                  'd-flex ' +
                  (`section${pc.id}` === active_product_category
                    ? 'product_category_active '
                    : '') +
                  (!pc.on_service || !storeIsOpen ? 'text-muted' : '')
                }
              >
                <LinkScroll
                  activeClass="text-primary"
                  to={`section${pc.id}`}
                  spy={true}
                  smooth={true}
                  offset={-120}
                  duration={500}
                  onSetActive={changeActiveProductCategory}
                >
                  <p className="p-0 m-0 h5 text-primary">{pc.name}</p>
                </LinkScroll>
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* Categorias de productos */}
      <section className="public-store-list-items">
        {filter_product_categories_display.length > 0 && (
          <>
            {filter_product_categories_display.map(pc => (
              <div name={`section${pc.id}`} key={pc.id}>
                <h5 className="public-store-list-items__category-name">
                  {pc.name}
                </h5>
                <div>
                  {pc.products.length > 0 && (
                    <div className="row">
                      {pc.products.map(p => (
                        <div
                          className={`public-product-container ${
                            !pc.on_service || !p.active | !storeIsOpen
                              ? ' opacity-5'
                              : ''
                          }`}
                          key={p.id}
                        >
                          <img
                            onClick={
                              !pc.on_service || !p.active || !storeIsOpen
                                ? null
                                : () => openProductDetail(p)
                            }
                            src={p.first_image}
                            className="border-right public-product-image"
                            alt="imagen producto"
                          />

                          <div className="public-product-content">
                            <h6
                              className="card-title m-0 font-weight-bold"
                              onClick={
                                !pc.on_service || !p.active || !storeIsOpen
                                  ? null
                                  : () => openProductDetail(p)
                              }
                            >
                              {truncateString(p.name, 100)}
                            </h6>
                            {p.name && p.name.length < 45 && p.description && (
                              <div className="public-product-description">
                                <p
                                  className="m-0"
                                  onClick={
                                    !pc.on_service || !p.active || !storeIsOpen
                                      ? null
                                      : () => openProductDetail(p)
                                  }
                                >
                                  {truncateString(p.description, 50)}
                                </p>
                              </div>
                            )}
                            <div className="public-product-footer d-flex align-items-center p-1">
                              <span className="public-store-product-price">
                                {(store.show_pricing ||
                                  !store.catalog_shop) && (
                                  <>
                                    {calcPrice(
                                      p.price,
                                      currentCurrencyClient,
                                      store.currency,
                                      store.exchange_rate
                                    )}
                                  </>
                                )}
                              </span>

                              {!pc.on_service || !p.active || !storeIsOpen ? (
                                <span className="p-2">No disponible</span>
                              ) : p.product_attributes.length === 0 ? (
                                <AddProductToCartButton
                                  min={0}
                                  callback={
                                    store.catalog_shop
                                      ? () => openProductDetail(p)
                                      : addAndUpdateProductCart
                                  }
                                  product={p}
                                  store={store}
                                />
                              ) : (
                                <AddProductToCartButton
                                  min={0}
                                  callback={() => openProductDetail(p)}
                                  callback2={addAndUpdateProductCart}
                                  product={p}
                                  haveAttributes={true}
                                  store={store}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </section>

      <PublicProductDetail
        open={openModalDetail}
        closeModal={closeProductDetail}
        product={selectProduct}
        store={store}
      />

      {/* Working hours */}
      <Dialog
        fullWidth={true}
        open={openWorkingHoursModal}
        onClose={closeModalWorkingHours}
        maxWidth="sm"
        fullScreen={fullScreen}
      >
        <DialogTitle className="d-flex align-items-center justify-content-between p-2 w-100 position-relative">
          <i className="fa fa-clock mr-3 text-muted"></i>
          Horario de atención
          <i
            onClick={closeModalWorkingHours}
            className="fa fa-times text-muted"
            style={{
              position: 'absolute',
              right: '20px',
              top: '15px',
              cursor: 'pointer',
            }}
          ></i>
        </DialogTitle>
        <DialogContent dividers className="p-0">
          <ul className="p-0 m-0">
            {store &&
              store.store_workdays.length > 0 &&
              store.store_workdays.sort(compare('workday_id')).map(sw => {
                return (
                  <span key={sw.id}>
                    {' '}
                    {sw.store_workday_working_hours.length > 0 && (
                      <li
                        key={sw.id}
                        className={
                          'p-3 border-bottom ' +
                          (today === sw.workday.name.toLowerCase()
                            ? 'bg-primary text-white'
                            : '')
                        }
                      >
                        <span className="h6 mr-3">{sw.workday.name}</span>
                        {sw.store_workday_working_hours.length > 0 &&
                          sw.store_workday_working_hours
                            .sort(compare('start_hour'))
                            .map(wh => {
                              return (
                                <span
                                  className="border m-2 p-1 rounded"
                                  key={wh.id}
                                >
                                  {moment(wh.start_hour, 'HH:mm:ss').format(
                                    'h:mm a'
                                  )}{' '}
                                  -{' '}
                                  {moment(wh.end_hour, 'HH:mm:ss').format(
                                    'h:mm a'
                                  )}
                                </span>
                              )
                            })}
                      </li>
                    )}
                  </span>
                )
              })}
          </ul>
        </DialogContent>
      </Dialog>

      {store && !store.catalog_shop && (
        <>
          <CartBottomBar />
          <PublicCompleteOrder />
        </>
      )}

      <PublicStatusOrderModal />
      <Loading />
      <Alert />
    </>
  )
}

const mapStateToProps = state => ({
  cart: state.cart,
  currentCurrencyClient: state.currentCurrencyClient,
})

export default connect(mapStateToProps, {
  update_alert,
  update_loading,
  update_cart,
  update_current_currency_client,
})(PublicStore)
