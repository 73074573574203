import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './routes/App'
//import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'
import {
  setupRequestInterceptors,
  setupResponseInterceptors,
  newEcho,
} from './helpers'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { esES } from '@material-ui/core/locale'

import AOS from 'aos'
import 'aos/dist/aos.css'

setupRequestInterceptors()
setupResponseInterceptors()

//AOS animations
AOS.init({
  offset: 20, // offset (in px) from the original trigger point
  once: true, //la animacion se lanzara una sola vez
})

//Add echo laravel to window
window.Echo = newEcho()

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#348c84' },
      success: { main: '#28a745' },
      secondary: { main: '#DE2700' },
    },
  },
  esES
)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
)

//serviceWorker.unregister()
