import http from "../http";
import { defaultRowsPerPage } from "../helpers";

class ProductService {
  //Save new product
  static save(data) {
    return http({
      method: "post",
      url: "products",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
  }

  //update product
  static update(data) {
    return http({
      method: "post",
      url: "products",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
  }

  //get products
  static get(page = 1, perPage = defaultRowsPerPage, search = "") {
    return http({
      method: "get",
      url: `products?page=${page}&per_page=${perPage}&search=${search}`,
    });
  }

  //delete product
  static delete(data) {
    return http({
      method: "delete",
      data,
      url: "products",
    });
  }

  //save product category
  static saveCategory(data) {
    return http({
      method: "post",
      url: "product-categories",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
  }

  //update product category
  static updateCategory(data) {
    return http({
      method: "post",
      url: "product-categories",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
  }

  //get product categories
  static getCategories(page = 1, perPage = defaultRowsPerPage, search = "") {
    return http({
      method: "get",
      url: `product-categories?page=${page}&per_page=${perPage}&search=${search}`,
    });
  }

  //delete product category
  static deleteCategory(data) {
    return http({
      method: "delete",
      data,
      url: "product-categories",
    });
  }
}

export default ProductService;
