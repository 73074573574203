import { createStore, compose } from 'redux'
import reducer from './reducers'
import { initStateCart } from '../helpers'

const storage_cart = localStorage.getItem('cart')

const initialState = {
  currentCurrencyClient: 1, //1: Dollars
  user: false,
  storeInfo: JSON.parse(localStorage.getItem('storeInfo')),
  posts: [],
  alert: {
    open: false,
    severity: 'error',
    message: 'Algo va mal',
  },
  loading: {
    show: false,
    message: 'Cargando...',
  },
  cart: storage_cart ? JSON.parse(storage_cart) : initStateCart(),
  showCompleteOrderModal: false, // show modal for complete order
  showStatusOrderModal: {
    open: false,
    order_id: null,
    store_id: null,
  }, //Show modal status order
}

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const store = createStore(reducer, initialState, composeEnhancers())

export default store
