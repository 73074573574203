import axios from 'axios'
// Set config defaults when creating the instance
const http = axios.create({
  withCredentials: true,
  //baseURL: 'http://localhost/personal/womve/backend8/public/api/',
  baseURL: 'https://womve.joseojedaf.com/api/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export default http
