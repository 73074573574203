import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { update_cart, update_alert } from '../store/actions'

const AddProductToCartButton = props => {
  //Props
  const {
    min,
    product,
    callback,
    callback2,
    haveAttributes,
    store,
    showAddButton,
  } = props
  const [quantity, setQuantity] = useState(min ? 1 : 0)

  //Redux
  const { cart, update_cart, update_alert } = props

  const increment = () => {
    const newQuantity = quantity + 1

    //If not store_id
    if (!cart.store_id) {
      //cart.store_id = product.store_id
      //update_cart(cart)
    } else {
      //Validate that product to add is same store
      if (product.store_id !== cart.store_id) {
        update_alert({
          open: true,
          severity: 'warning',
          message:
            'Ya tienes productos de otra tienda en tu carrito para agregar este producto vacia tu carrito',
        })
        return
      }
    }

    if (haveAttributes) {
      if (quantity === 0) {
        callback()
      } else {
        callback2({
          product: JSON.parse(JSON.stringify(product)),
          quantity: newQuantity,
        })
      }
      return
    }
    setQuantity(newQuantity)
    callback({
      product: JSON.parse(JSON.stringify(product)),
      quantity: newQuantity,
    })
  }

  const decrement = () => {
    if (quantity === 1 && min) return
    if (haveAttributes) {
      const newQuantity = quantity - 1
      setQuantity(newQuantity)
      callback2({
        product: JSON.parse(JSON.stringify(product)),
        quantity: newQuantity,
      })
      return
    }

    const newQuantity = quantity - 1
    setQuantity(newQuantity)
    callback({
      product: JSON.parse(JSON.stringify(product)),
      quantity: newQuantity,
    })
  }

  //UseEffect
  useEffect(() => {
    if (product.id) {
      console.log(cart)
      const productExists = cart.items.find(
        i => i.id === product.id && cart.store_id === product.store_id
      )
      if (productExists) {
        setQuantity(productExists.quantity)
      } else {
        setQuantity(min)
      }
    }
  }, [product, cart])

  useEffect(() => {
    if (cart.items.length === 0 && cart.store_id) {
      const emptyCart = {
        items: [],
        total: 0,
        total_options: 0,
        store_id: null,
      }
      update_cart(emptyCart)
      localStorage.removeItem('cart')
    }
  }, [cart])

  return (
    <>
      {((store && !store.catalog_shop) || showAddButton) && (
        <Button
          className={
            'p-1 public-store-btn-add d-flex align-items-center text-success ' +
            (quantity > 0 ? 'border rounded-pill px-2 m-0' : '')
          }
          style={{ flexWrap: 'nowrap' }}
        >
          {quantity === 0 && (
            <span
              onClick={() => increment()}
              className="d-flex align-items-center"
            >
              <i className="fa fa-shopping-cart mr-2"></i> AGREGAR
            </span>
          )}

          {quantity > 0 && (
            <span
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}
            >
              <i
                className={
                  'text-danger fa ' +
                  (quantity > 1 ? 'fa-minus' : min ? 'fa-minus' : 'fa-trash')
                }
                onClick={() => decrement()}
              ></i>

              <span className="px-4" style={{ color: 'black' }}>
                {quantity}
              </span>
              <i className="fa fa-plus" onClick={() => increment()}></i>
            </span>
          )}
        </Button>
      )}

      {store && store.catalog_shop && (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className="mb-1"
          onClick={callback}
        >
          Más info
        </Button>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  cart: state.cart,
})

export default connect(mapStateToProps, { update_cart, update_alert })(
  AddProductToCartButton
)
