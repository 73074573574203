import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useForm } from 'react-hook-form'
import AuthService from '../services/auth.service'
import RegisterService from '../services/register.service'
import { connect } from 'react-redux'
import {
  update_user,
  update_alert,
  update_loading,
  update_store_info,
} from '../store/actions'
import { Link } from 'react-router-dom'
import Alert from './Alert'
import './Login.scss'
import { getMessageError } from '../helpers'
import { useHistory } from 'react-router-dom'

const Login = props => {
  //History
  const history = useHistory()

  //Redux actions
  const { update_user, update_alert, update_loading, update_store_info } = props

  //Redux state
  const { cart } = props

  //State
  const [user, setUser] = useState({
    email: '',
    password: '',
  })

  //Validation hook
  const { register, handleSubmit, errors } = useForm()

  const handleInputChange = e =>
    setUser({
      ...user,
      [e.currentTarget.name]: e.currentTarget.value,
    })

  const singIn = async () => {
    try {
      update_loading({
        show: true,
        message: 'Iniciando sesión',
      })

      //If cart have items consolidate in backend
      if (cart.items && cart.items.length > 0) {
        const items = cart.items.map(i => ({
          id: i.id,
          quantity: i.quantity,
          additional_options: i.additional_options,
        }))

        user.cart = JSON.stringify({
          items: items,
          store_id: cart.store_id,
        })
      }

      const { data: res } = await AuthService.authenticate(user)
      if (res.user) {
        update_user(res.user)
        update_store_info(res.store_info)

        localStorage.setItem('token', res.access_token)
        localStorage.setItem('womvesi', res.womvesi)
        localStorage.setItem('womvesii', res.womvesii)
        localStorage.setItem('storeInfo', JSON.stringify(res.store_info))

        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })
      } else {
        update_alert({
          open: true,
          severity: 'error',
          message: 'Algo va mal',
        })
      }

      if (props.callback) {
        props.callback()
      } else {
        history.push('/home')
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  //Sing in facebook clicked
  const signInFacebookClicked = () => {
    console.log('login iniciado facebook')
  }

  //Response sign in facebook
  const responseFacebook = async response => {
    try {
      update_loading({
        show: true,
        message: 'Registrandote',
      })

      const data = {
        type: 'social_facebook',
        social_id: response.userID,
        email: response.email,
        name: response.name,
      }

      const { data: res } = await RegisterService.register(data)

      if (res.data.user) {
        update_user(res.data.user)
        localStorage.setItem('token', res.data.access_token)
        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })
      } else {
        update_alert({
          open: true,
          severity: 'error',
          message: 'Algo va mal',
        })
      }

      if (props.callback) {
        props.callback()
      } else {
        history.push('/home')
      }
    } catch (error) {
      const errorMessage = getMessageError(error)

      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
    update_loading({
      show: false,
    })
  }

  return (
    <>
      <div className="card">
        <Link to="/" className="text-center">
          <i className="fa fa-arrow-left mr-2"></i>
          Volver a la principal
        </Link>

        {/* Login with facebook */}
        {/* <div className="row">
          <FacebookLogin
            cssClass="fab fa-facebook btn btn-lg btn-primary p-3"
            textButton="Entrar con facebook"
            appId="958481567944105"
            autoLoad={false}
            fields="name,email,picture"
            onClick={signInFacebookClicked}
            callback={responseFacebook}
            onFailure={() => alert('fallo')}
          />
        </div> */}

        <form onSubmit={handleSubmit(singIn)}>
          {/* <div className="text-center">
            <Typography variant="h6">ó</Typography>
          </div> */}

          <div className="email-field">
            <TextField
              autoComplete="off"
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              fullWidth
              variant="outlined"
              label="Email"
              type="email"
              name="email"
              value={user.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={
                errors.email &&
                'El campo es requerido y debe ser un email valido'
              }
            />
          </div>
          <div>
            <TextField
              inputRef={register({ required: true })}
              fullWidth
              variant="outlined"
              label="Clave"
              name="password"
              type="password"
              value={user.password}
              onChange={handleInputChange}
              error={!!errors.password}
              helperText={errors.password && 'La campo clave es requerido'}
            />
          </div>

          <div className="text-center">
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              <i className="fa fa-sign-in-alt"></i>
              Entrar
            </Button>
          </div>

          <div className="mt-1 text-center">
            <Link to="/register">Crear una cuenta</Link>
          </div>

          <div className="text-center">
            <Link to="/password/email">¿ Olvide mi contraseña ?</Link>
          </div>
        </form>
      </div>

      <Alert />
    </>
  )
}

const mapStateToProps = state => ({
  cart: state.cart,
})

export default connect(mapStateToProps, {
  update_user,
  update_alert,
  update_loading,
  update_store_info,
})(Login)
