import React, { useState, useEffect } from 'react'

import { Button } from '@material-ui/core'

import AddStoreWorkdayModal from './AddStoreWorkdayModal'
import AddAndUpdateWorkdayHours from './AddAndUpdateWorkdayHours'

import { getMessageError, compare, confirmAlert } from '../helpers'

//Redux
import { connect } from 'react-redux'
import { update_alert } from '../store/actions'

//Services
import StoreService from '../services/store.service'
import PublicService from '../services/public.service'

import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const AddAndUpdateWorkdaysStore = props => {
  //Props
  const { store_id } = props
  //redux
  const { update_alert } = props
  //State
  const [openStoreWorkdayModal, setOpenStoreWorkdayModal] = useState(false)
  const [openStoreWorkdayHoursModal, setOpenStoreWorkdayHoursModal] = useState(
    false
  )

  const [workdays, setWorkdays] = useState([])
  const [storeWorkdays, setStoreWorkdays] = useState([])
  const [storeWorkdayActive, setStoreWorkdayActive] = useState(null)
  const [
    storeWorkdayWorkingHourEdit,
    setStoreWorkdayWorkingHourEdit,
  ] = useState(null)

  //Methods
  const newStoreWorkdayHours = store_workday => {
    setStoreWorkdayActive(store_workday)
    setOpenStoreWorkdayHoursModal(true)
  }

  const closeStoreWorkdayHoursModal = (
    new_workday_hour = false,
    edit = false
  ) => {
    if (new_workday_hour) {
      const store_workday_index = storeWorkdays.findIndex(
        i => i.id === new_workday_hour.store_workday_id
      )

      if (!edit) {
        storeWorkdays[store_workday_index].store_workday_working_hours.push(
          new_workday_hour
        )
      } else {
        const wh_index = storeWorkdays[
          store_workday_index
        ].store_workday_working_hours.findIndex(
          i => i.id === new_workday_hour.id
        )
        storeWorkdays[store_workday_index].store_workday_working_hours[
          wh_index
        ] = new_workday_hour
        setStoreWorkdays(JSON.parse(JSON.stringify(storeWorkdays)))
        setStoreWorkdayWorkingHourEdit(null)
      }
    }
    setStoreWorkdayActive(null)
    setOpenStoreWorkdayHoursModal(false)
  }

  const editStoreWorkdayWorkingHour = (sw, wh) => {
    setStoreWorkdayWorkingHourEdit(wh)
    setStoreWorkdayActive(sw)
    setOpenStoreWorkdayHoursModal(true)
  }

  const newStoreWorkday = () => {
    setOpenStoreWorkdayModal(true)
  }

  const closeStoreWorkdayModal = (new_workday = false) => {
    if (new_workday) setStoreWorkdays([...storeWorkdays, new_workday])
    setOpenStoreWorkdayModal(false)
  }

  const getStoreWorkdays = async () => {
    try {
      const { data: res } = await StoreService.getStoreWorkdays(store_id)
      setStoreWorkdays(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  const getWorkdays = async () => {
    try {
      const { data: res } = await PublicService.getWorkdays()
      setWorkdays(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  const deleteStoreWorkday = async (sw_id, index) => {
    const sw = await confirmAlert(
      'Quitar día de atención',
      '¿Estas seguro de quitar este día de atención de tu negocio'
    )

    if (sw.value) {
      try {
        const { data: res } = await StoreService.deleteStoreWorkday({
          store_id: store_id,
          id: sw_id,
        })
        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })

        storeWorkdays.splice(index, 1)
        setStoreWorkdays(JSON.parse(JSON.stringify(storeWorkdays)))
      } catch (error) {
        const errorMessage = getMessageError(error)
        update_alert({
          open: true,
          severity: 'error',
          message: errorMessage,
        })
      }
    }
  }

  const deleteStoreWorkdayWorkingHour = async (wh_id, sw_index, wh_index) => {
    const sw = await confirmAlert(
      'Quitar bloque de horas de atención',
      '¿Estas seguro de quitar este bloque de atención'
    )

    if (sw.value) {
      try {
        const { data: res } = await StoreService.deleteStoreWorkdayWorkingHour({
          store_id: store_id,
          id: wh_id,
        })
        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })

        storeWorkdays[sw_index].store_workday_working_hours.splice(wh_index, 1)
        setStoreWorkdays(JSON.parse(JSON.stringify(storeWorkdays)))
      } catch (error) {
        const errorMessage = getMessageError(error)
        update_alert({
          open: true,
          severity: 'error',
          message: errorMessage,
        })
      }
    }
  }

  //UseEffect
  useEffect(() => {
    if (store_id) {
      getWorkdays()
      getStoreWorkdays()
    }
  }, [store_id])

  return (
    <>
      <div className="d-flex justify-content-between p-3">
        <h5 className="pr-2">
          Administra los horarios de atención de tu negocio
        </h5>
        <div>
          <Button variant="contained" color="primary" onClick={newStoreWorkday}>
            <i className="fa fa-plus mr-2"></i>
            Nuevo
          </Button>
        </div>
      </div>

      <div className="pt-4">
        {storeWorkdays &&
          storeWorkdays.length > 0 &&
          storeWorkdays.sort(compare('workday_id')).map((sw, sw_index) => {
            return (
              <div className="border p-3 m-3" key={sw.id}>
                <span className="h5">
                  <i
                    className="fa fa-trash text-danger cursor-pointer mr-4"
                    onClick={() => deleteStoreWorkday(sw.id, sw_index)}
                  ></i>

                  {sw.workday.name}
                </span>
                {sw.store_workday_working_hours.length > 0 &&
                  sw.store_workday_working_hours
                    .sort(compare('start_hour'))
                    .map((wh, wh_index) => {
                      return (
                        <span className="border m-2 p-2 rounded" key={wh.id}>
                          {moment(wh.start_hour, 'HH:mm:ss').format('h:mm a')} -{' '}
                          {moment(wh.end_hour, 'HH:mm:ss').format('h:mm a')}
                          <i
                            className="fa fa-edit mx-3 text-success cursor-pointer"
                            onClick={() => editStoreWorkdayWorkingHour(sw, wh)}
                          ></i>
                          <i
                            className="fa fa-trash text-danger cursor-pointer"
                            onClick={() =>
                              deleteStoreWorkdayWorkingHour(
                                wh.id,
                                sw_index,
                                wh_index
                              )
                            }
                          ></i>
                        </span>
                      )
                    })}
                <span
                  className="border m-2 p-2 rounded cursor-pointer text-white"
                  style={{ background: '#C8C8C8' }}
                  onClick={() => newStoreWorkdayHours(sw)}
                >
                  <i className="fa fa-plus"></i>
                  Nuevo bloque horario
                </span>
              </div>
            )
          })}
      </div>

      <AddStoreWorkdayModal
        open={openStoreWorkdayModal}
        store_id={store_id}
        handleClose={closeStoreWorkdayModal}
        workdays={workdays}
        storeWorkdays={storeWorkdays}
      />

      <AddAndUpdateWorkdayHours
        open={openStoreWorkdayHoursModal}
        handleClose={closeStoreWorkdayHoursModal}
        store_id={store_id}
        storeWorkday={storeWorkdayActive}
        editStoreWorkdayWorkingHour={storeWorkdayWorkingHourEdit}
      />
    </>
  )
}

export default connect(null, { update_alert })(AddAndUpdateWorkdaysStore)
