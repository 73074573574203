import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import {
  update_cart,
  update_alert,
  update_show_status_order_modal,
} from '../store/actions'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Button, DialogActions } from '@material-ui/core'
import { useForm } from 'react-hook-form'
//Carousel
import Carousel from 'react-material-ui-carousel'
import Radio from '@material-ui/core/Radio'

import {
  calcPrice,
  calcCostDelivery,
  initStateCart,
  currencyFormat,
} from '../helpers'

//Services
import PublicService from '../services/public.service'
import MarkPositionInMap from './MarkPositionInMap'
import { isPointInPolygon, getDistance } from 'geolib'
import OrderService from '../services/order.service'

const useStyles = makeStyles(theme => ({
  btnOrderType: {
    background: '#ccc',
    padding: '0.7rem 0.8rem',
    color: 'white',
    margin: '5px 12px',
    cursor: 'pointer',
    borderRadius: '5px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
}))

const PublicCompleteAndSendOrder = props => {
  //Redux
  const {
    cart,
    update_cart,
    update_alert,
    update_show_status_order_modal,
    currentCurrencyClient,
  } = props

  //props
  const { handleActiveStep } = props

  //Styles
  const classes = useStyles()

  //State
  const [client, setClient] = useState({
    name: '',
    phone: '',
    address: '',
    address_reference: '',
    payment_reference: '',
    observations: '',
  })

  //Selected payment method
  const [selectedValue, setSelectedValue] = React.useState('')

  //Select order type (delivery, recoger)
  const [orderType, setOrderType] = useState(null)

  const [paymentMethods, setPaymentMethoods] = useState([])

  const [inArea, setInArea] = useState(false)

  const [costDelivery, setCostDelivery] = useState(0)

  //Validation hook
  const { register, handleSubmit, errors } = useForm()

  const PaymentItem = ({ paymentMethod, item }) => {
    return (
      <div className="p-3" style={{ border: '1px solid #E6E6E6' }}>
        {item.payment_method_fields.map(field => (
          <p key={field.id}>
            <span className="font-weight-bold">{field.name}:</span>{' '}
            {field.value}
          </p>
        ))}

        {paymentMethod.note && (
          <div className="pb-3">
            <span className="text-danger">Nota:</span> {paymentMethod.note}
          </div>
        )}

        <div className="mt-2">
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            variant="outlined"
            label="Referencia del pago"
            name="payment_reference"
            value={client.payment_reference}
            onChange={handleInputChange}
            error={!!errors.payment_reference}
            helperText={
              errors.payment_reference && 'La referencia del pago es requerida'
            }
          />
        </div>
      </div>
    )
  }

  //Methods
  const handleInputChange = e =>
    setClient({
      ...client,
      [e.currentTarget.name]: e.currentTarget.value,
    })

  const handleChange = event => {
    setSelectedValue(event.target.value)
  }

  //get payment methods store
  const getPaymentMethods = async store_id => {
    try {
      const { data: res } = await PublicService.getPaymentMethodsStore(store_id)
      setPaymentMethoods(res.data)
    } catch (error) {}
  }

  //Store order
  const storeOrder = async () => {
    //Validate in area for delivery
    if (orderType === 1 && !inArea) {
      update_alert({
        open: true,
        severity: 'error',
        message: 'Esta tienda no cubre delivery a tu ubicación actual',
      })
      return
    }

    //Validate if exists payment method
    if (!selectedValue) {
      update_alert({
        open: true,
        severity: 'error',
        message: 'Debes elegir un método de pago',
      })
      return
    }

    console.log(client) //Data client
    console.log(orderType) //Delivery or pickup
    console.log(selectedValue) //Metodo de pago
    console.log(inArea)
    console.log(costDelivery) //Cost delivery

    try {
      const { data: res } = await OrderService.save({
        client: client,
        order_type: orderType,
        payment_method: {
          id: selectedValue,
          reference: client.payment_reference,
          currency: 1,
        },
        cost_delivery: orderType === 1 ? costDelivery : 0,
      })
      console.log(res)
      update_cart(initStateCart())
      update_alert({
        open: true,
        severity: 'success',
        message: 'Orden completada correctamente',
      })
      handleActiveStep(3)
      update_show_status_order_modal({
        open: true,
        order_id: res.data.id,
        store_id: res.data.store_id,
      })
    } catch (error) {
      console.log(error)
    }
  }

  //Is point in polygon
  const isPointInArea = (latitude, longitude) => {
    let existInArea = false
    const polygons = JSON.parse(cart.coordinates_delivery_area)
    if (polygons) {
      polygons.forEach(polygon => {
        let coordinates = polygon.coordinates.map(function (row) {
          return { latitude: row.lat, longitude: row.lng }
        })
        console.log('coordenadas', coordinates)
        if (!existInArea) {
          existInArea = isPointInPolygon(
            { latitude: latitude, longitude: longitude },
            coordinates
          )
          console.log(existInArea)
        }
      })
      setInArea(() => existInArea)

      //Get distance store to client
      const distanceStoreClient = getDistance(
        {
          latitude: latitude,
          longitude: longitude,
        },
        { latitude: cart.latitude, longitude: cart.longitude }
      )

      console.log(
        distanceStoreClient,
        JSON.parse(cart.distance_price_deliveries)
      )

      const kms = distanceStoreClient / 1000
      console.log(kms)
      const dpds = JSON.parse(cart.distance_price_deliveries)
      const distance_price_delivery = dpds.find(i => {
        return kms >= i.start_distance && kms <= i.end_distance
      })

      if (distance_price_delivery) {
        setCostDelivery(parseFloat(distance_price_delivery.price))
      } else {
        setCostDelivery(parseFloat(dpds[dpds.length - 1].price))
      }
    }
    console.log(existInArea ? 'trueee' : 'falseee')
  }

  //useEffect
  useEffect(() => {
    if (cart.store_id) {
      getPaymentMethods(cart.store_id)
      if (cart.store_mode_delivery) {
        setOrderType(1)
      } else {
        setOrderType(2)
      }
    }
  }, [cart])

  return (
    <>
      <form onSubmit={handleSubmit(storeOrder)}>
        <div>
          <h5 className="pb-3 pt-1">Tipo de orden</h5>
          <div>
            {cart.store_mode_delivery && (
              <span
                onClick={() => setOrderType(1)}
                className={
                  classes.btnOrderType + (orderType === 1 ? ' bg-primary' : '')
                }
              >
                <i className="fa fa-biking mr-2"></i>
                Delivery
              </span>
            )}
            {cart.store_mode_pickup && (
              <span
                onClick={() => setOrderType(2)}
                className={
                  classes.btnOrderType + (orderType === 2 ? ' bg-primary' : '')
                }
              >
                <i className="fa fa-shopping-bag mr-2"></i>
                Recoger
              </span>
            )}
          </div>

          <div className="mt-4 pt-1">
            <TextField
              inputRef={register({ required: true })}
              fullWidth
              variant="outlined"
              label="Nombre de quien recibe"
              name="name"
              defaultValue={client.name}
              onChange={handleInputChange}
              error={!!errors.name}
              helperText={errors.name && 'El campo quien recibe es requerido'}
            />
          </div>

          <div className="mt-4">
            <TextField
              inputRef={register({ required: true })}
              fullWidth
              variant="outlined"
              label="Teléfono de contacto"
              name="phone"
              defaultValue={client.phone}
              onChange={handleInputChange}
              error={!!errors.phone}
              helperText={errors.phone && 'El campo teléfono es requerido'}
            />
          </div>
          {orderType === 1 && (
            <>
              {' '}
              <div className="mt-4 pt-1">
                <TextField
                  inputRef={register({ required: true })}
                  fullWidth
                  variant="outlined"
                  label="Dirección de entrega"
                  name="address"
                  defaultValue={client.address}
                  onChange={handleInputChange}
                  error={!!errors.address}
                  helperText={
                    errors.address && 'El campo dirección es requerido'
                  }
                />
              </div>
              <div className="mt-4 pt-1 mb-3">
                <TextField
                  inputRef={register({ required: true })}
                  fullWidth
                  variant="outlined"
                  label="Punto de referencia"
                  name="address_reference"
                  defaultValue={client.address_reference}
                  onChange={handleInputChange}
                  error={!!errors.address_reference}
                  helperText={
                    errors.address_reference &&
                    'La campo dirección es requerido'
                  }
                />
              </div>
              <div>
                {!inArea && (
                  <p className="alert alert-warning p-1 mb-0 mt-1">
                    La tienda no cubre delivery hasta donde esta tu posicíon
                    actual, recuerda que puedes mover el icono de uicación si
                    consideras no te hemos ubicado correctamente
                  </p>
                )}
              </div>
              <MarkPositionInMap cart={cart} isPointInArea={isPointInArea} />
            </>
          )}
        </div>

        {/* Payment methods */}
        <div>
          <h5 className="py-2 pt-4">Elige el metodo de pago</h5>

          <div>
            {paymentMethods.length > 0 &&
              paymentMethods.map(pm => {
                return (
                  <div key={pm.id}>
                    {((pm.use_in_pickup && orderType === 2) ||
                      (pm.use_in_delivery && orderType === 1)) && (
                      <div className="px-2" key={pm.id}>
                        <h6>
                          <label>
                            <Radio
                              checked={selectedValue == pm.id}
                              onChange={handleChange}
                              value={pm.id}
                              name="radioPm"
                            />
                            {pm.payment_method.name}
                          </label>
                        </h6>
                        {selectedValue == pm.id && (
                          <Carousel
                            animation="slide"
                            autoPlay={false}
                            timeout={400}
                          >
                            {pm.payment_method_store_lists.map((item, i) => (
                              <div
                                key={i}
                                className="p-3"
                                style={{ border: '1px solid #E6E6E6' }}
                              >
                                {item.payment_method_fields.map(field => (
                                  <p key={field.id}>
                                    <span className="font-weight-bold">
                                      {field.name}:
                                    </span>{' '}
                                    {field.value}
                                  </p>
                                ))}

                                {pm.note && (
                                  <div className="pb-3">
                                    <span className="text-danger">Nota:</span>{' '}
                                    {pm.note}
                                  </div>
                                )}

                                <div className="mt-2">
                                  <TextField
                                    inputRef={register({ required: true })}
                                    fullWidth
                                    variant="outlined"
                                    label="Referencia del pago"
                                    name="payment_reference"
                                    defaultValue={client.payment_reference}
                                    onChange={handleInputChange}
                                    error={!!errors.payment_reference}
                                    helperText={
                                      errors.payment_reference &&
                                      'La referencia del pago es requerida'
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </Carousel>
                        )}
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>

        {/* Note additional */}
        <div>
          <h5 className="py-2 pt-3">Nota sobre el pedido (opcional)</h5>
          <div className="mt-2">
            <TextField
              multiline
              placeholder="Si quieres acotar algo sobre tu pedido"
              fullWidth
              variant="outlined"
              name="observations"
              defaultValue={client.observations}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="d-flex align-items-center m-0 p-3 border flex-wrap mt-2">
          <p className="w-100 m-0 ">
            Subtotal:{' '}
            {calcPrice(
              cart.total,
              currentCurrencyClient,
              cart.store_currency,
              cart.store_exchange_rate
            )}
          </p>
          {orderType === 1 && (
            <p className="w-100 m-0 ">
              Delivery:{' '}
              {calcPrice(
                calcCostDelivery(costDelivery),
                currentCurrencyClient,
                cart.store_currency,
                cart.store_exchange_rate
              )}
            </p>
          )}

          <p className="w-100 m-0 ">
            {currentCurrencyClient === 1
              ? 'Total en Bolivares: '
              : 'Total en Dolares: '}
            {calcPrice(
              cart.total +
                cart.total_additional_options +
                (orderType === 1 ? calcCostDelivery(costDelivery) : 0),
              currentCurrencyClient === 1 ? 2 : 1,
              cart.store_currency,
              cart.store_exchange_rate
            )}
          </p>

          <p className="w-100 m-0 ">
            Tasa de cambio: {currencyFormat(cart.store_exchange_rate, 'Bs')}
          </p>

          <h4 className="m-0">
            <b>Total: </b>

            {calcPrice(
              cart.total +
                cart.total_additional_options +
                (orderType === 1 ? calcCostDelivery(costDelivery) : 0),
              currentCurrencyClient,
              cart.store_currency,
              cart.store_exchange_rate
            )}
          </h4>

          <DialogActions
            style={{
              position: 'absolute ',
              bottom: '0',
              right: '10px',
            }}
            className="p-2 d-flex align-items-center bg-primary w-100 bg-white border-top justify-content-between"
          >
            <Button
              type="button"
              onClick={() => handleActiveStep(0)}
              variant="contained"
              className="ml-3"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Atras
            </Button>
            <Button color="primary" type="submit" variant="contained">
              <i className="fa fa-paper-plane mr-2"></i>
              Enviar
            </Button>
          </DialogActions>
        </div>
      </form>
    </>
  )
}

const mapStateToProps = state => ({
  cart: state.cart,
  currentCurrencyClient: state.currentCurrencyClient,
})

export default connect(mapStateToProps, {
  update_cart,
  update_alert,
  update_show_status_order_modal,
})(PublicCompleteAndSendOrder)
