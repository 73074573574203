import http from '../http'

class UtilityService {
  static getStatuses() {
    return http({
      method: 'get',
      url: 'all-statuses',
    })
  }

  static getStatusesToOrder(data) {
    return http({
      method: 'get',
      url: 'statuses-to-order',
      params: data,
    })
  }

  static saveShippingAddress(data) {
    return http({
      method: 'post',
      url: 'save-shipping-address',
      data,
    })
  }

  static changeShippingAddress(id) {
    return http({
      method: 'put',
      url: 'update-shipping-address',
      data: { id: id },
    })
  }

  static getStoreCategories() {
    return http({
      method: 'get',
      url: 'store-categories',
    })
  }

  static getStoreSubCategories(store_category_id) {
    return http({
      method: 'get',
      url: 'store-sub-categories',
      params: {
        store_category_id: store_category_id,
      },
    })
  }

  static getProductCategories() {
    return http({
      method: 'get',
      url: 'get-product-categories',
    })
  }

  //Get exchange rate womve
  static getExchangeRate() {
    return http({
      method: 'get',
      url: 'exchange-rate',
    })
  }

  //Get payment methods womve
  static getPaymentMethods() {
    return http({
      method: 'get',
      url: 'payment-methods',
    })
  }

  static saveStatusOrder(data) {
    return http({
      method: 'post',
      url: 'save-status-order',
      data,
    })
  }

  static saveQualifyOrder(data) {
    return http({
      method: 'post',
      url: 'save-qualify-order',
      data,
    })
  }
}

export default UtilityService
