import http from '../http'

class OrderService {
  //Save new order
  static save(data) {
    return http({
      method: 'post',
      url: 'orders',
      data: data,
    })
  }
  //Get all orders
  static all(page = 1, search = '') {
    return http({
      method: 'get',
      url: 'all-orders',
    })
  }

  //Get orders user auth
  static getOrdersAuthUser(page = 1, search = '') {
    return http({
      method: 'get',
      url: 'orders',
    })
  }

  static getOrderById(id) {
    return http({
      method: 'get',
      url: `orders/${id}`,
    })
  }

  static saveStatus(data) {
    return http({
      headers: {
        //'X-Socket-ID': window.echo.socketId(),
      },
      method: 'post',
      url: 'order-save-status',
      data,
    })
  }

  //Get all orders distributor
  static getDistributorOrders(page = 1, search = '') {
    return http({
      method: 'get',
      url: 'distributor-all-orders',
    })
  }
}

export default OrderService
