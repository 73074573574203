import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import AuthService from '../services/auth.service'
import { useEffect } from 'react'
import { update_user, update_loading, update_alert } from '../store/actions'
import { connect } from 'react-redux'

import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'

import Alert from './../components/Alert'
import Loading from './../components/Loading'
import Button from '@material-ui/core/Button'
import logo from './logo.png'

import PublicStatusOrderModal from '../components/PublicStatusOrderModal'

const drawerWidth = 230

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: '2 !important',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(8),
    paddingLeft: '10px',
    paddingRight: '10px',
  },
}))

function Home(props) {
  const {
    container,
    children,
    update_user,
    update_loading,
    update_alert,
    user,
    storeInfo,
  } = props

  const classes = useStyles()
  const theme = useTheme()

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Link to="/">
          <img width="150" height="30" src={logo} alt="logo" />
        </Link>
      </div>

      <Divider />
      <List>
        <ListItem button>
          <Link to="/home">
            <ListItemText>
              <i className="fa fa-tachometer-alt"></i>
              Dashboard
            </ListItemText>
          </Link>
        </ListItem>

        {user && user.rol === 'distributor' && (
          <>
            <ListItem button>
              <Link to="/distributor-orders">
                <ListItemText>
                  <i className="fa fa-shopping-cart"></i>
                  Ordenes
                </ListItemText>
              </Link>
            </ListItem>
          </>
        )}
        {user && user.rol === 'admin' && (
          <>
            <ListItem button>
              <Link to="/orders">
                <ListItemText>
                  <i className="fa fa-shopping-cart"></i>
                  Ordenes
                </ListItemText>
              </Link>
            </ListItem>

            <ListItem button>
              <Link to="/products">
                <ListItemText>
                  <i className="fa fa-tags"></i>
                  Productos
                </ListItemText>
              </Link>
            </ListItem>

            <ListItem button>
              <Link to="/product-categories">
                <ListItemText>
                  <i className="fas fa-list"></i>
                  Categorías productos
                </ListItemText>
              </Link>
            </ListItem>

            <ListItem button>
              <Link to="/menus">
                <ListItemText>
                  <i className="fas fa-sitemap"></i>
                  Secciones menú
                </ListItemText>
              </Link>
            </ListItem>
          </>
        )}

        {user && user.rol === 'admin' && (
          <>
            <ListItem button>
              <Link to="/store-distributors">
                <ListItemText>
                  <i className="fa fa-biking"></i>
                  Repartidores
                </ListItemText>
              </Link>
            </ListItem>
          </>
        )}

        {user && user.rol === 'admin' && (
          <>
            <ListItem button>
              <Link to="/stores">
                <ListItemText>
                  <i className="fa fa-store"></i>
                  Tiendas
                </ListItemText>
              </Link>
            </ListItem>
          </>
        )}
      </List>

      <PublicStatusOrderModal />
    </div>
  )

  useEffect(() => {
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getUser() {
    try {
      const { data: res } = await AuthService.whoami()
      if (res.success) {
        update_user(res.user)
      } else {
      }
    } catch (error) {}
  }

  //go to edit profile
  const profile = () => {
    props.history.push('/profile')
    handleClose()
  }

  const logout = () => {
    localStorage.clear()
    update_user(false)
    props.history.push('/')
  }

  const resendEmailVerify = async () => {
    try {
      update_loading({
        show: true,
        message: 'Reenviando email de verificación',
      })
      const { data: res } = await AuthService.resendEmailVerify()
      console.log(res)
      if (res.success) {
        if (res.data.user) {
          update_user(res.data.user)
        }

        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })

        update_loading({
          show: false,
        })
        return
      }

      update_alert({
        open: true,
        severity: 'error',
        message: 'Algo va maldd',
      })
    } catch (error) {
      const errorMessage =
        typeof error.response !== 'undefined'
          ? error.response.data.message
          : error.message

      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className="homeToolbar">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap>
            <i className="fa fa-store"></i>{' '}
            {storeInfo ? storeInfo.name : 'Selecciona una tienda'}
          </Typography>

          <div className="user">
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem>{user.name}</MenuItem>
              <Divider />
              <MenuItem onClick={profile}>Perfil</MenuItem>
              <MenuItem onClick={logout}>Salir</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="sidebar">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Loading />

        {user && !user.email_verified_at && (
          <p>
            Revisa tu correo, te enviamos un enlace de verificación si no lo
            recibiste lo puedes reenviar haciendo
            <Button color="primary" onClick={resendEmailVerify}>
              Click aquí
            </Button>
          </p>
        )}

        {children}
      </main>
      <Alert />
    </div>
  )
}

Home.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
}

const mapStateToProps = state => ({
  user: state.user,
  storeInfo: state.storeInfo,
})

export default connect(mapStateToProps, {
  update_user,
  update_alert,
  update_loading,
})(Home)
