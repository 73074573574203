import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LoaderLib from "react-loader-spinner";

const Loader = ({ text, height, width, type, color }) => {
  return (
    <span style={{ color: color }}>
      {text}{" "}
      <LoaderLib
        style={{ display: "inline-block", fontSize: "0.9em" }}
        type={type}
        color={color}
        height={height}
        width={width}
      />
    </span>
  );
};

export default Loader;
