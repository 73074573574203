import React from "react";

const NoRegistersMessage = (props) => {
  const { search } = props;
  return (
    <p className="text-center border-top text-primary">
      <i className="fa fa-info-circle"></i>
      {search
        ? "No se encontraron registros para tu busqueda"
        : "Sin registros para mostrar"}
    </p>
  );
};

export default NoRegistersMessage;
