import http from '../http'
import * as currencyFormatter from 'currency-formatter'
import socketIo from 'socket.io-client'
import Echo from 'laravel-echo'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'moment/locale/es'
import CartService from '../services/cart.service'

moment.locale('es')

export function setupRequestInterceptors() {
  http.interceptors.request.use(
    function (config) {
      config.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
        'token'
      )}`
      config.headers.common['womvesi'] = `${localStorage.getItem('womvesi')}`
      config.headers.common['womvesii'] = `${localStorage.getItem('womvesii')}`
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )
}

export function setupResponseInterceptors() {
  http.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      console.log(error)
      //Si es un error 401 o 403
      //Borrar localStorage y sacar a login
      if (
        (typeof error.response && error.response.status === 401) ||
        error.response.status === 403
      ) {
        localStorage.clear()
        window.location.href = '/'
      } else {
        console.log(error)
      }

      return Promise.reject(error)
    }
  )
}

export function getMessageError(error) {
  let message = ''
  if (
    error &&
    error.response &&
    error.data &&
    typeof error.response.data.data !== 'undefined' &&
    error.response !== null
  ) {
    message = error.response.data.message
    if (
      typeof error.response.data.data !== 'undefined' &&
      error.response.data.data !== null
    ) {
      //If exists error
      if (
        typeof error.response.data.data !== 'undefined' &&
        error.response.data.data !== null
      ) {
        let messages = []
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(error.response.data.data)) {
          // eslint-disable-next-line
          value.forEach(message => {
            messages.push(message)
          })
        }
        messages = messages.join(' - ')
        message += ' - ' + messages
      } else {
        message = error.message
      }
    }
  } else {
    message = error.message
  }
  console.log(message)

  return message
}

//Remove object from array by id differents pass id
export function removeObjectFromArray(objectsArray, id) {
  return objectsArray.filter(i => i.id !== id)
}

//Remove other options additional options product
export function removeOtherOptions(other_options, additional_options) {
  let additional_options_array = []
  other_options.forEach(option => {
    //Remove where id different to pass id
    additional_options_array = removeObjectFromArray(
      additional_options,
      option.id
    )
  })

  return additional_options_array
}

//Remove dependent atributes of the option recusive
export function recusiveRemoveDependentAttributes(
  dependent_attributes,
  attributes,
  additional_options_array
) {
  dependent_attributes.forEach(d => {
    const index = attributes.findIndex(a => a.id === d.attribute_id)
    let attribute = attributes[index]
    attribute.dependent_attribute = true

    //Remove options of the dependent attribute of additional options array
    attribute.options.forEach(option => {
      additional_options_array = removeObjectFromArray(
        additional_options_array,
        option.id
      )

      //If option of the dependent attribute so has dependent attributes remove recursive
      if (option.dependent_attributes) {
        additional_options_array = recusiveRemoveDependentAttributes(
          option.dependent_attributes,
          attributes,
          additional_options_array
        )
      }
    })
  })
  return additional_options_array
}

//Get validation rules from option
export const getValidationRules = option => {
  let required = !!option.required ? true : false
  return {
    required: {
      value: required,
      message: 'Este campo es requerido',
    },
  }
}

//Format currency
export const currencyFormat = (value, symbol = '$') => {
  return currencyFormatter.format(value, {
    symbol: `${symbol} `,
    decimal: ',',
    thousand: '.',
    decimalDigits: 0,
    spaceBetweenAmountAndSymbol: true,
  })
}

//Laravel echo instance
export const newEcho = () =>
  new Echo({
    broadcaster: 'socket.io',
    host: 'http://localhost:6001',
    auth: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // Accept: 'application/json',
        // 'Content-Type': 'application/json',
      },
    },
    client: socketIo,
  })

//function that gets the location and returns it
export function getLocation() {
  if (navigator.geolocation) {
    console.log(navigator.geolocation)
    navigator.geolocation.getCurrentPosition(showPosition, error)
  } else {
    console.log('Geo Location not supported by browser')
  }
}

export const urlFiles =
  'http://localhost/personal/womve/backend/public/storage/'

//function that retrieves the position
function showPosition(position) {
  var location = {
    longitude: position.coords.longitude,
    latitude: position.coords.latitude,
  }
  console.log(location)
}

function error(err) {
  console.log('ERROR(' + err.code + '): ' + err.message)
}

//Get deafult registers per page
export const defaultRowsPerPage = 10
//Options of registers per page
export const arrayRowsPerPageOptions = [10, 25, 50, 100]

//Alert confirm
export function confirmAlert(title, text) {
  return Swal.fire({
    title: title,
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, continuar',
    cancelButtonText: 'Cancelar',
  })
}

//Get string hour and minutes (00:00) from Date
export function getStringHourMinutesFromDate(date = new Date()) {
  const [hour, minute] = date.toLocaleTimeString('en-GB').slice(0, 7).split(':')

  return `${hour}:${minute}`
}

//Get date with set hour and minutes from string
export function getDateWithSetHourMinutes(stringHourMinutes) {
  const date = new Date()
  if (stringHourMinutes) {
    let [hour, minutes] = stringHourMinutes.split(':')
    date.setHours(+hour)
    date.setMinutes(+minutes)
  }

  return date
}

//Truncate string
export const truncateString = (text, maxLetters) => {
  if (text) {
    if (text.length > maxLetters) {
      text = text.substring(0, maxLetters)
    }
  }
  return text
}

//Calculate cost delivery
export const calcCostDelivery = store_cost_delivery => {
  return parseFloat(store_cost_delivery)
}

//Add Or Update Product In Cart
export const AddOrUpdateProductInCart = async (
  oldCart,
  product,
  newQuantity,
  store
) => {
  const cart = JSON.parse(JSON.stringify(oldCart))
  const quantity = parseFloat(newQuantity)
  let items = cart.items
  const isAuth = localStorage.getItem('token')

  //If quantity is 0
  //Delete product cart
  if (quantity === 0) {
    items = cart.items.filter(i => i.id !== product.id)

    if (isAuth) {
      const { data: res } = await CartService.removeCartProduct({
        store_id: store.id,
        product_id: product.id,
      })
    }
    //If quantity not is 0
  } else {
    //Verify if product exists in cart
    const productExists = items.findIndex(i => i.id === product.id)
    if (productExists !== -1) {
      if (isAuth) {
        const { data: res } = await CartService.updateQuantity({
          store_id: store.id,
          product_id: product.id,
          quantity: quantity,
        })
      }

      items[productExists] = {
        ...items[productExists],
        total: parseFloat(items[productExists].price) * quantity,
        total_additional_options:
          parseFloat(items[productExists].cost_additional_options) * quantity,
        quantity: quantity,
      }
      //If product not exists in cart
    } else {
      const newProduct = {
        id: product.id,
        store_id: product.store_id,
        name: product.name,
        url_image: product.images[0].url,
        price: product.price,
        total: parseFloat(product.price) * quantity,
        quantity: quantity,
        additional_options: product.additional_options
          ? product.additional_options
          : [],
      }
      //If have additional options calc cost
      let costAdditionalOptions = 0
      if (newProduct.additional_options) {
        //Calc cost product additional options
        costAdditionalOptions = newProduct.additional_options.reduce(
          (prev, cur) => parseFloat(prev) + parseFloat(cur.price),
          0
        )
      }

      newProduct.cost_additional_options = costAdditionalOptions
      newProduct.total_additional_options = costAdditionalOptions * quantity

      if (isAuth) {
        const { data: res } = await CartService.save({
          store_id: store ? store.id : cart.store_id,
          product_id: product.id,
          quantity: quantity,
          additional_options: JSON.stringify(product.additional_options),
        })
      }

      //Push newProduct into cart
      items.push(newProduct)
    }
  }

  //Verify cart have store
  if (cart.store_id) {
    //If items is empty clear store_id
    if (items.length === 0) {
      cart.store_id = null
      cart.store_name = null
      cart.store_currency = null
      cart.store_exchange_rate = null
      cart.store_cost_delivery = null
      cart.store_url_logo = null
      cart.latitude = null
      cart.longitude = null
      cart.store_mode_delivery = null
      cart.store_mode_pickup = null
    }
    //If cart not store
  } else {
    cart.store_id = store.id
    cart.store_name = store.name
    cart.store_currency = store.currency
    cart.store_exchange_rate = store.exchange_rate
    cart.store_cost_delivery = store.cost_delivery
    cart.store_url_logo = store.url_logo
    cart.latitude = store.latitude
    cart.longitude = store.longitude
    cart.coordinates_delivery_area = store.coordinates_delivery_area
    cart.distance_price_deliveries = store.distance_price_deliveries
    cart.store_mode_delivery = store.mode_delivery
    cart.store_mode_pickup = store.mode_pickup
  }

  //Add new totals to cart
  //Calc total cart
  const cartTotal = items.reduce(
    (prev, cur) => parseFloat(prev) + parseFloat(cur.total),
    0
  )

  //Calc total additional options
  const cartTotalAdditionalOptions = items.reduce(
    (prev, cur) => parseFloat(prev) + parseFloat(cur.total_additional_options),
    0
  )

  cart.total = cartTotal
  cart.total_additional_options = cartTotalAdditionalOptions
  cart.items = items

  //Save cart into local storage
  if (!isAuth) {
    localStorage.setItem('cart', JSON.stringify(cart))
  }

  return cart
}

export const compare = (property, is_number = false) => {
  if (is_number) {
    return (a, b) => {
      if (parseFloat(a[property]) < parseFloat(b[property])) {
        return -1
      }
      if (parseFloat(a[property]) > parseFloat(b[property])) {
        return 1
      }
      return 0
    }
  } else {
    return (a, b) => {
      if (a[property] < b[property]) {
        return -1
      }
      if (a[property] > b[property]) {
        return 1
      }
      return 0
    }
  }
}

export const validateStoreIsOpen = (store, currentDateTime, today) => {
  if (store && store.store_workdays.length > 0) {
    const workday = store.store_workdays.find(
      i => i.workday.name.toLowerCase() === today
    )
    let store_is_open = false
    if (workday) {
      const format = 'HH:mm'
      const currentHour = moment(currentDateTime.time, format)
      store_is_open = workday.store_workday_working_hours.find(i => {
        const beforeTime = moment(i.start_hour, format)
        const afterTime = moment(i.end_hour, format)
        return currentHour.isBetween(beforeTime, afterTime, undefined, '[]')
      })
    }

    return store_is_open ? true : false
  }
}

export const initStateCart = () => {
  return {
    items: [],
    store_id: null,
    store_name: null,
    store_currency: null,
    store_exchange_rate: null,
    store_cost_delivery: null,
    store_url_logo: null,
    latitude: null,
    longitude: null,
    store_mode_delivery: null,
    store_mode_pickup: null,
    total: 0,
    total_additional_options: 0,
  }
}

//Calculate price cuurent currency and exchange_rate
export const calcPrice = (
  price,
  current_currency,
  store_currency,
  exchange_rate
) => {
  price = parseFloat(price)
  current_currency = parseInt(current_currency)
  store_currency = parseInt(store_currency)
  exchange_rate = parseFloat(exchange_rate)

  let amount = price
  if (current_currency !== store_currency) {
    //If 1: dollars
    if (store_currency === 1) {
      amount = price * exchange_rate
    }
    //If 2: Bolivares
    if (store_currency === 2) {
      amount = price / exchange_rate
    }
  }

  const currencySymbol = current_currency === 1 ? '$' : 'Bs'

  return currencyFormat(amount, currencySymbol)
}

//Show status order to user rol
export const showStatusOrder = (userRol, statusShowTo) => {
  const showTo = JSON.parse(statusShowTo)
  return showTo.includes(userRol) || userRol === 'admin'
}

//Show status name to user rol
export const showStatusName = (userRol, status) => {
  return userRol === 'distributor' ? status.name_distributor : status.name
}

//Show status description to user rol
export const showStatusDescription = (userRol, status) => {
  return userRol === 'distributor'
    ? status.description_distributor
    : status.description
}
