import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  DialogActions,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import RegisterService from '../services/register.service'

import { connect } from 'react-redux'
import { update_user, update_alert } from '../store/actions'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { getMessageError } from '../helpers'
import { useHistory } from 'react-router-dom'

const CreateAccount = props => {
  const history = useHistory()

  //Redux
  const {
    userSession,
    cart,
    update_user,
    update_alert,
    inOrder,
    pathName,
  } = props

  const { handleActiveStep } = props

  const [user, setUser] = useState({
    name: '',
    surname: '',
    identification: '',
    phone: '',
    email: '',
    password: '',
  })

  const [retrySendVerifyCode, setRetrySendVerifyCode] = useState(false)
  const [verifyCode, setVerifyCode] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showLogin, setShowLogin] = useState(false)

  //Validate hook form
  const { register, handleSubmit, errors } = useForm()

  //Methods
  const handleClickShowPassword = () => {
    setShowPassword(showPassword => !showPassword)
  }

  const handleInputChange = e => {
    setUser({
      ...user,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  const handleInputVerifyCodeChange = e => {
    setVerifyCode(e.currentTarget.value)
  }

  //Store account o login if exists account
  const storeAccount = async () => {
    try {
      //If cart have items consolidate in backend
      if (cart.items && cart.items.length > 0) {
        const items = cart.items.map(i => ({
          id: i.id,
          quantity: i.quantity,
          additional_options: i.additional_options,
        }))

        user.cart = JSON.stringify({
          items: items,
          store_id: cart.store_id,
        })
      }

      const { data: res } = showLogin
        ? await RegisterService.login(user)
        : await RegisterService.register(user)
      if (res.data.access_token) {
        update_user(res.data.user)
        localStorage.setItem('token', res.data.access_token)

        if (!inOrder) {
          if (res.data.user.verified_at) {
            history.push('/')
          }
        }
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  const verifyAccount = async () => {
    try {
      const { data: res } = await RegisterService.verifyAccount(verifyCode)
      if (res.data.verify) {
        update_user(res.data.user)
        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })
      } else {
        update_alert({
          open: true,
          severity: 'error',
          message: res.message,
        })
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  const resendVerifyCode = async () => {
    try {
      const { data: res } = await RegisterService.resendVerifyCode()
      setRetrySendVerifyCode(false)
      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  useEffect(() => {
    if (pathName === '/login') setShowLogin(true)
  }, [pathName])

  useEffect(() => {
    if (userSession && userSession.verified_at) {
      history.push('/')
    }
  }, [userSession])

  return (
    <>
      {!userSession && (
        <>
          <h5 className="text-center">
            {' '}
            {showLogin ? 'Inicia sesión' : 'Registrate'}
          </h5>{' '}
          <div className="d-flex justify-content-end mb-2">
            <Button
              color="secondary"
              onClick={() => setShowLogin(showLogin => !showLogin)}
            >
              {showLogin
                ? '¿ No tienes una cuenta ?'
                : '¿ Ya tienes una cuenta ?'}
            </Button>
          </div>
          <form onSubmit={handleSubmit(storeAccount)}>
            {!showLogin && (
              <>
                <div className="mb-4">
                  <TextField
                    inputRef={register({ required: true })}
                    fullWidth
                    variant="outlined"
                    label="Nombre"
                    name="name"
                    type="text"
                    defaultValue={user.name}
                    onChange={handleInputChange}
                    error={!!errors.name}
                    helperText={errors.name && 'El campo nombre es requerido'}
                  />
                </div>

                <div className="mb-4">
                  <TextField
                    inputRef={register({ required: true })}
                    fullWidth
                    variant="outlined"
                    label="Apellido"
                    name="surname"
                    type="text"
                    defaultValue={user.surname}
                    onChange={handleInputChange}
                    error={!!errors.surname}
                    helperText={
                      errors.surname && 'El campo apellido es requerido'
                    }
                  />
                </div>

                <div className="mb-4">
                  <TextField
                    inputRef={register({ required: true })}
                    fullWidth
                    variant="outlined"
                    label="Cédula"
                    name="identification"
                    type="text"
                    defaultValue={user.identification}
                    onChange={handleInputChange}
                    error={!!errors.identification}
                    helperText={
                      errors.identification && 'El campo cédula es requerido'
                    }
                  />
                </div>

                <div className="mb-4">
                  <TextField
                    inputRef={register({ required: true })}
                    fullWidth
                    variant="outlined"
                    label="Teléfono"
                    name="phone"
                    type="text"
                    defaultValue={user.phone}
                    onChange={handleInputChange}
                    error={!!errors.phone}
                    helperText={
                      errors.phone && 'El campo teléfono es requerido'
                    }
                  />
                </div>
              </>
            )}

            <div className="mb-4">
              <TextField
                inputRef={register({ required: true })}
                fullWidth
                variant="outlined"
                label="Correo"
                name="email"
                type="text"
                defaultValue={user.email}
                onChange={handleInputChange}
                error={!!errors.email}
                helperText={errors.email && 'El campo correo es requerido'}
              />
            </div>

            <div className="mb-4">
              <FormControl
                error={!!errors.password}
                variant="outlined"
                className="w-100"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Contraseña
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  defaultValue={user.password}
                  inputRef={register({ required: true })}
                  onChange={handleInputChange}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={82}
                />
                {!!errors.password && (
                  <FormHelperText>
                    El campo contraseña es requerido
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <DialogActions
              style={
                inOrder
                  ? {
                      position: 'absolute ',
                      bottom: '0',
                      right: '10px',
                    }
                  : {}
              }
              className={
                'p-2 d-flex align-items-center bg-primary w-100 bg-white justify-content-between ' +
                (inOrder ? 'border-top' : '')
              }
            >
              {inOrder && (
                <Button
                  type="button"
                  onClick={() => handleActiveStep(0)}
                  variant="contained"
                  className="ml-3"
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  Atras
                </Button>
              )}
              <Button color="primary" type="submit" variant="contained">
                <i className="fa fa-sign-in-alt mr-2"></i>
                {showLogin ? 'Entrar' : 'Regitrarme'}
              </Button>
            </DialogActions>
          </form>
        </>
      )}

      {userSession && !userSession.verified_at && (
        <>
          <h5 className="p-2 text-center">Verifica tu numero de teléfono</h5>
          <p>
            Enviamos un código de verificación via SMS el codigo podría tardar 1
            minuto en llegar
          </p>
          <div className="d-flex justify-content-center flex-wrap mb-3 mt-0">
            {!retrySendVerifyCode && (
              <CountdownCircleTimer
                isPlaying
                size={90}
                strokeWidth={8}
                duration={60}
                onComplete={() => setRetrySendVerifyCode(true)}
                colors={[
                  ['#004777', 0.33],
                  ['#F7B801', 0.33],
                  ['#A30000', 0.33],
                ]}
              >
                {({ remainingTime }) => (
                  <div className="time-wrapper text-center">
                    <div className="time">{remainingTime}</div>
                    <small>Segundos</small>
                  </div>
                )}
              </CountdownCircleTimer>
            )}

            {retrySendVerifyCode && (
              <div className="w-100 text-center">
                <p>
                  Si tu codigo de verificación no llega en unos minutos prueba
                  enviandote otro
                </p>
                <Button
                  onClick={resendVerifyCode}
                  color="primary"
                  type="submit"
                >
                  Reenviar código de verificación
                </Button>
              </div>
            )}
          </div>
          <form onSubmit={handleSubmit(verifyAccount)}>
            <div className={inOrder ? 'mb-5' : 'mb-5 pb-1'}>
              <TextField
                inputRef={register({
                  required: true,
                  minLength: 6,
                  maxLength: 6,
                })}
                fullWidth
                variant="outlined"
                label="Código de verificación"
                name="verify_code"
                type="text"
                defaultValue={verifyCode}
                onChange={handleInputVerifyCodeChange}
                error={!!errors.verify_code}
                helperText={
                  errors.verify_code &&
                  'El campo código verificación de 6 digitos es requerido'
                }
              />
            </div>
            <DialogActions
              style={{
                position: 'absolute ',
                bottom: '0',
                right: '10px',
              }}
              className={
                'p-2 d-flex align-items-center bg-primary w-100 bg-white' +
                (inOrder ? ' border-top' : '')
              }
            >
              <Button color="primary" type="submit" variant="contained">
                <i className="fa fa-sign-in-alt mr-2"></i>
                Verificar
              </Button>
            </DialogActions>
          </form>
        </>
      )}

      {userSession && userSession.verified_at && (
        <>
          <h5 className="p-2 text-center">Cuenta verificada</h5>
          {inOrder && (
            <p className="text-center">
              Tu cuenta fue verificada con exito ya puedes completar tu pedido
            </p>
          )}
          {!inOrder && (
            <>
              <p className="text-center">
                Tu cuenta fue verificada con exito bienvenido a womve ya puedes
                hacer tus pedidos
              </p>
              <div className="text-center">
                <Button to="/" variant="contained" color="primary">
                  Continuar
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  userSession: state.user,
  cart: state.cart,
})

export default connect(mapStateToProps, { update_user, update_alert })(
  CreateAccount
)
