import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import AuthService from "./../../services/auth.service";
import { Link } from "react-router-dom";
import Alert from "./../../components/Alert";
import { connect } from "react-redux";
import { update_alert, update_loading } from "./../../store/actions";
import Typography from "@material-ui/core/Typography";
import { getMessageError } from "../../helpers";

const SendEmail = (props) => {
  const { update_alert } = props;

  const [user, setUser] = useState({
    email: "",
  });

  const { register, handleSubmit, errors } = useForm();

  const handleInputChange = (e) =>
    setUser({
      ...user,
      [e.currentTarget.name]: e.currentTarget.value,
    });

  const sendEmail = async () => {
    try {
      update_loading({
        show: true,
        message: "Enviando correo de recuperación",
      });

      const { data: res } = await AuthService.resetPasswordSendEmail(user);
      if (res.success) {
        update_alert({
          open: true,
          severity: "success",
          message: res.message,
        });
      } else {
        update_alert({
          open: true,
          severity: "error",
          message: res.message,
        });
      }
    } catch (error) {
      const errorMessage = getMessageError(error);
      update_alert({
        open: true,
        severity: "error",
        message: errorMessage,
      });
    }

    update_loading({
      show: false,
    });
  };

  return (
    <>
      <div className="container">
        <div className="card">
          <div className="text-center">
            <Typography variant="h5">Recuperar Contraseña</Typography>
          </div>

          <form onSubmit={handleSubmit(sendEmail)}>
            <div className="email-field">
              <TextField
                autoComplete="off"
                inputRef={register({
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                fullWidth
                variant="outlined"
                label="Tu Email"
                type="email"
                name="email"
                value={user.email}
                onChange={handleInputChange}
                error={!!errors.email}
                helperText={
                  errors.email &&
                  "El campo es requerido y debe ser un email valido"
                }
              />
            </div>

            <div className="text-center">
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
              >
                <i className="fa fa-sign-in-alt"></i>
                Enviar
              </Button>
            </div>

            <div className="mt-1 text-center">
              <Link to="/login">Volver al login</Link>
            </div>
          </form>
        </div>
      </div>

      <Alert />
    </>
  );
};

export default connect(null, { update_alert, update_loading })(SendEmail);
