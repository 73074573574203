import React, { useEffect } from 'react'
import CreateAccount from '../../../components/CreateAccount'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { update_user } from '../../../store/actions'
import AuthService from '../../../services/auth.service'
import { useLocation } from 'react-router-dom'
import Loading from '../../../components/Loading'
import Alert from '../../../components/Alert'

const Register = props => {
  const location = useLocation()

  //Redux actions
  const { update_user } = props

  useEffect(() => {
    if (localStorage.getItem('token')) {
      getUser()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getUser() {
    try {
      const { data: res } = await AuthService.whoami()
      console.log(res)
      if (res.success) {
        update_user(res.user)
      } else {
      }
    } catch (error) {}
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12 p-3">
          <Link to="/">
            {' '}
            <i className="fa fa-arrow-left mr-2"></i> Volver a la principal
          </Link>
        </div>
        <div className="col-md-4 offset-md-4 text-center">
          <i
            className="fa fa-user-circle text-primary"
            style={{ fontSize: '5rem' }}
          ></i>
        </div>
        <div className="col-md-4 offset-md-4">
          <CreateAccount inOrder={false} pathName={location.pathname} />
        </div>
      </div>
      <Loading />
      <Alert />
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps, { update_user })(Register)
