import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useForm, FormContext } from 'react-hook-form'
import {
  removeOtherOptions,
  AddOrUpdateProductInCart,
  calcPrice,
} from '../helpers'
//Custom components
import AddProductToCartButton from './AddProductToCartButton'
import ProductAttributeOptionControl from './ProductAttributeOptionControl'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

//Redux
import { connect } from 'react-redux'
import {
  update_alert,
  update_cart,
  update_current_currency_client,
} from '../store/actions'
import { Fullscreen } from '@material-ui/icons'

const PublicProductDetail = props => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  //Props
  const { open, closeModal, product, store } = props

  //Redux actions
  const { update_alert, update_cart, update_current_currency_client } = props

  //Redux state
  const { currentCurrencyClient, cart } = props

  //State
  const [fullWidth, setFullWidth] = useState(true)
  const [maxWidth, setMaxWidth] = useState('xs')
  const [urlSelectImage, setUrlSelectImage] = useState('')

  const [additional_options, setAdditionalOptions] = useState([])
  const [additional_price, setAdditionalPrice] = useState(0)
  const [quantity, setQuantity] = useState(1)
  const [textWhatsapp, setTextWhatsapp] = useState('')

  const methods = useForm()

  //Methods
  const selectImage = url => {
    setUrlSelectImage(url)
  }

  const changeQuantity = ({ quantity }) => {
    setQuantity(quantity)
  }

  const addProductCart = async () => {
    const newCart = await AddOrUpdateProductInCart(
      cart,
      {
        ...product,
        additional_options: additional_options,
      },
      quantity,
      store
    )
    setAdditionalOptions([])
    update_cart(newCart)
    setQuantity(1)
    closeModal()
  }

  const changeAttribute = option => {
    //Que hacer cuando sea un select y despues de seleccionar una opcion
    //Vuelva a la opcion seleccionar por si se diera el caso que ya no la quiere
    //Sacar la opcion seleccionada de los additional options para ese select

    //copy additional options for manipulate
    let additional_options_array = JSON.parse(
      JSON.stringify(additional_options)
    )
    //The parent element of the option
    const parent_attribute = product.product_attributes.find(
      i => i.id === option.attribute_id
    )

    //Other options of the parent attribute differents to select
    const other_options = parent_attribute.options.filter(
      i => i.id !== option.id
    )
    //Verify if option exists
    const option_exists = additional_options_array.findIndex(
      i => i.id === option.id
    )

    //Get mode group options is (checkbox, radio, select)
    const mode_options = parent_attribute.mode_options

    //Add additional option to product
    //If checkbox
    if (mode_options === 'checkbox') {
      //If chckbox exists uncheck
      if (option_exists !== -1) {
        additional_options_array.splice(option_exists, 1)
        setAdditionalOptions([...additional_options_array])
      } else {
        setAdditionalOptions([...additional_options_array, option])
      }
      //If radio or select
    } else if (mode_options === 'radio' || mode_options === 'select') {
      //Remove other option selected
      additional_options_array = removeOtherOptions(
        other_options,
        additional_options
      )
      setAdditionalOptions([...additional_options_array, option])
    }
  }

  //UseEffect
  useEffect(() => {
    if (product.images) setUrlSelectImage(product.images[0].url)
    if (product)
      setTextWhatsapp(
        `Buen día me interesa su producto *${product.name}*, más información por favor`
      )
  }, [props.product])

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={closeModal}
      >
        <FormContext {...methods}>
          <form onSubmit={methods.handleSubmit(addProductCart)}>
            <DialogTitle className="p-2 d-flex align-items-center">
              <span>{product?.name}</span>{' '}
              <i
                className="fa fa-times text-muted"
                style={{
                  position: 'absolute',
                  right: '16px',
                  top: '16px',
                  cursor: 'pointer',
                }}
                onClick={closeModal}
              ></i>
            </DialogTitle>
            <DialogContent dividers className="p-0 m-0">
              <div>
                <img
                  className="w-100 h-100"
                  src={product.images ? urlSelectImage : ''}
                  alt="Imagen principal producto"
                />
                {product.images && (
                  <div className="text-left pl-2">
                    {product.images.map(i => {
                      return (
                        <img
                          key={i.url}
                          onClick={() => selectImage(i.url)}
                          src={i.url}
                          style={{
                            width: '50px',
                            height: '50px',
                            marginRight: '5px',
                            marginTop: '5px',
                            cursor: 'pointer',
                            border:
                              urlSelectImage === i.url
                                ? '1px solid gray'
                                : '1px solid #ccc',
                          }}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
              <div className="p-2">
                <p className="p-0 m-0">{product?.description}</p>
              </div>
              {store && (store.show_pricing || !store.catalog_shop) && (
                <div className="p-2">
                  <span>
                    {calcPrice(
                      product?.price,
                      currentCurrencyClient,
                      store.currency,
                      store.exchange_rate
                    )}
                  </span>
                </div>
              )}
              {store && !store.catalog_shop && (
                <div className="p-2">
                  <AddProductToCartButton
                    min={1}
                    callback={changeQuantity}
                    product={product}
                    store={store}
                  />
                </div>
              )}
              {store &&
                store.catalog_shop &&
                (store.phone_one || store.phone_two) && (
                  <div className="pl-2">
                    <p>Teléfonos de contacto:</p>
                    <ul
                      style={{
                        margin: '1rem',
                        listStyle: 'none',
                      }}
                    >
                      {store.phone_one && <li>{store.phone_one}</li>}
                      {store.phone_two && <li>{store.phone_two}</li>}
                    </ul>
                  </div>
                )}

              {/* if have attributes */}
              {product.product_attributes &&
                product.product_attributes.length > 0 && (
                  <div className="mt-4">
                    <p className="font-weight-bold">Opciones</p>
                    <ProductAttributeOptionControl
                      attributes={product.product_attributes}
                      changeAttribute={changeAttribute}
                      store={store}
                    />
                  </div>
                )}
            </DialogContent>
            <DialogActions className="p-2">
              {store && store.catalog_shop ? (
                store.phone_whatsapp && (
                  <a
                    href={`https://wa.me/${
                      store.phone_whatsapp
                    }?text=${encodeURIComponent(textWhatsapp)}`}
                    target="_blank"
                    style={{
                      background: '#4AC959',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                    className="btn"
                  >
                    <i className="fab fa-whatsapp mr-2 font-weight-bold"></i>
                    whatsapp
                  </a>
                )
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  className="public-store-btn-add text-success rounded-pill"
                  type="submit"
                >
                  <i className="fa fa-shopping-cart mr-2"></i> AGREGAR
                </Button>
              )}
            </DialogActions>
          </form>
        </FormContext>
      </Dialog>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  cart: state.cart,
  currentCurrencyClient: state.currentCurrencyClient,
})

export default connect(mapStateToProps, {
  update_alert,
  update_cart,
  update_current_currency_client,
})(PublicProductDetail)
