import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Decimal } from "decimal.js";

//Styles
const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  cursorPointer: {
    cursor: "pointer",
  },
});

const InputQuantity = (props) => {
  const classes = useStyles();

  //State
  const [quantity, setQuantity] = useState(props.quantity);

  //Methods
  const increment = () => {
    let new_quantity = Decimal.add(quantity, 1);
    setQuantity(new_quantity);
    props.changeQuantity(new_quantity);
  };

  const decrement = () => {
    let new_quantity = new Decimal(quantity).minus(1);
    if (new_quantity.lte(0)) {
      new_quantity = 1;
    }
    setQuantity(new_quantity);
    props.changeQuantity(new_quantity);
  };

  return (
    <span className={classes.root}>
      <span
        className={
          "fa fa-minus text-danger d-flex align-items-center " +
          classes.cursorPointer
        }
        onClick={decrement}
      ></span>

      <span className="font-weight-bold pr-2 pl-2 d-flex align-items-center h5">
        {quantity.toString()}
      </span>

      <span
        className={
          "fa fa-plus text-success d-flex align-items-center " +
          classes.cursorPointer
        }
        onClick={increment}
      ></span>
    </span>
  );
};

export default InputQuantity;
