import React, { useEffect, useState } from 'react'
import '../../node_modules/leaflet-draw/dist/leaflet.draw.css'
import { Map, TileLayer, FeatureGroup, Polygon } from 'react-leaflet'

import './hola.js'

import { EditControl } from 'react-leaflet-draw'

import { getMessageError, compare, currencyFormat } from '../helpers'

import { connect } from 'react-redux'
import { update_alert } from '../store/actions'

import StoreService from '../services/store.service'

//Validate input con hook form
import { useForm } from 'react-hook-form'

import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Button,
} from '@material-ui/core'

const DrawPolygonAreaMap = props => {
  //Props
  const { store_id } = props

  //Redux
  const { update_alert } = props

  //State
  const [coordinatesArea, setCoordinatesArea] = useState([])
  const [distancePriceDeliveries, setDistancePriceDeliveries] = useState([])

  const [keyUpdateMap, setKeyUpdateMap] = useState(Date.now())
  const [radio, setRadio] = useState('delivery_own')
  const [distancePrice, setDistancePrice] = useState({
    start_distance: '',
    end_distance: '',
    price: '',
  })
  const [store, setStore] = useState(null)

  //validación por useForm
  const { register, handleSubmit, setValue, errors, unregister } = useForm()

  //Methods
  const handleChangeRadio = ev => {
    saveModeDelivery(ev.currentTarget.value)
  }

  const handleChangeInput = ev => {
    setDistancePrice({
      ...distancePrice,
      [ev.currentTarget.name]: ev.currentTarget.value,
    })
  }
  const getStoreCoordinatesDeliveryArea = async () => {
    try {
      const { data: res } = await StoreService.getStoreCoordinatesDeliveryArea(
        store_id
      )

      setCoordinatesArea(res.data)
      setKeyUpdateMap(Date.now())

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //Get store by id
  const getStore = async () => {
    try {
      const { data: res } = await StoreService.getStoreById(store_id)
      if (res.success) {
        setStore(res.data)
        setRadio(
          res.data.use_my_own_delivery ? 'delivery_own' : 'delivery_company'
        )
        setDistancePriceDeliveries(
          res.data.distance_price_deliveries
            ? JSON.parse(res.data.distance_price_deliveries)
            : []
        )
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  const saveStoreCoordinatesDeliveryArea = async data => {
    console.log(data)
    try {
      const { data: res } = await StoreService.saveStoreCoordinatesDeliveryArea(
        data
      )
      setCoordinatesArea(() => res.data)

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
      setKeyUpdateMap(Date.now())
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  const saveModeDelivery = async store_mode_delivery => {
    console.log(store_mode_delivery)
    try {
      const { data: res } = await StoreService.saveModeDelivery({
        store_id: store_id,
        use_my_own_delivery: store_mode_delivery === 'delivery_own' ? 1 : 0,
      })

      setRadio(store_mode_delivery)

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  const saveStoreDistancePriceDeliveries = async () => {
    try {
      let new_start_distance = 0
      const before_distance_price =
        distancePriceDeliveries.length > 0
          ? distancePriceDeliveries[distancePriceDeliveries.length - 1]
          : false
      console.log(before_distance_price)
      if (before_distance_price) {
        new_start_distance = parseFloat(before_distance_price.end_distance)
        if (parseFloat(distancePrice.end_distance) <= new_start_distance) {
          update_alert({
            open: true,
            severity: 'error',
            message: 'La distancia debe ser mayor que la distancia inicial',
          })
          return
        }
        if (
          parseFloat(distancePrice.price) <=
          parseFloat(before_distance_price.price)
        ) {
          update_alert({
            open: true,
            severity: 'error',
            message:
              'El precio debe ser mayor al precio de la ultima distancia registrada',
          })
          return
        }
      }
      const { data: res } = await StoreService.saveStoreDistancePriceDeliveries(
        {
          ...distancePrice,
          start_distance: new_start_distance,
          store_id: store_id,
          action: 'create',
        }
      )
      //console.log(res.data)
      setDistancePriceDeliveries(res.data)
      setDistancePrice({
        start_distance: '',
        end_distance: '',
        price: '',
      })
      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  const onEdited = ev => {
    console.log('edit')
    let editPolygonIds = []
    Object.entries(ev.layers._layers).forEach(([key, value]) => {
      editPolygonIds.push({
        polygon_id: value.options.polygon_id,
        coordinates: value._latlngs[0],
      })
    })

    const newCoordinates = JSON.parse(JSON.stringify(coordinatesArea))
    if (newCoordinates.length > 0) {
      editPolygonIds.forEach(edit_polygon => {
        let editPolygonIndex = newCoordinates.findIndex(
          i => i.polygon_id === edit_polygon.polygon_id
        )
        newCoordinates[editPolygonIndex] = edit_polygon
      })

      saveStoreCoordinatesDeliveryArea({
        store_id: store_id,
        coordinates_delivery_area: newCoordinates,
      })
    }
  }
  const onCreated = ev => {
    console.log('create')
    const id =
      coordinatesArea && coordinatesArea.length > 0
        ? parseInt(
            Math.max.apply(
              Math,
              JSON.parse(JSON.stringify(coordinatesArea)).map(function (o) {
                return parseInt(o.polygon_id)
              })
            )
          ) + 1
        : 1
    ev.layer.options.polygon_id = id

    const newCoordinate = {
      polygon_id: id,
      coordinates: ev.layer.getLatLngs()[0],
    }

    const newCoordinates = coordinatesArea
      ? [...coordinatesArea, newCoordinate]
      : [newCoordinate]

    saveStoreCoordinatesDeliveryArea({
      store_id: store_id,
      coordinates_delivery_area: newCoordinates,
    })

    //setKeyUpdateMap(Date.now())
  }
  const onDeleted = ev => {
    let deletePolygonIds = []
    Object.entries(ev.layers._layers).forEach(([key, value]) => {
      deletePolygonIds.push(value.options.polygon_id)
    })

    const coordinates = coordinatesArea.filter(
      i => !deletePolygonIds.includes(i.polygon_id)
    )
    saveStoreCoordinatesDeliveryArea({
      store_id: store_id,
      coordinates_delivery_area: coordinates,
    })

    //setKeyUpdateMap(Date.now())
  }
  // const onMounted = ({ _map }) => {
  //   console.log('mounted')
  //   console.log(_map._layers)
  // }

  //UseEffect
  useEffect(() => {
    if (store_id) {
      getStoreCoordinatesDeliveryArea()
      getStore()
    }
  }, [store_id])

  return (
    <>
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Modalida de delivery a implementar {radio}
          </FormLabel>
          <RadioGroup
            aria-label="Modalida de delivery a implementar"
            name="delivery"
            value={radio}
            onChange={handleChangeRadio}
          >
            <FormControlLabel
              value="delivery_own"
              control={<Radio />}
              label="Mi propio delivery"
            />
            <FormControlLabel
              value="delivery_company"
              control={<Radio />}
              label="Compañia de delivery"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div>
        <h5>
          Delimita las áreas en el mapa donde cubriras el servicio de delivery
        </h5>
        <Map
          center={[8.630194677878427, -70.21493268133581]}
          zoom={16}
          zoomControl={false}
          style={{ width: '100%', height: '500px' }}
          key={keyUpdateMap}
        >
          <TileLayer
            attribution="womve"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <FeatureGroup>
            <EditControl
              position="topright"
              onCreated={onCreated}
              onEdited={onEdited}
              onDeleted={onDeleted}
              //onMounted={onMounted}
              draw={{
                rectangle: false,
                polyline: false,
                circle: false,
                marker: false,
                circlemarker: false,
              }}
            />
            {coordinatesArea &&
              coordinatesArea.length > 0 &&
              coordinatesArea.map(ca => {
                return (
                  <Polygon
                    polygon_id={ca.polygon_id}
                    key={ca.polygon_id}
                    positions={ca.coordinates}
                  />
                )
              })}
          </FeatureGroup>
        </Map>
      </div>

      <div className="py-5">
        <h5>
          Agrega los precios de los deliveries en base a distancias en
          kilometros
        </h5>
        <div>
          <ul>
            {store &&
              distancePriceDeliveries.length > 0 &&
              distancePriceDeliveries
                .sort(compare('end_distance', true))
                .map(dpd => {
                  return (
                    <li className="p-1">
                      <h6>
                        {dpd.start_distance} km a {dpd.end_distance} km ={' '}
                        {currencyFormat(dpd.price, store.currency_store_symbol)}
                      </h6>
                    </li>
                  )
                })}
          </ul>
        </div>
        <div className="d-flex align-items-center">
          <form onSubmit={handleSubmit(saveStoreDistancePriceDeliveries)}>
            {store && distancePriceDeliveries.length > 0 && (
              <span style={{ fontSize: '20px', marginRight: '16px' }}>
                {
                  distancePriceDeliveries.sort(compare('end_distance', true))[
                    distancePriceDeliveries.length - 1
                  ].end_distance
                }
              </span>
            )}{' '}
            {store && !distancePriceDeliveries.length > 0 && (
              <span style={{ fontSize: '20px', marginRight: '16px' }}>0</span>
            )}
            <span style={{ fontSize: '20px', marginRight: '16px' }}>a</span>
            <TextField
              inputRef={register({ required: true })}
              className="mx-2"
              style={{ width: '100px' }}
              label="Km"
              variant="outlined"
              name="end_distance"
              value={distancePrice.end_distance}
              error={!!errors.distance}
              helperText={errors.distance && 'El campo es requerido'}
              onChange={handleChangeInput}
            />
            <TextField
              inputRef={register({ required: true })}
              className="mx-2"
              label="Precio delivery"
              variant="outlined"
              name="price"
              value={distancePrice.price}
              error={!!errors.price}
              helperText={errors.price && 'El campo es requerido'}
              onChange={handleChangeInput}
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
            >
              <i className="fa fa-save mr-2"></i>
              Agregar
            </Button>
          </form>
        </div>
      </div>
    </>
  )
}

export default connect(null, { update_alert })(DrawPolygonAreaMap)
