import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PublicService from '../../../services/public.service'
import './DetailProduct.scss'
import ProductAttributeOptionControl from '../../../components/ProductAttributeOptionControl'
import {
  removeOtherOptions,
  recusiveRemoveDependentAttributes,
  currencyFormat,
  getMessageError,
} from '../../../helpers'
import { useForm, FormContext } from 'react-hook-form'
import { update_loading, update_alert } from '../../../store/actions'
import { connect } from 'react-redux'
import Modal from '../../../components/Modal'
import LoginComponent from '../../../components/Login'
import { useHistory } from 'react-router-dom'

const DetailProduct = props => {
  const history = useHistory()

  //Redux
  const { update_alert, update_loading, user } = props

  //Params
  const { id } = useParams()

  //State
  const [product, setProduct] = useState({
    name: '',
    price: 0,
    description: '',
    images: [],
    attributes: [],
  })
  const [additional_options, setAdditionalOptions] = useState([])
  const [additional_price, setAdditionalPrice] = useState(0)
  const [current_image_url, setCurrentImageUrl] = useState('')
  const methods = useForm()
  const [open, setOpen] = useState(false)

  //Methods
  const getProduct = async () => {
    try {
      const { data: res } = await PublicService.getProduct(id)
      setProduct(res.data)
      setCurrentImageUrl(res.data.first_image)
    } catch (error) {}
  }

  const selectImage = url => setCurrentImageUrl(url)

  const changeAttribute = option => {
    //Que hacer cuando sea un select y despues de seleccionar una opcion
    //Vuelva a la opcion seleccionar por si se diera el caso que ya no la quiere
    //Sacar la opcion seleccionada de los additional options para ese select

    //copy additional options for manipulate
    let additional_options_array = JSON.parse(
      JSON.stringify(additional_options)
    )
    //The parent element of the option
    const parent_attribute = product.attributes.find(
      i => i.id === option.attribute_id
    )

    //Other options of the parent attribute differents to select
    const other_options = parent_attribute.options.filter(
      i => i.id !== option.id
    )

    const option_exists = additional_options_array.findIndex(
      i => i.id === option.id
    )

    const mode_options = parent_attribute.mode_options

    //If Mode options is radio remove other options
    if (
      mode_options === 'radio' ||
      mode_options === 'select' ||
      (mode_options === 'checkbox' && option_exists !== -1)
    ) {
      if (mode_options === 'radio' || mode_options === 'select') {
        additional_options_array = removeOtherOptions(
          other_options,
          additional_options,
          product.attributes
        )
      }

      if (parent_attribute.mode_options === 'checkbox') {
        additional_options_array = recusiveRemoveDependentAttributes(
          option.dependent_attributes,
          product.attributes,
          additional_options_array
        )
      }
    }

    //If selected option has dependent attributes those activate
    if (
      mode_options === 'radio' ||
      mode_options === 'select' ||
      (mode_options === 'checkbox' && option_exists === -1)
    ) {
      if (option.dependent_attributes.length) {
        option.dependent_attributes.forEach(d => {
          const index = product.attributes.findIndex(
            a => a.id === d.attribute_id
          )
          let attribute = product.attributes[index]
          attribute.dependent_attribute = false
        })
      }
    }

    setProduct({
      ...product,
      attributes: [...product.attributes],
    })

    //Add additional option to product
    //If checkbox
    if (mode_options === 'checkbox') {
      //If chckbox exists uncheck
      if (option_exists !== -1) {
        additional_options_array.splice(option_exists, 1)
        setAdditionalOptions([...additional_options_array])
      } else {
        setAdditionalOptions([...additional_options_array, option])
      }
    } else if (mode_options === 'radio' || mode_options === 'select') {
      setAdditionalOptions([...additional_options_array, option])
    } else if (mode_options === 'file') {
      option.is_file = option.file ? true : false
      option.file = option.file ? option.file : null
      setAdditionalOptions([...additional_options_array, option])
    }
  }

  //Add product to cart
  const addProductCart = async () => {
    try {
      update_loading({
        show: true,
        message: 'Agregando al carrito',
      })

      const form_data = new FormData()
      let clear_options = []

      //For each additional option
      additional_options.forEach(i => {
        //Make options object if user session or no
        let option = {
          id: i.id,
          name: i.name,
          is_file: i.file ? true : false,
          file: i.file ? i.file : null,
        }

        //Add file to form data to send
        if (i.is_file) {
          form_data.append(`files[${i.id}]`, i.file)
        }

        //add only data to send
        clear_options.push(option)
      })

      form_data.append('additional_options', JSON.stringify(clear_options))
      form_data.append('product_id', product.id)
      form_data.append('quantity', 1)

      const { data: res } = await PublicService.addProductCart(form_data)

      if (res.success) {
        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })

        history.push('/cart')
      } else {
        update_alert({
          open: true,
          severity: 'warning',
          message: res.message,
        })
      }
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
    update_loading({
      show: false,
    })
  }

  //Open modal
  const openModal = () => {
    setOpen(true)
  }

  //Close modal
  const closeModal = () => {
    setOpen(false)
  }

  useEffect(() => {
    getProduct()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAdditionalPrice(
      additional_options.reduce(function (prev, cur) {
        return parseInt(prev) + parseInt(cur.price)
      }, 0)
    )
  }, [additional_options]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="row p-5">
        <div className="col-md-5 offset-md-1">
          <img
            src={current_image_url}
            className="main-img-detail"
            alt="product"
          />
          <div>
            {product.images.length > 0 &&
              product.images.map(i => {
                return (
                  <img
                    className={
                      'mini-img-detail ' +
                      (current_image_url === i.url ? 'mini-img-active' : '')
                    }
                    onClick={() => selectImage(i.url)}
                    src={i.url}
                    key={i.url}
                    alt="imagen producto"
                  />
                )
              })}
          </div>
        </div>
        <div className="col-md-5 offset-md-1 p-5">
          <FormContext {...methods}>
            <form onSubmit={methods.handleSubmit(addProductCart)}>
              <h1 className="pb-1">{product.name}</h1>
              <h2 className="text-success pb-1">
                {currencyFormat(product.price)}
              </h2>
              {additional_price > 0 && (
                <>
                  <h4 className="text-muted pl-5 mb-3">
                    Adiciones: {currencyFormat(additional_price)}
                  </h4>
                  <h4 className="pl-5 mb-3">
                    Total:{' '}
                    {currencyFormat(
                      parseFloat(product.price) + additional_price
                    )}
                  </h4>
                </>
              )}

              {/* Mmostrar atributos si los hay aqui */}
              <ProductAttributeOptionControl
                attributes={product.attributes}
                changeAttribute={changeAttribute}
              />
              {user && (
                <button className="btn btn-success btn-lg mt-2">
                  Agregar al carrito
                </button>
              )}
            </form>
          </FormContext>

          {!user && (
            <button className="btn btn-success btn-lg mt-2" onClick={openModal}>
              Agregar al carrito
            </button>
          )}
        </div>
      </div>
      <div className="row pl-5">
        <div className="col-md-5 offset-md-1">{product.description}</div>
      </div>

      <Modal open={open} title="Inicia sesión" closeModal={closeModal}>
        <LoginComponent callback={addProductCart} />
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps, {
  update_alert,
  update_loading,
})(DetailProduct)
