import React, { useState, useEffect } from 'react'
import { Fade, Tooltip, Button } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { getValidationRules } from '../helpers'

const FileControls = props => {
  const [options, setOptions] = useState(props.options)

  //Validate useForm
  const { register, errors } = useFormContext()

  useEffect(() => {
    if (props.clearOptions) {
      const reset_options = options.map(o => {
        let { file, ...rest } = o
        return rest
      })
      setOptions(reset_options)
    }
  }, [props.clearOptions]) // eslint-disable-line react-hooks/exhaustive-deps

  //Listen to register validation for appy the rules each input
  useEffect(() => {
    props.options.forEach(option => {
      register({ name: `option_${option.id}` }, getValidationRules(option))
    })
  }, [register]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectFile = option => event => {
    option.file = event.target.files[0]
    props.callback(option)
  }
  return (
    <>
      {options.map(o => {
        return (
          <li key={o.id}>
            <label className="label-option m-2">
              <Button variant="contained" color="primary" component="label">
                <i className="fa fa-upload mr-2"></i>
                {o.name}
                <input
                  name={`option_${o.id}`}
                  ref={register}
                  onChange={selectFile(o)}
                  type="file"
                  style={{ display: 'none' }}
                />
              </Button>
              {o.help_text && (
                <Tooltip
                  placement="top"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 200 }}
                  title={o.help_text}
                  arrow
                >
                  <i className="fa fa-info-circle ml-1 text-info"></i>
                </Tooltip>
              )}

              {o.file && (
                <div className="p-1">
                  <span>Archivo a subir: </span>
                  <span className="text-danger">{o.file.name}</span>
                </div>
              )}
            </label>

            {!!errors[`option_${o.id}`] && (
              <p className="text-danger text-small">
                {errors[`option_${o.id}`].message}
              </p>
            )}
          </li>
        )
      })}
    </>
  )
}

export default FileControls
