import React, { useState } from 'react'
import { connect } from 'react-redux'
import { update_alert, update_loading } from '../store/actions'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import UtilityService from '../services/utility.service'
import Alert from './Alert'
import Loading from './Loading'
import { getMessageError } from '../helpers'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import { useEffect } from 'react'

const ShippingAddress = props => {
  const { update_alert, update_loading, callback } = props

  const [address, setAddress] = useState({
    name: '',
    address: '',
    latitude: '',
    longitude: '',
  })

  const [position, setPosition] = useState([8.61148, -70.234431])

  //validación por useForm
  const { register, handleSubmit, errors } = useForm()

  //Inputs controlados
  const handleInputChange = e =>
    setAddress({
      ...address,
      [e.currentTarget.name]: e.currentTarget.value,
    })

  const saveAddress = async () => {
    try {
      update_loading({
        show: true,
        message: 'Registrando nueva dirección',
      })
      const { data: res } = await UtilityService.saveShippingAddress(address)

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })

      callback(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
  }

  const markPositionClick = ({ latlng }) => {
    setPosition([latlng.lat, latlng.lng])
  }

  const markPositionDrag = ({ target }) => {
    setPosition([target._latlng.lat, target._latlng.lng])
  }

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition)
    } else {
      alert('No soporta localización')
    }
  }

  const showPosition = position => {
    setPosition([position.coords.latitude, position.coords.longitude])
  }

  //useEffect
  useEffect(() => {
    getLocation()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAddress({ ...address, latitude: position[0], longitude: position[1] })
    console.log('aquiiiiiiiiiiiiiiii')
  }, [position]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="w-100">
        <div>
          <Loading />
        </div>
        <div className="card p-3 w-100">
          <form onSubmit={handleSubmit(saveAddress)}>
            <div className="text-center text-muted">
              <Typography variant="h5">Nueva dirección</Typography>
            </div>

            <div className="mt-3">
              <TextField
                inputRef={register({ required: true })}
                fullWidth
                variant="outlined"
                label="Nombre Dirección"
                name="name"
                value={address.name}
                onChange={handleInputChange}
                error={!!errors.name}
                helperText={errors.name && 'El campo nombre es requerido'}
                placeholder="Ej. Mi Casa"
              />
            </div>

            <div className="mt-3">
              <TextField
                inputRef={register({ required: true })}
                fullWidth
                variant="outlined"
                label="Dirección"
                name="address"
                value={address.address}
                onChange={handleInputChange}
                error={!!errors.address}
                helperText={errors.address && 'El campo dirección es requerido'}
                placeholder="Ej. urbanización Don samuel calle 1"
                multiline
              />
            </div>

            <div className="mt-3">
              <h5 className="text-center text-muted">Elige tu ubicación</h5>
              <Map
                center={position}
                zoom={16}
                style={{ width: '100%', height: '300px' }}
                onClick={markPositionClick}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution="jose"
                />
                <Marker
                  position={position}
                  draggable={true}
                  onDragend={markPositionDrag}
                >
                  <Popup>A esta ubicación llegaran tus pedidos</Popup>
                </Marker>
              </Map>

              {!address.latitude && !address.longitude && (
                <p className="danger">
                  Debes elegir una ubicación a la cual enviar tus pedidos
                </p>
              )}
            </div>

            <div className="text-center mt-3">
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
              >
                <i className="fa fa-sign-in-alt"></i>
                Registrar
              </Button>
            </div>
          </form>
        </div>

        <Alert />
      </div>
    </>
  )
}

export default connect(null, { update_alert, update_loading })(ShippingAddress)
