import React, { useEffect, useState } from 'react'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet'

import { geolocated } from 'react-geolocated'
import mapMarkerDefault from './mapMarkerDefault.png'
import markerStoreDefault from './markerStoreDefault.png'
import { getDistance } from 'geolib'
const MarkPositionInMap = props => {
  //props
  const { cart, isPointInArea } = props
  //State
  const [position, setPosition] = useState(null)
  const [positionStore, setPositionStore] = useState(null)

  //Methods
  const markPositionDrag = ({ target }) => {
    if (target.options.typeId === 'user') {
      setPosition([target._latlng.lat, target._latlng.lng])
    } else {
      if (cart) setPositionStore([cart.latitude, cart.longitude])
    }
  }

  const markerUser = L.icon({
    iconUrl: mapMarkerDefault,
    iconSize: [30, 30], // size of the icon
    popupAnchor: [0, -15], // point from which the popup should open relative to the iconAnchor
  })

  const markerStore = L.icon({
    iconUrl: markerStoreDefault,
    iconSize: [30, 30], // size of the icon
    popupAnchor: [0, -15], // point from which the popup should open relative to the iconAnchor
  })

  useEffect(() => {
    if (props.coords) {
      //isPointInArea(props.coords.latitude, props.coords.longitude)
      setPosition([props.coords.latitude, props.coords.longitude])
    } else {
      if (cart) {
        setPosition([cart.latitude, cart.longitude])
      }
    }

    if (cart) {
      setPositionStore([cart.latitude, cart.longitude])
    }
  }, [props.coords])

  useEffect(() => {
    if (position && positionStore)
      console.log(
        'distancia: ',
        getDistance(
          {
            latitude: position[0],
            longitude: position[1],
          },
          {
            latitude: positionStore[0],
            longitude: positionStore[1],
          }
        )
      )
  }, [position, positionStore])

  useEffect(() => {
    if (position) isPointInArea(position[0], position[1])
  }, [position])

  return (
    <>
      {!props.isGeolocationAvailable ? (
        <div>Tu navegador no soporte geolocalización</div>
      ) : !props.isGeolocationEnabled ? (
        <div>
          Por favor habilita la geolocalización para ayudarte con tu delivery
        </div>
      ) : props.coords ? (
        <span></span>
      ) : (
        <div>
          Obteniendo tu localización permitenos ubicarte para manejar tu
          delivery{' '}
        </div>
      )}

      <Map
        center={position}
        zoom={16}
        style={{ width: '100%', height: '300px' }}
        // onClick={markPositionClick}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="womve"
        />

        {cart && (
          <Marker
            typeId="store"
            position={positionStore}
            draggable={true}
            onDragend={markPositionDrag}
            icon={markerStore}
          >
            <Popup>{cart.store_name}</Popup>
          </Marker>
        )}

        <Marker
          typeId="user"
          position={position}
          draggable={true}
          onDragend={markPositionDrag}
          icon={markerUser}
        >
          <Popup>
            Esta ubicación sera usada
            <br /> para enviar tu pedido
          </Popup>
        </Marker>
      </Map>
    </>
  )
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(MarkPositionInMap)
