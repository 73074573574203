export const update_user = payload => {
  return {
    type: 'UPDATE_USER',
    payload,
  }
}

export const update_alert = payload => ({
  type: 'UPDATE_ALERT',
  payload,
})

export const update_loading = payload => ({
  type: 'UPDATE_LOADING',
  payload,
})

export const update_store_info = payload => ({
  type: 'UPDATE_STORE_INFO',
  payload,
})

export const update_cart = payload => ({
  type: 'UPDATE_CART',
  payload,
})

export const update_show_complete_order_modal = payload => ({
  type: 'UPDATE_SHOW_COMPLETE_ORDER_MODAL',
  payload,
})

export const update_show_status_order_modal = payload => ({
  type: 'UPDATE_SHOW_STATUS_ORDER_MODAL',
  payload,
})

export const update_current_currency_client = payload => ({
  type: 'UPDATE_CURRENT_CURRENCY_CLIENT',
  payload,
})
