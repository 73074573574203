import React, { useState, useEffect } from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
  DialogContent,
  DialogActions,
} from '@material-ui/core'

import Modal from './Modal'

//Redux
import { connect } from 'react-redux'
import { update_alert } from '../store/actions'

//Validate input con hook form
import { useForm } from 'react-hook-form'
import UtilityService from '../services/utility.service'
import StoreService from '../services/store.service'

//Helpers
import { getMessageError } from '../helpers'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  addPaymentMethodBtn: {
    background: '#E6E6E6',
    cursor: 'pointer',
    '&:hover': {
      background: '#F9F9F9',
      color: '#52CA8B !important',
      fontWeight: 'bold',
    },
  },
})

const ApprovePaymentModal = props => {
  const classes = useStyles()

  //Redux actions
  const { update_alert } = props

  //Redux state
  const { storeInfo } = props

  //Props
  const { open, handleClose, order, payment } = props

  //STATE
  const [availableDistributors, setAvailableDistributors] = useState([
    {
      id: 0,
      distributor: {
        id: 'auto',
        name: 'Se asignara automaticamente',
      },
    },
  ])
  const [distributor, setDistributor] = useState(
    availableDistributors[0].distributor
  )

  const [storeUseDeliveryOwn, setStoreUseDeliveryOwn] = useState(false)

  //validate form
  const { register, handleSubmit, errors, setValue } = useForm()

  //Methods
  const changeSelectDistributor = ev => {
    console.log(ev.target)
    setDistributor(ev.target.value)
    setValue('distributor', ev.target.value)
  }

  const getStore = async store_id => {
    try {
      const { data: res } = await StoreService.getStoreById(store_id)
      console.log('aquiiiii', res.data)
      setStoreUseDeliveryOwn(res.data.use_my_own_delivery)
    } catch (error) {
      console.log(error)
    }
  }

  //Get available distributors order is delivery
  const getAvailableDistributors = async () => {
    try {
      const { data: res } = await StoreService.getAvailableDistributors(
        storeInfo.id
      )
      console.log(res.data)
      setAvailableDistributors([...availableDistributors, ...res.data])
      setValue('distributor', availableDistributors[0].distributor)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //Save
  const save = async () => {
    try {
      console.log({
        store_id: storeInfo.id,
        payment_id: payment.id,
        distributor_id: distributor.id,
        order_id: order.id,
        storeInfo: storeInfo,
      })
      //return

      const { data: res } = await UtilityService.saveStatusOrder({
        store_id: storeInfo.id,
        payment_id: payment.id,
        distributor_id: distributor.id,
        order_id: order.id,
        status: 2,
        type: 'store',
      })

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
      handleClose(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //close modal
  const closeModal = () => {
    handleClose()
  }

  //UseEffect
  useEffect(() => {
    if (storeInfo && order) {
      if (order.type == 1) {
        getStore(storeInfo.id)
        getAvailableDistributors()
      }
    }
  }, [storeInfo, order])

  return (
    <>
      <Modal
        open={open}
        zIndex={1}
        title="Aprobar pago"
        closeModal={closeModal}
        maxWidth="sm"
      >
        <form onSubmit={handleSubmit(save)}>
          <DialogContent className="pt-0">
            <p className="p-0">
              Confirmaras que has recibido el pago del cliente, y se marcara la
              orden con el siguiente estado
            </p>
            {order.type == 1 &&
              storeUseDeliveryOwn &&
              availableDistributors.length > 0 && (
                <div>
                  <FormControl
                    variant="outlined"
                    className="w-100"
                    error={!!errors.distributor}
                  >
                    <InputLabel id="select_distributor">
                      Asignar repartidor para el delivery
                    </InputLabel>
                    <Select
                      label="Asignar repartidor para el delivery"
                      labelId="select_distributor"
                      id="select_distributor"
                      className="w-100"
                      value={distributor}
                      onChange={changeSelectDistributor}
                      inputRef={register}
                      name="distributor"
                    >
                      <MenuItem value="" disabled>
                        Seleccionar
                      </MenuItem>
                      {availableDistributors.map(dis => {
                        return (
                          <MenuItem key={dis.id} value={dis.distributor}>
                            {dis.distributor.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    {!!errors.distributor && (
                      <FormHelperText className="text-danger">
                        {errors.distributor.message}
                      </FormHelperText>
                    )}

                    <small className="text-default text-justify p-1">
                      <span className="text-info">Nota:</span> Puedes elegir un
                      repartidor manualmente para el delivery de la orden, si
                      deseas el sistema lo asigne automaticamente deja la opción
                      marcada como "Se asignara automaticamente".
                    </small>
                  </FormControl>
                </div>
              )}
            <div></div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary">
              <i className="fa fa-check mr-3"></i>
              Aprobar
            </Button>
          </DialogActions>
        </form>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  storeInfo: state.storeInfo,
})

export default connect(mapStateToProps, { update_alert })(ApprovePaymentModal)
