import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

//Stepper
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'

//Redux
import { connect } from 'react-redux'
import { update_show_status_order_modal } from '../store/actions'

//Components
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import moment from 'moment'

//Services
import PublicService from '../services/public.service'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PublicCompleteOrder = props => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { open, closeUserOrdersModal } = props

  //Redux
  const { update_show_status_order_modal } = props

  //State
  const [orders, setOrders] = useState([])
  //Methods
  const getOrders = async () => {
    try {
      const { data: res } = await PublicService.userOrders()
      if (res.success) {
        setOrders(res.data.data)
      }
    } catch (error) {}
  }

  //open status order
  const openStatusOrder = data => {
    update_show_status_order_modal({
      ...data,
      open: true,
    })
  }

  useEffect(() => {
    getOrders()
  }, [])

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        onClose={() => closeUserOrdersModal()}
        className="p-0 m-0"
        scroll={'paper'}
        TransitionComponent={Transition}
      >
        <DialogTitle className="d-flex align-items-center justify-content-between p-2 w-100 position-relative">
          Tus ordenes en curso
          <i
            className="fa fa-times text-muted"
            style={{
              position: 'absolute',
              right: '16px',
              top: '16px',
              cursor: 'pointer',
            }}
            onClick={closeUserOrdersModal}
          ></i>
        </DialogTitle>
        <DialogContent dividers={true} className="p-1 pb-5">
          {orders.length > 0 &&
            orders.map(o => (
              <div className="card w-100 position-relative p-2">
                <h4>{o.store.name}</h4>
                <p className="p-0 m-0">
                  <i className="fa fa-calendar mr-2 text-muted"></i>
                  {moment(o.created_at).format('DD-MM-YYYY HH:mm A')}
                </p>
                <p className="p-0 m-0">Total: {o.total}</p>
                <span>Estado actual: {JSON.parse(o.status).name}</span>
                <div className="mt-1">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      openStatusOrder({
                        order_id: o.id,
                        store_id: o.store_id,
                      })
                    }
                  >
                    <i className="fa fa-eye mr-2"></i>
                    Detalle
                  </Button>
                </div>
              </div>
            ))}
          {orders.length === 0 && <p>No tienes ordenes en curso</p>}
        </DialogContent>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user,
})
export default connect(mapStateToProps, { update_show_status_order_modal })(
  PublicCompleteOrder
)
