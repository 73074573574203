import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './globalStyles.scss'
import { connect } from 'react-redux'
// eslint-disable-next-line
import $ from 'jquery'
// eslint-disable-next-line
import Popper from 'popper.js'
import { update_user, update_cart } from '../store/actions'
import AuthService from '../services/auth.service'
import CartService from '../services/cart.service'
import Alert from '../components/Alert'
import Loading from '../components/Loading'
import logoWhite from './logo_white.png'

import PublicStatusOrderModal from '../components/PublicStatusOrderModal'
import DropdownMenuClient from '../components/DropdownMenuClient'
import PublicMenuDrawer from '../components/PublicMenuDrawer'

import { Link as LinkScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'

import { List, ListItem, ListSubheader, Divider } from '@material-ui/core'

const PublicLayout = ({ children, user, update_user, update_cart }) => {
  //Router location
  const location = useLocation()

  //State
  const [openMenuDrawer, setOpenMenuDrawer] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('token')) {
      getUser()
      getCart()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getUser() {
    try {
      const { data: res } = await AuthService.whoami()
      if (res.success) {
        update_user(res.user)
      } else {
      }
    } catch (error) {}
  }

  async function getCart() {
    try {
      const { data: res } = await CartService.get()
      if (res.success) {
        console.log(res.data)
        update_cart(res.data)
      }
    } catch (error) {
      alert('hola')
    }
  }

  const handleScroll = () => {
    if (window.scrollY > 460) {
      document.querySelector('.navbar').classList.add('scroll-stinky')
      document.querySelector('.navbar').classList.add('navbar-brand-stinky')
    } else {
      document.querySelector('.navbar').classList.remove('scroll-stinky')
      document.querySelector('.navbar').classList.remove('navbar-brand-stinky')
    }
  }

  const closeMenuDrawer = () => {
    setOpenMenuDrawer(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <nav
        className="navbar navbar-expand-lg fixed-top"
        style={{ background: 'transparent' }}
      >
        <Link className="navbar-brand" to="/">
          <img src={logoWhite} alt="logo" />
        </Link>

        <span className="d-flex align-items-center">
          <span
            className="mt-2"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setOpenMenuDrawer(true)}
          >
            <span className="navbar-toggler-icon fa fa-bars"></span>
          </span>

          <DropdownMenuClient />
        </span>

        <div
          className="collapse navbar-collapse right-menu"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            {location.pathname === '/' && (
              <LinkScroll
                offset={-60}
                className="link-scroll-womve"
                smooth={true}
                to="inicio"
              >
                Inicio
              </LinkScroll>
            )}
            {location.pathname !== '/' && <Link to="/">Inicio</Link>}

            {location.pathname === '/' && (
              <>
                <LinkScroll
                  offset={-60}
                  className="link-scroll-womve"
                  smooth={true}
                  to="benefits"
                >
                  Beneficios
                </LinkScroll>
                <LinkScroll
                  offset={-60}
                  className="link-scroll-womve"
                  smooth={true}
                  to="about"
                >
                  Nosotros
                </LinkScroll>
                <LinkScroll
                  offset={-60}
                  className="link-scroll-womve"
                  smooth={true}
                  to="pricing"
                >
                  Precios
                </LinkScroll>

                <LinkScroll
                  offset={-60}
                  className="link-scroll-womve"
                  smooth={true}
                  to="contract"
                >
                  Registrar mi negocio
                </LinkScroll>

                <Link to="/markeplace">Marketplace</Link>
              </>
            )}

            {user && (
              <>
                <DropdownMenuClient />
              </>
            )}
            {!user && (
              <>
                <Link to="/login">Iniciar sesión</Link>
                <Link to="/register">Registrate</Link>
              </>
            )}
          </div>
        </div>
      </nav>

      <main style={{ marginBottom: '200px' }}>
        <Loading />
        {children}
        <Alert />
      </main>

      {/* footer */}
      <div className="footer">
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          fill="#348c84"
          width="100%"
          height="80"
          viewBox="0 0 4 0.266661"
          preserveAspectRatio="none"
          style={{ height: '100px', marginTop: '200px' }}
        >
          <polygon
            className="fil0"
            points="4,0 4,0.266661 -0,0.266661 "
          ></polygon>
        </svg> */}

        <footer>
          <div
            className="row text-center text-light"
            style={{ background: '#348c84' }}
          >
            <div className="col-md border-right">
              <h4>Redes</h4>
              <ul>
                <li>
                  <i className="fab fa-instagram"></i> Instagram
                </li>
                <li>
                  <i className="fab fa-linkedin"></i> Linkedin
                </li>
                <li>
                  <i className="fab fa-twitter"></i> Twitter
                </li>
              </ul>
            </div>
            <div className="col-md border-right">
              <h4>Contacto</h4>
              <ul>
                <li>Telefono: +57 345 4444</li>
                <li>Email: info@womve.com</li>
              </ul>
            </div>
            <div className="col-md">
              <h4>Enlaces</h4>
              <ul>
                <li>
                  <Link className="text-light" to="/">
                    Inicio
                  </Link>
                </li>
                <li>
                  <Link className="text-light" to="/about">
                    Nosotros
                  </Link>
                </li>
                {user && (
                  <li>
                    <Link className="text-light" to="/home">
                      Home - Pedidos
                    </Link>
                  </li>
                )}
                {!user && (
                  <>
                    <li>
                      <Link className="text-light" to="/login">
                        Entrar
                      </Link>
                    </li>
                    <li>
                      <Link className="text-light" to="/register">
                        Registrate
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>

            <div className="col-md-12 mt-5">
              Copyright &copy; 2020 <strong>womve.com</strong>
              <p>Todos los derechos reservados</p>
            </div>
          </div>
        </footer>
      </div>

      <PublicStatusOrderModal />
      <PublicMenuDrawer
        open={openMenuDrawer}
        anchor="left"
        closeDrawer={closeMenuDrawer}
      >
        <List>
          {location.pathname === '/' && (
            <LinkScroll offset={-60} smooth={true} to="inicio">
              <ListItem
                button
                className="text-primary"
                onClick={() => closeMenuDrawer()}
              >
                Inicio
              </ListItem>
            </LinkScroll>
          )}
          {location.pathname !== '/' && (
            <Link to="/">
              <ListItem
                button
                className="text-primary"
                onClick={() => closeMenuDrawer()}
              >
                Inicio
              </ListItem>
            </Link>
          )}

          {location.pathname === '/' && (
            <>
              <LinkScroll offset={-60} smooth={true} to="benefits">
                <ListItem
                  button
                  className="text-primary"
                  onClick={() => closeMenuDrawer()}
                >
                  Beneficios
                </ListItem>
              </LinkScroll>

              <LinkScroll offset={-60} smooth={true} to="about">
                <ListItem
                  button
                  className="text-primary"
                  onClick={() => closeMenuDrawer()}
                >
                  Nosotros
                </ListItem>
              </LinkScroll>

              <LinkScroll offset={-60} smooth={true} to="pricing">
                <ListItem
                  button
                  className="text-primary"
                  onClick={() => closeMenuDrawer()}
                >
                  Precios
                </ListItem>
              </LinkScroll>

              <LinkScroll offset={-60} smooth={true} to="contract">
                <ListItem
                  button
                  className="text-primary"
                  onClick={() => closeMenuDrawer()}
                >
                  {' '}
                  Registrar mi negocio
                </ListItem>
              </LinkScroll>
            </>
          )}

          {user && (
            <>
              <Link to="/my-orders">
                <ListItem
                  button
                  className="text-primary"
                  onClick={() => closeMenuDrawer()}
                >
                  Mis ordenes
                </ListItem>
              </Link>
              <Link to="/home">
                <ListItem
                  button
                  className="text-primary"
                  onClick={() => closeMenuDrawer()}
                >
                  Mi cuenta
                </ListItem>
              </Link>
            </>
          )}
          {!user && (
            <>
              <Link to="/login">
                <ListItem
                  button
                  className="text-primary"
                  onClick={() => closeMenuDrawer()}
                >
                  Entrar
                </ListItem>
              </Link>
              {/* <Link to="/register">Registrate</Link> */}
            </>
          )}
        </List>
      </PublicMenuDrawer>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps, { update_user, update_cart })(
  PublicLayout
)
