// React
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Checkbox,
  Button,
  IconButton,
} from '@material-ui/core'

import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { connect } from 'react-redux'
import { update_alert, update_loading } from '../../store/actions'
import Modal from '../../components/Modal'
import NoRegistersMessage from '../../components/NoRegistersMessage'

//Validate input con hook form
import { useForm } from 'react-hook-form'

// Services
import ProductService from '../../services/product.service'

//Helpers
import {
  getMessageError,
  defaultRowsPerPage,
  arrayRowsPerPageOptions,
  confirmAlert,
  getStringHourMinutesFromDate,
  getDateWithSetHourMinutes,
} from '../../helpers'

//Dialog
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

//Import custom search input
import SearchInput from '../../components/SearchInput'

// css
import './ProductCategories.scss'

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '1rem',
  },
  table: {
    maxWidth: '100%',
  },
})

const Products = props => {
  const classes = useStyles()
  const { update_alert, update_loading } = props
  //State
  const [rows, setRows] = useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage)
  const [totalRows, setTotalRows] = React.useState(1)
  const [open, setOpen] = React.useState(false)
  const initial_state_product = {
    id: null,
    name: '',
    description: '',
    image: '',
    active: true,
    has_sales_hours: false,
    start_hour: '',
    end_hour: '',
  }
  const [product, setProduct] = useState(initial_state_product)
  const [images, setImages] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [error_image, setErrorImage] = useState(null)
  //Current edit category id
  const [category_edit_id, setCategoryEditId] = useState(null)
  const [category_edit_change_image, setCategoryEditChangeImage] = useState(
    false
  )
  const [selectedInitTime, setSelectedInitTime] = useState(new Date())
  const [selectedEndTime, setSelectedEndTime] = useState(new Date())
  //search term
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)

  //validación por useForm
  const { register, handleSubmit, errors } = useForm()

  //METHODS
  //Change init time input
  const handleInitTimeChange = date => {
    setSelectedInitTime(date)
  }
  //Change end time input
  const handleEndTimeChange = date => {
    setSelectedEndTime(date)
  }

  //Change input product
  const handleInputChange = e => {
    let value = ''
    if (
      e.currentTarget.name === 'active' ||
      e.currentTarget.name === 'has_sales_hours'
    ) {
      value = e.target.checked
    } else {
      value = e.currentTarget.value
    }
    setProduct({
      ...product,
      [e.currentTarget.name]: value,
    })
  }

  //Open modal new product category
  const handleClickOpen = () => {
    //setCategoryEditId(null);
    //setProduct(initial_state_product);
    //setImages([]);
    //setCategoryEditChangeImage(false);
    setOpen(true)
  }

  //Open modal edit product category
  const editCategory = (category_id, index) => {
    setCategoryEditId(category_id)
    const edit_product = rows[index]
    edit_product.description = edit_product.description ?? ''
    setProduct(edit_product)

    //Set init and end hours
    const dateInit = getDateWithSetHourMinutes(edit_product.start_hour)
    const dateEnd = getDateWithSetHourMinutes(edit_product.end_hour)

    setSelectedInitTime(dateInit)
    setSelectedEndTime(dateEnd)

    if (edit_product.image) {
      setImages([
        {
          url: edit_product.url_image,
          file: edit_product.url_image,
        },
      ])
    }

    setCategoryEditChangeImage(false)
    setOpen(true)
  }

  //Close modal new product category
  const handleClose = () => {
    setCategoryEditId(null)
    setProduct(initial_state_product)
    setImages([])
    setCategoryEditChangeImage(false)
    const new_date = new Date()
    setSelectedInitTime(new_date)
    setSelectedEndTime(new_date)
    setOpen(false)
  }

  //Change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  //Change items per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const addImage = e => {
    const file = e.target.files[0]
    setImages([
      ...images,
      {
        url: URL.createObjectURL(file),
        file: file,
      },
    ])
    setCategoryEditChangeImage(true)
  }

  //save
  const save = async () => {
    const data = JSON.parse(JSON.stringify(product))
    const dateInit = getStringHourMinutesFromDate(selectedInitTime)
    const dateEnd = getStringHourMinutesFromDate(selectedEndTime)

    try {
      const form = new FormData()
      if (images.length > 0) {
        images.forEach((file, index) => {
          form.append(`files[${index}]`, file.file)
        })
      }

      form.append('name', data.name)
      form.append('description', data.description ? data.description : 0)
      form.append('has_sales_hours', data.has_sales_hours ? 1 : 0)
      form.append('initTime', dateInit)
      form.append('endTime', dateEnd)
      form.append('active', data.active ? 1 : 0)

      const { data: res } = await ProductService.saveCategory(form)
      handleClose()

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })

      //update correctly table
      if (page !== 0) {
        setPage(0)
        return
      } else {
        if (rowsPerPage < rows.length + 1) {
          rows.pop()
        }
      }
      setRows([{ ...res.data, products_count: 0 }, ...rows])
      setTotalRows(totalRows => totalRows + 1)
      //end update correctly table
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //Search change input
  const executeSearch = value => {
    setPage(0)
    setSearch(value)
  }

  //get all product categories
  const get = async () => {
    if (!search) {
      update_loading({
        show: true,
        message: 'Cargando',
      })
    } else {
      setSearchLoading(true)
    }

    try {
      const { data: res } = await ProductService.getCategories(
        page + 1,
        rowsPerPage,
        search
      )
      setTotalRows(res.data.total)
      setRows(res.data.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }

    update_loading({
      show: false,
    })
    setSearchLoading(false)
  }

  //Delete image product
  const deleteImage = async index => {
    const sw = await confirmAlert(
      'Quitar imagen',
      '¿Estas seguro de quitar la imagen de la categoría?'
    )

    if (sw.value) {
      images.splice(index, 1)
      setImages([...images])
      setCategoryEditChangeImage(true)
    }
  }

  //Delete product
  const deleteCategory = async (product_id, index) => {
    const sw = await confirmAlert(
      'Eliminar categoría',
      '¿Estas seguro de eliminar esta categoría?'
    )
    if (sw.value) {
      const { data: res } = await ProductService.deleteCategory({
        id: product_id,
      })

      if (res.success) {
        rows.splice(index, 1)
        if (rows.length === 0) {
          setPage(0)
          setRows([])
          setTotalRows(0)
        } else {
          setRows([...rows])
          setTotalRows(totalRows => totalRows - 1)
        }

        update_alert({
          open: true,
          severity: 'success',
          message: res.message,
        })
      }
    }
  }

  //update
  const update = async () => {
    const data = JSON.parse(JSON.stringify(product))
    const dateInit = getStringHourMinutesFromDate(selectedInitTime)
    const dateEnd = getStringHourMinutesFromDate(selectedEndTime)

    try {
      const form = new FormData()
      if (images.length > 0) {
        images.forEach((file, index) => {
          form.append(`files[${index}]`, file.file)
        })
      }
      form.append('_method', 'PUT')
      form.append('id', data.id)
      form.append('name', data.name)
      form.append('description', data.description ? data.description : 0)
      form.append('has_sales_hours', data.has_sales_hours ? 1 : 0)
      form.append('initTime', dateInit)
      form.append('endTime', dateEnd)
      form.append('active', data.active ? 1 : 0)
      form.append(
        'category_edit_change_image',
        category_edit_change_image ? 1 : 0
      )

      const { data: res } = await ProductService.updateCategory(form)
      handleClose()

      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })

      //Find row category
      let new_rows = JSON.parse(JSON.stringify(rows))
      const index = new_rows.findIndex(i => i.id === res.data.id)
      new_rows[index] = res.data
      setRows(new_rows)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //UseEffects
  // useEffect(() => {
  //   get();
  // }, []);
  useEffect(() => {
    get()
  }, [search, page, rowsPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="text-right p-2">
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          <i className="fa fa-plus"></i>
          Nuevo
        </Button>
      </div>
      <Paper className={classes.root}>
        <div className="headerTable">
          <span className="title">Lista de categorías de productos</span>
          <SearchInput
            label="Buscar"
            executeSearch={executeSearch}
            loading={searchLoading}
          />
        </div>

        {rows.length > 0 ? (
          <>
            <TableContainer className="containerTable">
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Descripción</TableCell>
                    <TableCell>Productos</TableCell>
                    <TableCell>Activa</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell>{row.id}</TableCell>
                        <TableCell>
                          <img
                            className="mr-2"
                            src={row.url_image}
                            width="40"
                            height="40"
                            alt="imagen categoría"
                          />
                          {row.name}
                        </TableCell>
                        <TableCell>
                          {row.description ? row.description : 's/d'}
                        </TableCell>
                        <TableCell>{row.products_count}</TableCell>

                        <TableCell>{row.active ? 'Si' : 'No'}</TableCell>

                        <TableCell>
                          <IconButton
                            aria-label="Editar"
                            color="primary"
                            onClick={() => editCategory(row.id, index)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            onClick={() => deleteCategory(row.id, index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={arrayRowsPerPageOptions}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <NoRegistersMessage search={search} />
        )}
      </Paper>

      <Modal
        open={open}
        zIndex={1}
        title={(category_edit_id ? 'Editar' : 'Nueva') + ' categoría'}
        closeModal={handleClose}
        maxWidth="sm"
      >
        <form
          onSubmit={
            category_edit_id ? handleSubmit(update) : handleSubmit(save)
          }
        >
          <DialogContent>
            <div className="mt-3">
              <TextField
                inputRef={register({ required: true })}
                fullWidth
                variant="outlined"
                label="Nombre"
                name="name"
                type="text"
                value={product.name}
                onChange={handleInputChange}
                error={!!errors.name}
                helperText={errors.name && 'El campo nombre es requerido'}
              />
            </div>

            <div className="mt-3">
              <TextField
                fullWidth
                variant="outlined"
                label="Descripción"
                name="description"
                type="text"
                value={product.description}
                onChange={handleInputChange}
                multiline
              />
            </div>

            <div className="mt-3">
              <p className="font-weight-bold">Imagenes</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {images.map((i, index) => {
                  return (
                    <div
                      style={{
                        width: '100px',
                        height: '100px',
                        position: 'relative',
                        border: '1px solid #ccc',
                        marginRight: '8px',
                      }}
                    >
                      <i
                        className="fa fa-trash text-danger"
                        style={{
                          position: 'absolute',
                          right: 0,
                          top: '5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => deleteImage(index)}
                      >
                        e
                      </i>
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                        src={i.url}
                        key={i.url}
                        alt="imagen categoría"
                      />
                    </div>
                  )
                })}

                {images.length <= 0 && (
                  <span>
                    <input
                      className="inputfile"
                      type="file"
                      onChange={addImage}
                      id="file"
                    />
                    <label htmlFor="file">
                      <i className="fa fa-plus-circle"></i>
                    </label>
                  </span>
                )}
              </div>
              {/* Error si no hay una imagen */}
              <div>
                {error_image && (
                  <div>
                    <p className="text-danger">{error_image}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3">
              <label htmlFor="active" className="font-weight-bold">
                Tendra un horario en el que estara disponible
              </label>
              <Checkbox
                id="active"
                color="primary"
                checked={product.has_sales_hours}
                onChange={handleInputChange}
                inputProps={{ 'aria-label': 'Horario de venta' }}
                name="has_sales_hours"
              />
            </div>
            {product.has_sales_hours && (
              <div className="mt-3">
                <p>
                  Desde que hora a que hora estara disponible esta categoría
                </p>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Hora inicial"
                  value={selectedInitTime}
                  onChange={handleInitTimeChange}
                  KeyboardButtonProps={{
                    'aria-label': 'Elegir',
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Hora final"
                  value={selectedEndTime}
                  onChange={handleEndTimeChange}
                  KeyboardButtonProps={{
                    'aria-label': 'Elegir',
                  }}
                />
              </div>
            )}

            <div className="mt-3">
              <label htmlFor="active" className="font-weight-bold">
                {product.active ? 'Activa' : 'Inactiva'}
              </label>
              <Checkbox
                id="active"
                color="primary"
                checked={product.active}
                onChange={handleInputChange}
                inputProps={{ 'aria-label': 'Activa' }}
                name="active"
              />
            </div>
          </DialogContent>
          <DialogActions className="pb-4">
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button variant="contained" type="submit" color="primary">
              <i className="fa fa-save"></i>
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Modal>
    </MuiPickersUtilsProvider>
  )
}

export default connect(null, { update_alert, update_loading })(Products)
