import React, { useState, useEffect } from 'react'
import {
  TextField,
  Button,
  DialogContent,
  Paper,
  Box,
  Dialog,
  DialogActions,
} from '@material-ui/core'

import Modal from './Modal'

//Redux
import { connect } from 'react-redux'
import { update_alert } from '../store/actions'

//Validate input con hook form
import { useForm } from 'react-hook-form'
import StoreService from '../services/store.service'
import UtilityService from '../services/utility.service'
//Helpers
import { getMessageError, confirmAlert } from '../helpers'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  addPaymentMethodBtn: {
    background: '#E6E6E6',
    cursor: 'pointer',
    '&:hover': {
      background: '#F9F9F9',
      color: '#52CA8B !important',
      fontWeight: 'bold',
    },
  },
})

const AddPaymentMethodModal = props => {
  const classes = useStyles()

  //Redux
  const { update_alert } = props

  //Props
  const {
    open,
    paymentMethod,
    handleClose,
    activePaymentMethodChildEdit,
  } = props
  //STATE
  const [initFields, setInitFields] = useState([])

  //validate form
  const { register, handleSubmit, errors } = useForm()

  //Methods
  const handleChange = (ev, index) => {
    console.log(ev.target.value, index)
    const values = [...initFields]
    values[index].value = ev.target.value
    setInitFields(values)
  }

  //Save
  const save = async () => {
    try {
      const {
        data: res,
      } = await StoreService.storePayementMethodInPayementMethodStore({
        payment_method_fields: [...initFields],
        payment_method_store_id: paymentMethod.id,
        store_id: paymentMethod.store_id,
      })
      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
      setInitFields([])

      handleClose(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  const update = async () => {
    try {
      const {
        data: res,
      } = await StoreService.updatePayementMethodInPayementMethodStore({
        payment_method_fields: [...initFields],
        payment_method_store_id: paymentMethod.id,
        store_id: paymentMethod.store_id,
        payment_method_store_list_id: activePaymentMethodChildEdit.id,
      })
      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
      setInitFields([])

      handleClose({ ...res.data, edit_id: activePaymentMethodChildEdit.id })
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //close modal
  const closeModal = () => {
    setInitFields([])
    handleClose()
  }

  //UseEffect
  useEffect(() => {
    console.log(paymentMethod)
    if (paymentMethod) {
      if (activePaymentMethodChildEdit) {
        setInitFields(
          JSON.parse(
            JSON.stringify(activePaymentMethodChildEdit.payment_method_fields)
          )
        )
      } else {
        setInitFields(
          JSON.parse(JSON.stringify(paymentMethod.payment_method.init_fields))
        )
      }
    }
  }, [paymentMethod])

  return (
    <>
      <Modal
        open={open}
        zIndex={1}
        title={
          'Agregar nuevo metodo ' +
          (paymentMethod ? paymentMethod.payment_method.name : '')
        }
        closeModal={closeModal}
        maxWidth="sm"
      >
        <form
          onSubmit={handleSubmit(activePaymentMethodChildEdit ? update : save)}
        >
          <DialogContent className="pt-0">
            {paymentMethod &&
              initFields.map((field, index) => {
                return (
                  <div className="mt-3" key={index}>
                    <TextField
                      inputRef={register({ required: true })}
                      fullWidth
                      variant="outlined"
                      label={field.name}
                      name={field.name}
                      type="text"
                      defaultValue={field.value || ''}
                      onChange={ev => handleChange(ev, index)}
                      error={!!errors[field.name]}
                      helperText={
                        errors[field.name] &&
                        `El campo ${field.name} es requerido`
                      }
                    />
                  </div>
                )
              })}
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary">
              <i className="fa fa-save mr-3"></i>
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Modal>
    </>
  )
}

export default connect(null, { update_alert })(AddPaymentMethodModal)
