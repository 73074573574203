import React from 'react'
import RadioControls from '../components/RadioControls'
import CheckboxControls from '../components/CheckboxControls'
import SelectControls from '../components/SelectControls'
import FileControls from '../components/FileControls'
import { Fade, Tooltip } from '@material-ui/core'

const ProductAttributeOptionControl = props => {
  //Parent properties
  const { attributes, store } = props
  //Parent methods
  const { changeAttribute } = props

  //Methods
  const verifyDependentAttributes = option => {
    changeAttribute(option)
  }

  return (
    <ul>
      {attributes.length > 0 &&
        attributes
          .sort(function (a, b) {
            if (a.position > b.position) {
              return 1
            }
            if (a.position < b.position) {
              return -1
            }
            // a must be equal to b
            return 0
          })
          .map(a => {
            return (
              <li
                key={a.id}
                className={!!a.dependent_attribute ? 'hide' : 'show'}
              >
                <span className="name-attribute">
                  {a.name}
                  {a.help_text && (
                    <Tooltip
                      placement="top"
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 200 }}
                      title={a.help_text}
                      arrow
                    >
                      <i className="fa fa-info-circle ml-1 text-info"></i>
                    </Tooltip>
                  )}
                </span>

                {/* Here print attribute option */}
                {a.options.length > 0 && (
                  <ul>
                    {(() => {
                      if (a.mode_options === 'radio') {
                        return (
                          <RadioControls
                            store={store}
                            clearOptions={!!a.dependent_attribute}
                            callback={verifyDependentAttributes}
                            options={a.options}
                            required={a.required}
                          />
                        )
                      } else if (a.mode_options === 'checkbox') {
                        return (
                          <CheckboxControls
                            store={store}
                            clearOptions={!!a.dependent_attribute}
                            callback={verifyDependentAttributes}
                            options={a.options}
                          />
                        )
                      } else if (a.mode_options === 'select') {
                        return (
                          <SelectControls
                            store={store}
                            clearOptions={!!a.dependent_attribute}
                            callback={verifyDependentAttributes}
                            options={a.options}
                            required={a.required}
                          />
                        )
                      } else if (a.mode_options === 'file') {
                        return (
                          <FileControls
                            store={store}
                            clearOptions={!!a.dependent_attribute}
                            callback={verifyDependentAttributes}
                            options={a.options}
                          />
                        )
                      } else {
                        return <div>catch all</div>
                      }
                    })()}
                  </ul>
                )}
              </li>
            )
          })}
    </ul>
  )
}

export default ProductAttributeOptionControl
