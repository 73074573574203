import React, { useState, useEffect } from 'react'
import ReactSelect from 'react-select'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Checkbox,
  TextField,
  Button,
  FormControlLabel,
} from '@material-ui/core'

import Swal from 'sweetalert2'

//Leaflet
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import mapMarkerDefault from './mapMarkerDefault.png'

//Redux
import { connect } from 'react-redux'
import { update_alert } from '../store/actions'

//Validate input con hook form
import { useForm } from 'react-hook-form'
import StoreService from '../services/store.service'
import UtilityService from '../services/utility.service'
//Helpers
import { getMessageError } from '../helpers'

//Initial currencies
const init_state_currencies = [
  {
    id: 1,
    name: 'Dolares',
  },
  {
    id: 2,
    name: 'Bolivares',
  },
]

const init_exchange_rate_types = [
  {
    id: 1,
    name: 'Banco Central de Venezuela',
  },
  {
    id: 2,
    name: 'Manual',
  },
]

const init_state_messages_exchange_type = [
  'Para los casos donde la plataforma deba hacer conversiones de moneda se usara la tasa de cambio en el momento del Banco Central de Venezuela.',
  'Para los casos donde la plataforma deba hacer conversiones de moneda se usara la tasa de cambio que especifiques y podras actualizarla cuando quieras y las veces que quieras',
]

const SaveAndEditStoreInfo = props => {
  //Redux
  const { update_alert, callback1, callback2 } = props
  //STATE
  const [store, setStore] = useState({
    name: '',
    description: '',
    address: '',
    phone_one: '',
    phone_two: '',
    phone_whatsapp: '',
    exchangeRate: 1,
    url_instagram: '',
    url_facebook: '',
    url_twitter: '',
    url_linkedin: '',
    url_youtube: '',
    mode_delivery: false,
    mode_pickup: false,
  })
  const [logo, setLogo] = useState(false)
  const [banner, setBanner] = useState(false)
  const [category, setCategory] = useState('')
  const [sub_category, setSubCategory] = useState('')
  const [categories, setCategories] = useState([])
  const [sub_categories, setSubCategories] = useState([])

  const [currency, setCurrency] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [currencies, setCurrencies] = useState(init_state_currencies)
  const [currencyClient, setCurrencyClient] = useState('')

  const [catalogShop, setCatalogShop] = useState(false)
  const [showPricing, setShowPricing] = useState(false)

  const [exchangeRateType, setExchangeRateType] = useState(
    init_exchange_rate_types[0]
  )
  const [messageExchangeType, setMessageExchangeType] = useState(
    init_state_messages_exchange_type[0]
  )
  // eslint-disable-next-line no-unused-vars
  const [exchangeRateTypes, setExchangeRateTypes] = useState(
    init_exchange_rate_types
  )

  //Edit store
  const [storeEditChangeLogo, setStoreEditChangeLogo] = useState(false)
  const [storeEditChangeBanner, setStoreEditChangeBanner] = useState(false)
  const [
    storeEditChangeSubCategories,
    setStoreEditChangeSubCategories,
  ] = useState(false)

  //map
  const [position, setPosition] = useState([
    8.630194677878427,
    -70.21493268133581,
  ])

  const markerUser = L.icon({
    iconUrl: mapMarkerDefault,
    iconSize: [30, 30], // size of the icon
    popupAnchor: [0, -15], // point from which the popup should open relative to the iconAnchor
  })

  //validación por useForm
  const { register, handleSubmit, setValue, errors, unregister } = useForm()

  //Methods
  //Change input store
  const markPositionClick = ({ latlng }) => {
    setPosition([latlng.lat, latlng.lng])
  }

  const markPositionDrag = ({ target }) => {
    setPosition([target._latlng.lat, target._latlng.lng])
  }

  const handleInputChange = e => {
    let { name, value } = e.currentTarget
    if (name === 'mode_delivery') {
      value = e.currentTarget.checked
    }
    if (name === 'mode_pickup') {
      value = e.currentTarget.checked
    }

    setStore({
      ...store,
      [name]: value,
    })
  }

  //Catalog shop checked
  const handleChangeCatalogShop = ev => {
    if (ev.target.checked) {
      unregister('currency')
      unregister('currencyClient')
    } else {
      register(
        { name: 'currency', type: 'select' },
        {
          required: {
            value: true,
            message: 'El campo moneda es requerido',
          },
        }
      )
      register(
        { name: 'currencyClient', type: 'select' },
        {
          required: {
            value: true,
            message: 'El campo moneda cliente es requerido',
          },
        }
      )
    }

    setCatalogShop(ev.target.checked)
  }

  const handleChangeShowPricing = ev => {
    setShowPricing(ev.target.checked)
  }

  //Change select store category
  const changeSelectStoreCategory = event => {
    setValue('category', event.target.value, true)
    setCategory(event.target.value)
    getStoreSubCategories(event.target.value)
  }

  //Change select store sub category
  const changeSelectStoreSubCategory = options => {
    setValue('sub_category', options, true)
    setSubCategory(options)
    setStoreEditChangeSubCategories(true)
  }

  //Change select store currency
  const changeSelectStoreCurrency = event => {
    setValue('currency', event.target.value, true)
    setCurrency(event.target.value)
  }

  //Change select store currency client
  const changeSelectStoreCurrencyClient = event => {
    setValue('currencyClient', event.target.value, true)
    setCurrencyClient(event.target.value)
  }

  //Change select exchange rate type
  const changeSelectStoreExchangeRateType = event => {
    //If is manual
    if (event.target.value.id === 2) {
      setMessageExchangeType(init_state_messages_exchange_type[1])
    } else {
      getExchangeRate(true)
      setMessageExchangeType(init_state_messages_exchange_type[0])
    }

    setValue('exchangeRateType', event.target.value, true)
    setExchangeRateType(event.target.value)
  }

  //save
  const save = async () => {
    const data = JSON.parse(JSON.stringify(store))
    const sub_categories_ids = sub_category.map(item => item.id)

    if (!data.mode_delivery && !data.mode_pickup && !catalogShop) {
      update_alert({
        open: true,
        severity: 'error',
        message:
          'Debes elegir una modalidad de venta para tu tienda ya sea delivery o permitir a tus clientes recoger en tienda',
      })
      return
    }

    try {
      const form = new FormData()

      //If update
      if (props.store_id) {
        form.append('_method', 'PUT')
        form.append('id', data.id)
        form.append('store_edit_change_logo', storeEditChangeLogo ? 1 : 0)
        form.append('store_edit_change_banner', storeEditChangeBanner ? 1 : 0)
        form.append(
          'store_edit_change_sub_categories',
          storeEditChangeSubCategories ? 1 : 0
        )
      }
      form.append('store_category_id', category.id)
      form.append('sub_categories_ids', JSON.stringify(sub_categories_ids))
      form.append('name', data.name)
      form.append('description', data.description)
      form.append('address', data.address)
      form.append('phone_one', data.phone_one)
      form.append('phone_two', data.phone_two)
      form.append('phone_whatsapp', data.phone_whatsapp)
      form.append('currency', currency.id)
      form.append('currency_client', currencyClient.id)

      form.append('exchange_rate_type', exchangeRateType.id)
      form.append('exchange_rate', data.exchangeRate)
      form.append('catalog_shop', catalogShop ? 1 : 0)
      form.append('show_pricing', showPricing ? 1 : 0)

      //social networks
      form.append('url_instagram', data.url_instagram)
      form.append('url_facebook', data.url_facebook)
      form.append('url_twitter', data.url_twitter)
      form.append('url_linkedin', data.url_linkedin)
      form.append('url_youtube', data.url_youtube)

      form.append('mode_delivery', data.mode_delivery ? 1 : 0)
      form.append('mode_pickup', data.mode_pickup ? 1 : 0)

      //latitude and longitude
      form.append('latitude', position[0])
      form.append('longitude', position[1])

      if (logo) {
        form.append('logo', logo.file)
      }

      if (banner) {
        form.append('banner', banner.file)
      }

      //If update
      const { data: res } = props.store_id
        ? await StoreService.update(form)
        : await StoreService.save(form)
      update_alert({
        open: true,
        severity: 'success',
        message: res.message,
      })
      if (!props.store_id) callback2(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //Add Logo
  const addLogo = e => {
    const file = e.target.files[0]
    setLogo({
      url: URL.createObjectURL(file),
      file: file,
    })

    //If update
    if (props.store_id) {
      setStoreEditChangeLogo(true)
    }
  }

  //Delete Logo
  const deleteLogo = () => {
    Swal.fire({
      title: 'Quitar logo',
      text: '¿Estas seguro de quitar el logo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        setLogo(false)
        //If update
        if (props.store_id) {
          setStoreEditChangeLogo(true)
        }
      }
    })
  }

  //Add Banner
  const addBanner = e => {
    const file = e.target.files[0]
    setBanner({
      url: URL.createObjectURL(file),
      file: file,
    })
    //If update
    if (props.store_id) {
      setStoreEditChangeBanner(true)
    }
  }

  //Delete Banner
  const deleteBanner = () => {
    Swal.fire({
      title: 'Quitar banner',
      text: '¿Estas seguro de quitar el banner?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        setBanner(false)
        //If update
        if (props.store_id) {
          setStoreEditChangeBanner(true)
        }
      }
    })
  }

  //Get Categories
  const getStoreCategories = async () => {
    try {
      const { data: res } = await UtilityService.getStoreCategories()
      setCategories(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //Get Sub Categories
  const getStoreSubCategories = async store_category => {
    try {
      if (!store_category) {
        setSubCategories([])
        setValue('category', false)
        setCategory(false)
        setValue('sub_category', false)
        setSubCategory('sub_category', false)
        return
      }

      const { data: res } = await UtilityService.getStoreSubCategories(
        store_category.id
      )
      setValue('sub_category', false)
      setSubCategory('sub_category', false)
      setSubCategories(res.data)
    } catch (error) {
      const errorMessage = getMessageError(error)
      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //Get exchange rate
  const getExchangeRate = async (change = false) => {
    try {
      const { data: res } = await UtilityService.getExchangeRate()

      if (!props.store_id || change) {
        setStore({ ...store, exchangeRate: res.data.rate })
        setValue('exchangeRate', res.data.rate)
      }
    } catch (error) {
      const errorMessage = getMessageError(error)

      update_alert({
        open: true,
        severity: 'error',
        message: errorMessage,
      })
    }
  }

  //Get store by id
  const getStore = async store_id => {
    try {
      const { data: res } = await StoreService.getStoreById(store_id)
      if (res.success) {
        const store = res.data
        store.description = store.description ?? ''
        store.phone_one = store.phone_one ?? ''
        store.phone_two = store.phone_two ?? ''
        store.phone_whatsapp = store.phone_whatsapp ?? ''
        store.exchangeRate = store.exchange_rate

        //social networks
        store.url_instagram = store.url_instagram ?? ''
        store.url_facebook = store.url_facebook ?? ''
        store.url_twitter = store.url_twitter ?? ''
        store.url_linkedin = store.url_linkedin ?? ''
        store.url_youtube = store.url_youtube ?? ''

        //Map
        setPosition([store.latitude, store.longitude])

        //Mode store sales
        store.mode_delivery = store.mode_delivery ? true : false
        store.mode_pickup = store.mode_pickup ? true : false

        setStore(store)
        setValue('exchangeRate', store.exchangeRate)

        const store_category = categories.find(
          i => i.id === store.store_category.id
        )

        //Get subcateries for category_id
        await getStoreSubCategories(store.store_category)

        setValue('category', store_category, true)
        setCategory(store_category)

        setSubCategory(store.store_sub_categories)
        setValue('sub_category', store.store_sub_categories, true)

        //set currency store
        const currency = currencies.find(i => i.id === store.currency)
        setValue('currency', currency, true)
        setCurrency(currency)

        //set currency store
        const currencyClient = currencies.find(
          i => i.id === store.currency_client
        )
        setValue('currencyClient', currencyClient, true)
        setCurrencyClient(currencyClient)

        //Set  exchange rate type
        const exchangeRateType = exchangeRateTypes.find(
          i => i.id === store.exchange_rate_type
        )
        setValue('exchangeRateType', exchangeRateType, true)
        setExchangeRateType(exchangeRateType)

        setCatalogShop(store.catalog_shop)
        setShowPricing(store.show_pricing)

        //Set logo and set banner
        setLogo({
          url: store.url_logo,
          file: false,
        })
        setBanner({
          url: store.url_banner,
          file: false,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  //useEffect
  useEffect(() => {
    getStoreCategories()
    getExchangeRate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //received store info as prop
  useEffect(() => {
    if (categories.length) {
      if (props.store_id) {
        getStore(props.store_id)
        console.log('viene el store_id: ' + props.store_id)
      } else {
        console.log('No es editar')
      }
    }
  }, [categories]) // eslint-disable-line react-hooks/exhaustive-deps

  //Listen to register validation for appy the rules each input
  useEffect(() => {
    register(
      { name: 'category', type: 'select' },
      {
        required: {
          value: true,
          message: 'El campo categoría es requerido',
        },
      }
    )
    register(
      { name: 'sub_category', type: 'select' },
      {
        required: {
          value: true,
          message: 'El campo subcategoría es requerido',
        },
      }
    )
    register(
      { name: 'currency', type: 'select' },
      {
        required: {
          value: true,
          message: 'El campo moneda es requerido',
        },
      }
    )
    register(
      { name: 'currencyClient', type: 'select' },
      {
        required: {
          value: true,
          message: 'El campo moneda cliente es requerido',
        },
      }
    )
  }, [register])

  return (
    <>
      <form onSubmit={handleSubmit(save)} style={{ position: 'relative' }}>
        <div>
          <FormControl
            variant="outlined"
            className="w-100"
            error={!!errors.category}
          >
            <InputLabel id="select_store_category_label">Categoría</InputLabel>
            <Select
              label="Categoría"
              labelId="select_store_category_label"
              id="select_store_category"
              className="w-100"
              value={category}
              onChange={changeSelectStoreCategory}
              inputRef={register}
              name="category"
            >
              <MenuItem value="" disabled>
                Seleccionar
              </MenuItem>
              {categories.map(o => {
                return (
                  <MenuItem key={o.id} value={o}>
                    {o.name}
                  </MenuItem>
                )
              })}
            </Select>

            {!!errors.category && (
              <FormHelperText className="text-danger">
                {errors.category.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>

        <div className="mt-3 mb-3">
          <label>Subcategoría</label>

          <ReactSelect
            isMulti
            classNamePrefix="react-select"
            className="react-select-container"
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            options={sub_categories}
            inputRef={register}
            name="sub_category"
            value={sub_category}
            onChange={changeSelectStoreSubCategory}
          ></ReactSelect>

          {!!errors.sub_category && (
            <FormHelperText className="text-danger">
              {errors.sub_category.message}
            </FormHelperText>
          )}
        </div>

        <div>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            variant="outlined"
            label="Nombre"
            name="name"
            type="text"
            value={store.name}
            onChange={handleInputChange}
            error={!!errors.name}
            helperText={errors.name && 'El campo nombre es requerido'}
          />
        </div>

        <div className="mt-3">
          <TextField
            fullWidth
            variant="outlined"
            label="Descripción"
            name="description"
            type="text"
            value={store.description}
            onChange={handleInputChange}
            multiline
          />
        </div>

        <div className="mt-3">
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            variant="outlined"
            label="Dirección"
            name="address"
            type="text"
            value={store.address}
            onChange={handleInputChange}
            multiline
            error={!!errors.address}
            helperText={errors.address && 'El campo dirección es requerido'}
          />
        </div>

        <div className="mt-4">
          <h5>Elige la ubicación de tu tienda en el mapa</h5>
          <Map
            center={position}
            zoom={16}
            style={{ width: '100%', height: '350px' }}
            onClick={markPositionClick}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="womve"
            />
            <Marker
              typeId="user"
              position={position}
              draggable={true}
              onDragend={markPositionDrag}
              icon={markerUser}
            >
              <Popup>
                Esta ubicación sera usada
                <br /> para localizar tu tienda y en caso de prestar delivery
                <br />
                calcular las distancias
              </Popup>
            </Marker>
          </Map>
        </div>

        <div className="mt-3">
          <TextField
            fullWidth
            variant="outlined"
            label="Teléfono 1 (opcional)"
            name="phone_one"
            type="text"
            value={store.phone_one}
            onChange={handleInputChange}
          />
        </div>

        <div className="mt-3">
          <TextField
            fullWidth
            variant="outlined"
            label="Teléfono 2 (opcional)"
            name="phone_two"
            type="text"
            value={store.phone_two}
            onChange={handleInputChange}
          />
        </div>

        <div className="mt-3">
          <TextField
            fullWidth
            variant="outlined"
            label="Teléfono whatsapp (opcional)"
            name="phone_whatsapp"
            type="text"
            value={store.phone_whatsapp}
            onChange={handleInputChange}
          />
        </div>

        <div className="mt-3">
          <div>
            <label htmlFor="store_catalog_shop" className="h6 m-0 p-0">
              <Checkbox
                id="store_catalog_shop"
                checked={catalogShop}
                onChange={handleChangeCatalogShop}
                color="primary"
              />
              Usar tienda como un catalogo de productos
            </label>
          </div>
          <small className="w-100 p-3">
            <span className="text-info">Nota:</span> La opción de usar tienda
            como catalogo te permite mostrar el catalogo de tus productos al
            cliente y si el cliente quiere mas información o comprarte un
            producto te contacte via whatsapp a un número elegido por ti o a los
            contactos de tu tienda.
          </small>
        </div>
        {catalogShop && (
          <div className="mt-3">
            <div>
              <label
                htmlFor="store_catalog_show_pricing"
                className="h6 m-0 p-0"
              >
                <Checkbox
                  id="store_catalog_show_pricing"
                  checked={showPricing}
                  onChange={handleChangeShowPricing}
                  color="primary"
                />
                Mostrar precios de productos en mi catalogo
              </label>
            </div>
            <small className="w-100 pl-3">
              <span className="text-info">Nota:</span> Si quires mostrar los
              precios de tus productos en tu catalogo marca esta opcion si solo
              quieres mostrar los productos sin precio deja esta opción
              desmarcada.
            </small>
          </div>
        )}
        <div className="mt-4">
          <FormControl
            variant="outlined"
            className="w-100"
            error={!!errors.currency}
          >
            <InputLabel id="select_store_currency_label">
              Moneda interna
            </InputLabel>
            <Select
              label="Moneda interna"
              labelId="select_store_currency_label"
              id="select_store_currency"
              className="w-100"
              value={currency}
              onChange={changeSelectStoreCurrency}
              inputRef={register}
              name="currency"
            >
              <MenuItem value="" disabled>
                Seleccionar
              </MenuItem>
              {currencies.map(o => {
                return (
                  <MenuItem key={o.id} value={o}>
                    {o.name}
                  </MenuItem>
                )
              })}
            </Select>
            {!!errors.currency && (
              <FormHelperText className="text-danger">
                {errors.currency.message}
              </FormHelperText>
            )}

            <small className="text-default text-justify p-1">
              <span className="text-info">Nota:</span> Moneda interna es la
              moneda en la que vas a manejar internamente tu tienda.
            </small>
          </FormControl>
        </div>
        {(showPricing || !catalogShop) && (
          <>
            <div className="mt-4">
              <FormControl
                variant="outlined"
                className="w-100"
                error={!!errors.currencyClient}
              >
                <InputLabel id="select_store_currency_client_label">
                  Moneda visible al cliente
                </InputLabel>
                <Select
                  label="Moneda visible al cliente"
                  labelId="select_store_currency_client_label"
                  id="select_store_currency_client"
                  className="w-100"
                  value={currencyClient}
                  onChange={changeSelectStoreCurrencyClient}
                  inputRef={register}
                  name="currencyClient"
                >
                  <MenuItem value="" disabled>
                    Seleccionar
                  </MenuItem>
                  {currencies.map(o => {
                    return (
                      <MenuItem key={o.id} value={o}>
                        {o.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                {!!errors.currencyClient && (
                  <FormHelperText className="text-danger">
                    {errors.currencyClient.message}
                  </FormHelperText>
                )}

                <small className="text-default text-justify p-1">
                  <span className="text-info">Nota:</span> Moneda visible al
                  cliente es la moneda en la que se mostraran los precios en tu
                  tienda, por ejemplo si en moneda interna manejas dolares y
                  quieres mostrar a tus clientes precios en Bolivares en base a
                  una tasa de cambio elegida por ti o la tasa del Banco Central
                  de Venezuela.
                </small>
              </FormControl>
            </div>

            <div className="mt-4">
              <FormControl
                variant="outlined"
                className="w-100"
                error={!!errors.exchangeRateType}
              >
                <InputLabel id="select_store_exchange_rate_type_label">
                  Tasa de cambio a usar
                </InputLabel>
                <Select
                  label="Tasa de cambio a usar"
                  labelId="select_store_exchange_rate_type_label"
                  id="select_store_exchange_rate_type"
                  className="w-100"
                  value={exchangeRateType}
                  onChange={changeSelectStoreExchangeRateType}
                  inputRef={register}
                  name="exchangeRateType"
                >
                  <MenuItem value="" disabled>
                    Seleccionar
                  </MenuItem>
                  {exchangeRateTypes.map(o => {
                    return (
                      <MenuItem key={o.id} value={o}>
                        {o.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                {!!errors.exchangeRateType && (
                  <FormHelperText className="text-danger">
                    {errors.exchangeRateType.message}
                  </FormHelperText>
                )}

                <small className="text-default text-justify p-1">
                  <span className="text-info">Nota:</span> {messageExchangeType}
                </small>
              </FormControl>
            </div>

            <div className="mt-3">
              <TextField
                style={{
                  display: exchangeRateType.id === 2 ? 'block' : 'none',
                }}
                inputRef={register({
                  required: {
                    value: true,
                    message: 'Tasa de cambio es requerida',
                  },
                  validate: {
                    value: value =>
                      value < 1 ? 'La tasa debe ser minimo 1' : undefined,
                  },
                })}
                fullWidth
                variant="outlined"
                label="Tasa de cambio"
                name="exchangeRate"
                type="text"
                defaultValue={store.exchangeRate}
                onChange={handleInputChange}
                error={!!errors.exchangeRate}
                helperText={errors.exchangeRate && errors.exchangeRate.message}
              />
            </div>
          </>
        )}

        {!catalogShop && (
          <div className="row pt-3">
            <div className="col-md-3">
              <p className="font-weight-bold m-0">
                Modalidades de venta a usar en mi tienda
              </p>
            </div>
            <div className="mt-3 w-100">
              <FormControlLabel
                control={
                  <Checkbox
                    name="mode_delivery"
                    checked={store.mode_delivery}
                    onChange={handleInputChange}
                  />
                }
                label="Delivery a clientes"
              />
            </div>
            <div className="mt-3 w-100">
              <FormControlLabel
                control={
                  <Checkbox
                    name="mode_pickup"
                    checked={store.mode_pickup}
                    onChange={handleInputChange}
                  />
                }
                label="Permitir a mis clientes recoger en tienda"
              />
            </div>
          </div>
        )}

        <div className="row pt-3">
          <div className="col-md-3">
            <p className="font-weight-bold m-0">Enlaces a redes sociales</p>
          </div>
          <div className="mt-3 w-100">
            <TextField
              inputRef={register({
                pattern: {
                  value: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                  message: 'Ingresa un enlace valido',
                },
              })}
              fullWidth
              variant="outlined"
              label="Enlace instagram"
              name="url_instagram"
              type="text"
              value={store.url_instagram}
              onChange={handleInputChange}
              error={!!errors.url_instagram}
              helperText={errors.url_instagram && errors.url_instagram.message}
            />
          </div>
          <div className="mt-3 w-100">
            <TextField
              inputRef={register({
                pattern: {
                  value: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                  message: 'Ingresa un enlace valido',
                },
              })}
              fullWidth
              variant="outlined"
              label="Enlace facebook"
              name="url_facebook"
              type="text"
              value={store.url_facebook}
              onChange={handleInputChange}
              error={!!errors.url_facebook}
              helperText={errors.url_facebook && errors.url_facebook.message}
            />
          </div>
          <div className="mt-3 w-100">
            <TextField
              inputRef={register({
                pattern: {
                  value: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                  message: 'Ingresa un enlace valido',
                },
              })}
              fullWidth
              variant="outlined"
              label="Enlace twitter"
              name="url_twitter"
              type="text"
              value={store.url_twitter}
              onChange={handleInputChange}
              error={!!errors.url_twitter}
              helperText={errors.url_twitter && errors.url_twitter.message}
            />
          </div>
          <div className="mt-3 w-100">
            <TextField
              inputRef={register({
                pattern: {
                  value: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                  message: 'Ingresa un enlace valido',
                },
              })}
              fullWidth
              variant="outlined"
              label="Enlace linkedin"
              name="url_linkedin"
              type="text"
              value={store.url_linkedin}
              onChange={handleInputChange}
              error={!!errors.url_linkedin}
              helperText={errors.url_linkedin && errors.url_linkedin.message}
            />
          </div>
          <div className="mt-3 w-100">
            <TextField
              inputRef={register({
                pattern: {
                  value: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                  message: 'Ingresa un enlace valido',
                },
              })}
              fullWidth
              variant="outlined"
              label="Enlace youtube"
              name="url_youtube"
              type="text"
              value={store.url_youtube}
              onChange={handleInputChange}
              error={!!errors.url_youtube}
              helperText={errors.url_youtube && errors.url_youtube.message}
            />
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-md-3">
            <p className="font-weight-bold m-0">Logo</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {logo && (
                <div
                  style={{
                    width: '100px',
                    height: '100px',
                    position: 'relative',
                    border: '1px solid #ccc',
                    marginRight: '8px',
                  }}
                >
                  <i
                    className="fa fa-trash text-danger"
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={deleteLogo}
                  ></i>
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    src={logo.url}
                    key={logo.url}
                    alt="logo"
                  />
                </div>
              )}

              {!logo && (
                <span>
                  <input
                    className="inputfile"
                    type="file"
                    onChange={addLogo}
                    id="file"
                  />
                  <label htmlFor="file">
                    <i className="fa fa-plus-circle"></i>
                  </label>
                </span>
              )}
            </div>
          </div>

          <div className="col-md-9">
            <p className="font-weight-bold m-0">Banner</p>
            <div
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
              {banner && (
                <div
                  style={{
                    width: '100%',
                    height: '100px',
                    position: 'relative',
                    border: '1px solid #ccc',
                    marginRight: '8px',
                  }}
                >
                  <i
                    className="fa fa-trash text-danger"
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={deleteBanner}
                  ></i>
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    src={banner.url}
                    key={banner.url}
                    alt="banner"
                  />
                </div>
              )}

              {!banner && (
                <span style={{ width: '100%' }}>
                  <input
                    style={{ width: '100%' }}
                    className="inputfile"
                    type="file"
                    onChange={addBanner}
                    id="file-banner"
                  />
                  <label htmlFor="file-banner" style={{ width: '100%' }}>
                    <i className="fa fa-plus-circle"></i>
                  </label>
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="p-3 mt-2 text-right border-top">
          <Button onClick={callback1} color="primary">
            Cancelar
          </Button>
          <Button variant="contained" color="primary" type="submit">
            <i className="fa fa-save"></i>
            Guardar
          </Button>
        </div>
      </form>
    </>
  )
}

export default connect(null, { update_alert })(SaveAndEditStoreInfo)
