import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

//Services
import PublicService from '../../../services/public.service'
//Styles
import './welcome.scss'

import { Element, scroller } from 'react-scroll'

import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import SearchInput from '../../../components/SearchInput'

function Welcome() {
  //State
  const [subscriptions, setSubscriptions] = useState([])
  const [selectedSubscription, setSelectedSubscription] = useState('none')
  const [stores, setStores] = useState([])
  const [benefits, setBenefits] = useState([
    {
      iconClass: 'fa fa-store',
      name: 'Crear tu tienda',
      description: `En womve puedes crear tu tienda, registrar tus productos
      configurar los metodos de pago que recibiras, decidir si
      vendes en modalidad recoger, delivery o ambas.`,
    },
    {
      iconClass: 'fas fa-laptop',
      name: 'Panel administrativo web',
      description: `Tendras acceso a un panel administrativo web que podras acceder
      desde una computadora o desde tu teléfono en el mismo podras administrar toda
      tu tienda y recibir los pedidos.`,
    },
    {
      iconClass: 'fa fa-history',
      name: 'Pedidos en tiempo real',
      description: `Cuando tus clientes realicen un pedido en tu negocio recibiras
      inmediatamente una notificación en tu panel administrativo.`,
    },
    {
      iconClass: 'fa fa-link',
      name: 'Enlace web a tu negocio',
      description: `Cuando registras tu tienda en womve se te genera un enlace a tu
      negocio el cual puedes luego compartir a tus cliente, en redes sociales o cualquier otro
      medio que consideres.`,
    },
    {
      iconClass: 'fa fa-clock',
      name: 'Ahorra tiempo y potencia tus ventas',
      description: `Ya no mas ventas habituales por chats, tus clientes veran tus productos
      y podran pedirte desde tu negocio online y tu las recibiras en tu panel web.`,
    },
    {
      iconClass: 'fa fa-minus-circle',
      name: 'No mas comisiones por ventas',
      description: `No pagues mas comisiones por ventas en womve no te cobramos comisiones 
      solo pagas tu subscripción y la ganancia por tus ventas es toda tuya.`,
    },
    {
      iconClass: 'fa fa-biking',
      name: 'Maneja tus repartidores',
      description: `Puedes registrar tus propios repartidores en womve y manejar tus
      deliverys desde tu panel de administración.`,
    },
  ])

  const [loading, setLoading] = useState(false)

  //Methods
  const getSubscriptions = async () => {
    try {
      const { data: res } = await PublicService.getSubscriptions()
      console.log(res)
      setSubscriptions(res.data)
    } catch (error) {}
  }

  const getStores = async (search = '') => {
    try {
      const { data: res } = await PublicService.getStores(search)
      setStores(res.data)
    } catch (error) {}
  }

  const executeSearchStores = async search => {
    setLoading(true)
    await getStores(search)
    setLoading(false)
  }

  const contractSubscription = subs => {
    setSelectedSubscription(subs)
    // Somewhere else, even another file
    scroller.scrollTo('contract', {
      duration: 1000,
      smooth: true,
      offset: -60, // Scrolls to element + 50 pixels down the page
    })
  }

  useEffect(() => {
    // getProducts()
    getStores()
    getSubscriptions()
  }, [])

  return (
    <>
      <Element name="inicio">
        <div className="banner w-100">
          <div className="banner-buttons">
            <h1>Dale vida a tu negocio online</h1>
            <p>Tus productos y servicio a la mano de tus clientes</p>
            <div>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className="mr-4"
              >
                Prueba gratis
              </Button>
              <Button size="large" variant="contained" color="primary">
                Ver Demos
              </Button>
            </div>
          </div>
          {/* <img className="w-100" src={mainBanner} alt="" /> */}
          <div
            style={{
              height: '100px',
              overflow: 'hidden',
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          >
            <svg
              viewBox="0 0 500 80"
              preserveAspectRatio="none"
              style={{ height: '100%', width: '100%' }}
            >
              <path
                d="M0.00,49.98 C258.17,32.06 359.20,118.91 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                style={{ stroke: 'none', fill: '#fff' }}
              ></path>
            </svg>
          </div>
        </div>
      </Element>

      {/* Search area */}
      <div className="d-flex flex-wrap justify-content-center mb-5">
        <h1 className="w-100 text-center text-responsive h4 m-0 px-2 py-1 pt-2 font-weight-bold">
          Todo lo que necesitas esta en tus manos
        </h1>
        <p className="w-100 text-center m-0 py-2 text-muted">
          Busca entre nuestros negocios afiliados
        </p>
        <SearchInput
          placeholder="Tiendas, resturantes y más..."
          executeSearch={executeSearchStores}
          loading={loading}
          size="medium"
        ></SearchInput>
      </div>

      {/* Stores */}
      <div
        className="row flex-row justify-content-center "
        style={stores.length === 0 ? { height: '500px' } : {}}
      >
        {/* Skeleton */}
        {stores.length === 0 && (
          <>
            {[1, 2, 3, 4, 5, 6].map(i => {
              return (
                <div className="card-store m-2" key={i}>
                  <div className="card-store-image">
                    <Skeleton height="100%" variant="rect" animation="wave" />
                  </div>

                  <div className="pt-2 p-2">
                    <Skeleton
                      variant="rect"
                      height={30}
                      animation="wave"
                      className="mb-1"
                    />
                  </div>
                </div>
              )
            })}
          </>
        )}

        {stores.length > 0 &&
          stores.map(store => {
            return (
              <div className="flex-md-column m-3 card-store" key={store.id}>
                <div
                  className="card p-0 border-0"
                  style={{ height: '100%', width: '100%' }}
                >
                  <div className="position-relative">
                    <Link to={`public/stores/${store.id}`}>
                      <div className="card-store-image">
                        <img
                          style={{ height: '100%' }}
                          src={`${store.url_banner}`}
                          className="card-img-top img-responsive"
                          alt="imagen producto"
                        />

                        <span
                          className="item-logo"
                          style={{
                            backgroundImage: `url(${store.url_logo})`,
                          }}
                        ></span>
                      </div>
                    </Link>

                    <div className="item-icon-like position-absolute">
                      <i className="far fa-heart icon-outline"></i>
                      <i className="fas fa-heart icon-solid"></i>
                    </div>
                  </div>

                  <div className="card-body px-3 pt-2 pb-1">
                    <div className="d-flex justify-content-between">
                      <Link to={`public/stores/${store.id}`}>
                        <h4 className="mt-0 p-0">{store.name}</h4>
                      </Link>
                      <div className="d-flex align-items-center justify-content-center item-score">
                        <span>5.0</span>
                      </div>
                    </div>
                    <p className="d-flex flex-wrap m-0">
                      {store.store_sub_categories.map((sc, index) => (
                        <span key={sc.id} className="d-flex align-items-center">
                          <span>{sc.name}</span>
                          {index !== store.store_sub_categories.length - 1 && (
                            <i
                              className="fa fa-circle text-muted mx-1"
                              style={{ fontSize: '8px' }}
                            ></i>
                          )}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
      </div>

      {/* benefits */}
      <Element name="benefits">
        <h2 className="text-center pt-3 pb-0  mb-0 h4 text-responsive margin-top-responsive">
          ¿ Que beneficios le ofrece <b>womve</b> a mi negocio ?
        </h2>
        <p className="text-center p-0 m-0 mt-2">
          Ponemos a tu disposición los siguientes beneficios
        </p>
        {/* <div className="row justify-content-center align-items-center payment-methods parallax"></div> */}
        <div className="d-flex flex-wrap benefits-container pt-3">
          <div className="d-flex flex-wrap justify-content-center align-items-center benefits-container-interno">
            {benefits.map(benefit => {
              return (
                <div
                  data-aos="fade-up"
                  key={benefit.name}
                  className="m-4 rounded card-benefit position-relative border-top"
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '-35px',
                    }}
                    className="d-flex w-100 justify-content-center"
                  >
                    <i
                      style={{
                        height: '60px',
                        width: '60px',
                        fontSize: '30px',
                      }}
                      className={
                        'bg-primary text-white rounded-circle d-flex justify-content-center align-items-center ' +
                        benefit.iconClass
                      }
                    ></i>
                  </div>

                  <h4 className="px-3 pt-3 mt-4 text-primary text-center m-0">
                    {benefit.name}
                  </h4>
                  <p className="px-3 pb-3 pt-0 text-center">
                    {benefit.description}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </Element>

      {/* Nosotros */}
      <Element name="about">
        <h2 className="text-center p-4 h2 mt-3 text-responsive">
          ¿ Quienes somos ? ¿A quien esta dirigido <b>womve</b> ?
        </h2>
        <div className="row mb-5 container-about">
          <div className="col-md-8 offset-md-2 text-justify d-flex flex-wrap align-items-center">
            <div className="text-center">
              <p>
                Womve es un efuerzo en emprendimiento que busca conectar
                clientes y negocios a traves de una plataforma tecnologica
                estable basada en las ultimas tecnologias.
              </p>
              <p>
                Womve esta dirigido a los diferentes tipos de establecimientos
                tiendas, restaurantes, farmacias, vendedores por catalogo y
                cualquier tipo de negocio permitiendoles dar a conocer sus
                productos via online contando con una plataforma administrativa
                web que les permite gestionar desde sus productos, organizarlos
                por categorias manejar tipos de moneda, tasa de cambio, manejar
                modalidad de ventas recoger o delivery, manejar su equipo
                interno de repartidores entre otras muchas herramientas mas.
              </p>
            </div>
          </div>
        </div>
      </Element>

      {/* PRICING */}
      <Element name="pricing">
        <div className="d-flex flex-wrap justify-content-center">
          <div className="text-center w-100">
            <h4>
              Elige el plan que mas se adapte a las necesidades de tu negocio
            </h4>
          </div>
          {subscriptions.map(i => {
            return (
              <div
                className="card border-0 p-0 m-3 card-pricing "
                style={{
                  borderRadius: '5px 30px 5px 5px',
                }}
                key={i.id}
              >
                <div
                  className="card-header no-border text-white bg-primary d-flex align-items-center justify-content-center flex-wrap p-2"
                  style={{ height: '120px', borderRadius: '5px 30px 0 30px' }}
                >
                  <h3 className="w-100 text-center">{i.name}</h3>
                  <div
                    className="header-price bg-primary font-weight-bold text-center rounded-circle d-flex justify-content-center align-items-center flex-wrap"
                    style={{
                      height: '90px',
                      width: '90px',
                      border: '3px solid white',
                    }}
                  >
                    <span className="p-0 m-0" style={{ fontSize: '30px' }}>
                      {Math.trunc(parseInt(i.price))}
                    </span>{' '}
                    <small className="font-weight-bold">USD</small>
                  </div>
                </div>

                <div className="card-body mt-4 no-border">
                  <ul
                    className="d-flex justify-content-center p-0 m-0 flex-wrap"
                    style={{ listStyle: 'none' }}
                  >
                    {JSON.parse(i.benefits).map((b, index) => (
                      <li
                        key={index}
                        className="p-2 w-100 d-flex align-items-center"
                      >
                        <i
                          className="fa fa-check mr-2 text-primary"
                          style={{ fontSize: '10px' }}
                        ></i>
                        {b}
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  className="d-flex p-3 pt-1 justify-content-center card-footer bg-white"
                  style={{ borderTop: 0 }}
                >
                  <Button
                    onClick={() => contractSubscription(i)}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Contratar
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </Element>
      <Element name="contract">
        <h2 className="text-center p-2 h4 text-responsive margin-top-responsive">
          Crea tu negocio online <b>hoy</b>
        </h2>
        <div className="d-flex justify-content-center">
          <div className="p-1 row m-1">
            <div className="col-md-4 offset-md-1 align-items-center justify-content-center">
              <img
                className="w-100"
                src="https://whaped.com/images/free-quote-left-image2.png"
              />
            </div>
            <div className="col-md-4 offset-md-1">
              <div className="p-1">
                <TextField
                  variant="outlined"
                  label="Nombre de contacto"
                  className="my-2"
                  fullWidth
                />
                <TextField
                  variant="outlined"
                  label="Correo electronico"
                  className="my-2"
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  label="Número de contacto"
                  className="my-2"
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  label="Nombre de tu negocio"
                  className="my-2"
                  fullWidth
                />

                <FormControl variant="outlined" className="w-100 my-2">
                  <InputLabel id="select_plan">Plan</InputLabel>

                  <Select
                    label="Plan"
                    labelId="select_plan"
                    value={selectedSubscription}
                    onChange={ev => setSelectedSubscription(ev.target.value)}
                    fullWidth
                  >
                    <MenuItem value="none" disabled>
                      Selecciona un plan
                    </MenuItem>
                    {subscriptions.map(i => (
                      <MenuItem key={i.id} value={i}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  variant="outlined"
                  label="Describe tus servicios"
                  className="my-2"
                  fullWidth
                  multiline
                />
              </div>

              <div className="mx-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      onChange={() => {}}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Aceptas que womve se ponga en contacto contigo"
                />
              </div>

              <div className="text-center">
                <Button variant="contained" size="large" color="primary">
                  <i className="fa fa-paper-plane mr-3"></i>
                  Enviar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default Welcome
