import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
//publics
import PublicLayout from '../views/PublicLayout'
import Welcome from '../views/public/Welcome/Welcome'
import About from '../views/public/About/About'
import NotFound from '../views/NotFound/NotFound'
import Register from '../views/public/Register/Register'
import ResetPasswordReset from '../views/ResetPassword/ResetPassword'
import ResetPasswordSendEmail from '../views/ResetPassword/SendEmail'
import DetailProduct from '../views/public/DetailProduct/DetailProduct'
import Cart from '../views/public/Cart/Cart'
import AfterPayment from '../views/public/AfterPayment/AfterPayment'
import PublicStore from '../views/public/PublicStore/PublicStore'

//Private
import Home from '../views/Home/Home'
import Orders from '../views/Orders/Orders'
import DetailOrder from '../views/DetailOrder/DetailOrder'
import HomeLayout from '../views/HomeLayout'
import EmailVerify from '../views/EmailVerify/EmailVerify'
import Products from '../views/Products/Products'
import ProductCategories from '../views/ProductCategories/ProductCategories'
import Stores from '../views/Stores/Stores'
import DetailStore from '../views/DetailStore/DetailStore'
import MenuSections from '../views/Menus/Menus'
import Profile from '../views/Profile/Profile'
import ScrollToTop from '../components/ScrollToTop'

//Public User views routes
import UserOrders from '../views/user/Orders'
import Distributors from '../views/Distributors/Distributors'

//Distributors
import DistributorOrders from '../views/DistributorOrders/Orders'

//Public Layout wrapper route
function RoutePublic({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        <PublicLayout {...props}>
          <Component {...props} />
        </PublicLayout>
      )}
    />
  )
}

//Home layout wrapper route
function RouteHome({ component: Component, ...rest }) {
  return isAuthenticated() ? (
    <Route
      {...rest}
      render={props => (
        <HomeLayout {...props}>
          <Component {...props} />
        </HomeLayout>
      )}
    />
  ) : (
    <Redirect to="/login" />
  )
}

function isAuthenticated() {
  return localStorage.getItem('token')
}

const App = () => (
  <>
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        {/* Publicas */}
        <RoutePublic exact path="/" component={Welcome} />
        <RoutePublic exact path="/about" component={About} />
        <RoutePublic
          exact
          path="/detail/product/:id"
          component={DetailProduct}
        />
        <RoutePublic exact path="/cart" component={Cart} />
        <RoutePublic exact path="/after-payment" component={AfterPayment} />

        <Route exact path="/public/stores/:id" component={PublicStore} />

        {/* Public user */}
        <RoutePublic exact path="/my-orders" component={UserOrders} />

        <RouteHome exact path="/home" component={Home} />
        <RouteHome exact path="/orders" component={Orders} />
        <RouteHome exact path="/orders/:id" component={DetailOrder} />

        {/* Menu Sections */}
        <RouteHome exact path="/menus" component={MenuSections}></RouteHome>

        {/* Products */}
        <RouteHome exact path="/products" component={Products}></RouteHome>

        {/* Distributors */}
        <RouteHome
          exact
          path="/store-distributors"
          component={Distributors}
        ></RouteHome>

        <RouteHome
          exact
          path="/distributor-orders"
          component={DistributorOrders}
        ></RouteHome>

        {/* Product Categories */}
        <RouteHome
          exact
          path="/product-categories"
          component={ProductCategories}
        ></RouteHome>

        {/* Stores */}
        <RouteHome exact path="/stores" component={Stores}></RouteHome>
        <RouteHome exact path="/stores/:id" component={DetailStore}></RouteHome>

        <RouteHome
          exact
          path="/email/verify/:id/:code"
          component={EmailVerify}
        />

        <RouteHome exact path="/profile" component={Profile} />

        <Route exact path="/login" component={Register} />

        <Route exact path="/register" component={Register} />

        <Route
          exact
          path="/password/email"
          component={ResetPasswordSendEmail}
        />

        <Route
          exact
          path="/password/reset/:code"
          component={ResetPasswordReset}
        />

        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  </>
)

export default App
