import http from '../http'

class StoreService {
  static save(data) {
    return http({
      method: 'post',
      url: 'stores',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    })
  }

  static update(data) {
    return http({
      method: 'post',
      url: 'stores',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    })
  }

  static get(page = 1, search = '') {
    return http({
      method: 'get',
      url: 'stores',
    })
  }

  static delete(data) {
    return http({
      method: 'delete',
      data,
      url: 'stores',
    })
  }

  //Get store by id
  static getStoreById(store_id) {
    return http({
      method: 'get',
      url: `stores/${store_id}`,
    })
  }

  //Select store
  static selectStore(store_id) {
    return http({
      method: 'post',
      url: `select-store`,
      data: {
        store_id: store_id,
      },
    })
  }

  //Get payment methods in store
  static getPaymentMethods(store_id) {
    return http({
      method: 'get',
      url: `store-payment-methods/${store_id}`,
    })
  }

  //Store payment method in store
  static storePaymentMethod(data) {
    return http({
      method: 'post',
      url: 'store-payment-method',
      data: data,
    })
  }

  //change accept order type in payment method
  static changeAcceptOrderTypeInPaymentMethod(data) {
    return http({
      method: 'post',
      url: 'change-accept-order-type-in-payment-method',
      data: data,
    })
  }

  //Update note payment method
  static updateNotePaymentMethod(data) {
    return http({
      method: 'post',
      url: 'update-note-payment-method',
      data: data,
    })
  }

  //Delete store payment method
  static deleteStorePaymentMethod(data) {
    return http({
      method: 'post',
      url: 'delete-store-payment-method',
      data: data,
    })
  }

  //Store payment method in store payment method
  static storePayementMethodInPayementMethodStore(data) {
    return http({
      method: 'post',
      url: 'store-payment-method-in-store-payment-method',
      data: data,
    })
  }

  //Delete store payment method in store payment method
  static deleteStorePaymentMethodChild(data) {
    return http({
      method: 'post',
      url: 'delete-store-payment-method-in-store-payment-method',
      data: data,
    })
  }

  //Update payment method in store payment method
  static updatePayementMethodInPayementMethodStore(data) {
    return http({
      method: 'post',
      url: 'update-payment-method-in-store-payment-method',
      data: data,
    })
  }

  //Get store workdays
  static getStoreWorkdays(store_id) {
    return http({
      method: 'get',
      url: `store-workdays/${store_id}`,
    })
  }

  //Save store workday
  static saveStoreWorkday(data) {
    return http({
      method: 'post',
      url: 'store-store-workday',
      data: data,
    })
  }

  //Delete store workday
  static deleteStoreWorkday(data) {
    return http({
      method: 'post',
      url: 'delete-store-workday',
      data: data,
    })
  }

  //Save store workday hours
  static saveStoreWorkdayHours(data) {
    return http({
      method: 'post',
      url: 'store-store-workday-hours',
      data: data,
    })
  }

  //Update store workday hours
  static updateStoreWorkdayHours(data) {
    return http({
      method: 'post',
      url: 'update-store-workday-hours',
      data: data,
    })
  }

  //Delete store workday working hour
  static deleteStoreWorkdayWorkingHour(data) {
    return http({
      method: 'post',
      url: 'delete-store-workday-working-hour',
      data: data,
    })
  }

  static getStoreCoordinatesDeliveryArea(store_id) {
    return http({
      method: 'get',
      url: `store-coordinates-delivery-area/${store_id}`,
    })
  }

  static saveStoreCoordinatesDeliveryArea(data) {
    return http({
      method: 'post',
      url: 'save-store-coordinates-delivery-area',
      data: data,
    })
  }

  static saveStoreDistancePriceDeliveries(data) {
    return http({
      method: 'post',
      url: 'save-store-distance-price-deliveries',
      data: data,
    })
  }

  static saveModeDelivery(data) {
    return http({
      method: 'post',
      url: 'save-store-mode-delivery',
      data: data,
    })
  }

  //Get distributors
  static getStoreDistributors(store_id) {
    return http({
      method: 'get',
      url: `store-distributors/${store_id}`,
    })
  }

  //Get available distributors store
  static getAvailableDistributors(store_id) {
    return http({
      method: 'get',
      url: `store-available-distributors/${store_id}`,
    })
  }
}

export default StoreService
